import "./App.css";
import SignUpPage from "./pages/SignUpPage";
import WelcomePage from "./pages/LoginPage";
import ForgetPage from "./pages/ForgetPage";
import AccountPage from "./pages/AccountPage";
import CategoryPage from "./pages/CategoryPage";
import HomePage from "./pages/HomePage";
import SearchProductPage from "./pages/SearchProductsPage";
import CartPage from "./pages/CartPage";
import OrderHistoryPage from "./pages/OrderHistoryPage";
import ProductInfoPage from "./pages/ProductInfoPage";
import ContactUs from "./pages/ContactUsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy"
import FAQs from "./pages/FAQsPage"
import TermsAndConditions from './pages/TermsAndConditions';
import AccountInfoPage from "./pages/AccountInformationPage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import WishlistPage from './pages/WishlistPage';
import ProtectedRoute from "./utils/ProtectedRoute";
import MessageModal from "./components/Modal/MessageModal";
import ModalContainer from "./components/Modal/ModalContainer";
import {useAppDispatch, useAppSelector} from "./store";
import {setNotification} from "./features/notification/notificationSlice";
import OrderInvoicePage from "./pages/OrderInvoicePage";
import CheckoutPage from './pages/CheckoutPage'; 
import PaymentConfirmationPage from './pages/PaymentConfirmationPage'; 


function App() {

  let dispatch = useAppDispatch();
  let {message, show, action} = useAppSelector(state => state.notification);

  return (
      <div className="App">

        <Router>
          <Routes>
            <Route exact={true} path="/" element={<HomePage />} />
            <Route exact={true} path="/sign-up" element={<SignUpPage />} />
            <Route exact={true} path="/login" element={<WelcomePage />} />
            <Route exact={true} path="/recovery" element={<ForgetPage/>} />
            <Route exact={true} path="/search" element={<SearchProductPage />} />
            <Route exact={true} path="/product/info/:productId" element={<ProductInfoPage />} />
            <Route exact={true} path="/category" element={<CategoryPage />} />
            <Route exact={true} path="/contact-us" element={<ContactUs />} />
            <Route exact={true} path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact={true} path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact={true} path="/FAQs" element={<FAQs />} />

            <Route element={<ProtectedRoute/>}>
              <Route exact={true} path="/payment" element={<PaymentConfirmationPage />} />
              <Route
                  exact={true}
                  path="/account/cart"
                  element={ <CartPage/>}
              />
              <Route exact={true} path="/account/order-history" element={<OrderHistoryPage />} />
              <Route exact={true} path="/account/order-history/invoice/:orderId" element={<OrderInvoicePage />} />
              <Route exact={true} path="/account" element={<AccountPage />} />
              <Route exact={true} path="/account/information" element={<AccountInfoPage />} />
              <Route exact={true} path="/account/wishlist" element={<WishlistPage />} />
              <Route exact={true} path="/account/checkout" element={<CheckoutPage />} />
            </Route>

          </Routes>
        </Router>
      <ModalContainer visible={show}>
        <MessageModal
            message={message}
            onClickConfirm={() => {
              if(action) action()
              dispatch(setNotification({message: "", show: false}))
            }}
        />
      </ModalContainer>
      </div>
  );
}

export default App;
