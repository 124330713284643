import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {GeneralResponseType, PaginationResponseType} from "../types";
import {WishlistGetAllType, WishlistType} from "./types";
import {RootState} from "../../store";
import {refreshTokenUtil} from "../../utils/tokenUtil";


const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const wishlistApiSlice = createApi({
    reducerPath: "WishlistApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${CUSTOMER_API_BASE_URL}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.accessToken
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['WishList'],

    endpoints: (builder) => ({
        addToWishList: builder.query<GeneralResponseType<WishlistType>, { productId: string }>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let wishListResponse = await fetchWithBQ({
                    url: `/wishlist/${arg.productId}/add-item`,
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                });
                return wishListResponse as any
            }
        }),

        getAllWishListItems: builder.query<GeneralResponseType<PaginationResponseType<WishlistGetAllType>>, { pageNo: number, count: number }>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ){

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let wishListResponse = await fetchWithBQ({
                    url: `/wishlist?page=${arg.pageNo}&count=${arg.count}`,
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })
                return wishListResponse as any
            },
            providesTags: ['WishList'],
            keepUnusedDataFor: 60
        }),

        deleteWishListItem: builder.mutation<GeneralResponseType<any>, {productId: number }>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ){
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let wishListResponse = await fetchWithBQ({
                    url: `/wishlist?productId=${arg.productId}`,
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })
                return wishListResponse as any
            },
            invalidatesTags: ['WishList']
        })
    })
})

export const {
    useLazyAddToWishListQuery,
    useLazyGetAllWishListItemsQuery,
    useDeleteWishListItemMutation
} = wishlistApiSlice;