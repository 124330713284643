import React, { CSSProperties, FC } from 'react'
import styled from "styled-components";
import Lottie from 'react-lottie';
import Colors from '../constants/Colors';
import spinnerLottie from "../assets/lottie/loading-dots.json"
import { lottieOptions } from "../utils/lottieOptions";

interface StandardButtonInterface {
  value: string;
  containerStyling?: CSSProperties;
  textStyling?: CSSProperties;
  onClick?: Function;
  loader?: boolean;
  disable?: boolean
  leftIcon?: any;
}

const StandardButton: FC<StandardButtonInterface> = (props) => {
  return (
    <ButtonContainer onClick={() => props.onClick && !props.disable ? props.onClick() : null}
      style={{ ...props.containerStyling, backgroundColor: props.disable ? Colors.mediumGrey : props.containerStyling?.backgroundColor }}
      disabled={props.disable}
      type="button"
    >
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10}}>
        {props.leftIcon ? props.leftIcon : null}
        {!props.loader && <Text style={{ ...props.textStyling }}>{props.value}</Text>}
      </div>
      {props.loader && <Lottie options={lottieOptions(true, true, spinnerLottie)}
        height={35}
        width={150}
        isStopped={false}
        isPaused={false}
      />}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.button`
  width: 100%;
  height: 36px;
  background-color: ${Colors.green};
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
  &:hover {
    cursor: pointer
  }
  &:disabled {
    background-color: ${Colors.mediumGrey}
  };
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.a`
  color: #ffffff;
  font-weight: 600;
  margin: auto;
`;

export default StandardButton
