import {combineReducers, configureStore, Reducer} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {persistStore} from "redux-persist";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

//Local Imports
import {authApiSlice} from "./features/auth/authApiSlice"
import {authReducer, resetGlobalState} from "./features/auth/authSlice";
import {productApiSlice} from "./features/product/productApiSlice";
import {customerApiSlice} from './features/customer/customerApiSlice';
import {customerReducer} from './features/customer/customerSlice';
import {wishlistApiSlice} from "./features/wishlist/wishlistApiSlice";
import {unauthenticatedMiddleware} from "./middleware/unauthenticatedMiddleware";
import {notificationReducer} from "./features/notification/notificationSlice";
import {cartApiSlice} from './features/cart/cartApiSlice';
import {cartReducer} from './features/cart/cartSlices';
import { shippingCalculatorApiSlice } from "./features/ShippingCalculator/ShippingCalculatorApiSlice";
import { OrderShippingApiSlice } from "./features/order/orderApiSlice";
import { contactUsApiSlice } from "./features/contactUs/contactUsApiSlice";
import { campaignApiSlice } from "./features/campaign/campaignApiSlices";

const reducers = {
    auth: authReducer,
    notification: notificationReducer,
    customer: customerReducer,
    cart: cartReducer,
    [cartApiSlice.reducerPath]: cartApiSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [productApiSlice.reducerPath] : productApiSlice.reducer,
    [customerApiSlice.reducerPath]: customerApiSlice.reducer,
    [wishlistApiSlice.reducerPath]: wishlistApiSlice.reducer,
    [shippingCalculatorApiSlice.reducerPath]: shippingCalculatorApiSlice.reducer,
    [OrderShippingApiSlice.reducerPath]: OrderShippingApiSlice.reducer,
    [contactUsApiSlice.reducerPath]: contactUsApiSlice.reducer,
    [campaignApiSlice.reducerPath]: campaignApiSlice.reducer
}

const appReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === resetGlobalState.type) {
        /**
         * Reducers are supposed to return the initial state when they are called with undefined as the first argument, no matter the action.
         * */
        console.log("Reset Global State root reducer!");
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
}

export const store = configureStore({
    reducer: rootReducer,
    middleware:(getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat([
            authApiSlice.middleware,
            customerApiSlice.middleware,
            productApiSlice.middleware,
            wishlistApiSlice.middleware,
            cartApiSlice.middleware,
            shippingCalculatorApiSlice.middleware,
            OrderShippingApiSlice.middleware,
            unauthenticatedMiddleware,
            campaignApiSlice.middleware
        ])
    }
})

//Set type for dispatch
export type AppDispatch = typeof store.dispatch;

//Set type for items in state
export type RootState = ReturnType<typeof appReducer>;

export const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;