import React, { FC } from "react";
import styled, { CSSProperties } from "styled-components";
import { Screen, SCREEN_WIDTH_CONSTANTS } from "../constants/Devices"

interface CategoryInterface {
  source?: any;
  alternate?: String;
  label?: String;
  containerStyling?: CSSProperties;
  onClick: Function;
}

const Category: FC<CategoryInterface> = (props) => {
  return (
    <Container style={{ ...props.containerStyling }} onClick={()=>props.onClick()}>
      {/* Note: The the backward quotation for the property with a text datatype */}
      <Image src={props.source} alt={`props.alternate`} />
      <Label>{props.label}</Label>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
    max-width: 300px;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Label = styled.span`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 4px 6px;
  color: #ffffff;
  position: absolute;
  font-size: 14px;
`;

export default Category;
