import NavBar from "../components/NavBar";
import styled from "styled-components";
import Colors from "../constants/Colors";
import CartItem from "../components/CartItem";
import TextTrail from "../components/Universal/TextTrail";
import React, { useEffect, useState } from "react";
import StandardButton from "../components/StandardButton";
import { useAppDispatch, useAppSelector } from "../store";
import { useGetCustomerInfoQuery } from "../features/customer/customerApiSlice";
import { setCustomerInfo } from "../features/customer/customerSlice";
import {
  useDeleteCartItemMutation,
  useGetCartItemsQuery,
  useLazyGetCartItemCountQuery
} from '../features/cart/cartApiSlice'
import Footer from "../components/Universal/Footer";
import { updateCartCount } from "../features/cart/cartSlices";
import useWindowDimensions from "../utils/useWindowDimensions";
import { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";
import { useNavigate } from "react-router-dom";

const CartPage = (): JSX.Element => {

  let navigate = useNavigate();

  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();

  let { data, isSuccess } = useGetCustomerInfoQuery();
  let { data: cartData, refetch } = useGetCartItemsQuery();
  let [triggerDeleteCartItem] = useDeleteCartItemMutation();

  let [triggerItemCount] = useLazyGetCartItemCountQuery();




  useEffect(() => {
    if (isSuccess && data?.response) {
      dispatch(setCustomerInfo(data?.response))
    }
  }, [data, isSuccess])

  let setCartItemCount = () => {
    triggerItemCount()
      .unwrap()
      .then(response => {
        if (response.response) dispatch(updateCartCount(response.response?.count))
      })
  }

  let deleteCartItem = (cartItemId: number) => {
    triggerDeleteCartItem({ cartId: cartItemId })
  }


  let calculateCartTotal = () => {
    let total = 0;
    cartData?.response?.cartItems.forEach(item => {
      total += item.quantity * item.product.productPrice
    })
    return total;
  }


  return (
    <PageContainer>
      <div>
        <NavBar />
        <ContentContainer>
          <ContentWrapper>
            <TextTrail
              textArray={["Your Account", "Cart"]}
              containerStyling={{ marginBottom: 15 }}
            />

            <BoldText>Your Cart</BoldText>

            {/* This Container is used to position the main sections of this screen */}
            <ContentPositioner>

              {/* Render if on Standard Ipad or Phone */}
              {dimensions.width && dimensions.width < 834 && <CartCheckoutButtonContainer>
                <TotalText>
                  Total ({cartData?.response?.cartItems && cartData?.response?.cartItems?.length>0 ? cartData?.response?.cartItems?.map(val=> val.quantity).reduce((sum, currentValue)=>sum + currentValue) : 0} items): <Span style={{ color: Colors.black }}> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateCartTotal())}</Span>
                </TotalText>

                <StandardButton
                  value={"Checkout"}
                  disable={cartData?.response?.cartItems.length === 0}
                  containerStyling={{}}
                  onClick={() => navigate(`/account/checkout`)}
                >
                  Checkout
                </StandardButton>
              </CartCheckoutButtonContainer>}

              <CardContainer>
                {cartData?.response?.cartItems &&
                  cartData?.response?.cartItems?.length > 0
                  && cartData?.response?.cartItems.map((item => <CartItem key={item.product.productId}
                    imageUrl={item.product.imageUrls?.[0] ?? '/images/undraw_polaroid_re_481f.svg'}
                    name={item.product.productName}
                    price={item.quantity * item.product.productPrice}
                    quantity={item.quantity}
                    availableQuantity={item.product.quantity}
                    details={item.details}
                    productId={item.product.productId}
                    cartItemId={item.cartItemId.toString()}
                    onClickDelete={() => deleteCartItem(item.cartItemId)}
                  />))
                }
              </CardContainer>

              {/* Render if on device larger than standard Ipad */}
              {dimensions.width && dimensions.width >= 834 && <div>
                <CartCheckoutButtonContainer>
                  <TotalText>
                  Total ({cartData?.response?.cartItems && cartData?.response?.cartItems?.length>0 ? cartData?.response?.cartItems?.map(val=> val.quantity).reduce((sum, currentValue)=>sum + currentValue) : 0} items): <Span style={{ color: Colors.black }}> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateCartTotal())}</Span>
                  </TotalText>

                  <StandardButton
                    value={"Checkout"}
                    disable={cartData?.response?.cartItems.length === 0}
                    containerStyling={{backgroundColor: Colors.orange}}
                    textStyling={{color: Colors.white}}
                    onClick={() => navigate(`/account/checkout`)}
                  >
                    Checkout
                  </StandardButton>
                </CartCheckoutButtonContainer>
              </div>}

            </ContentPositioner>


          </ContentWrapper>
        </ContentContainer>
      </div>
      <Footer />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;

`;

const ContentWrapper = styled.div`
    width: 100%;
    max-width: 1500px;
    /* min-width: 350px; */
`;

const ContentPositioner = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: ${SCREEN_WIDTH_CONSTANTS.iPad}){
    flex-direction: column;
  }
  flex-direction: row;
  gap: 20px;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const CartCheckoutButtonContainer = styled.div`
  background-color: ${Colors.white};
  padding: 15px;
  border-radius: 8px;
  /* width: 100%; */
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
    width: 400px;
  }
`;

let Span = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.darkGrey};
  padding-bottom: 10px;
`;

let TotalText = styled.div`
  text-align: left;
  font-family: Poppins;
  font-size: 18px;
  color: ${Colors.lightGrey2};
  padding-bottom: 15px;

  span {
    color: ${Colors.fontGrey};
  }
`;

let BoldText = styled.h3`
  font-family: Poppins;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
  margin-bottom: 15px;
`;

export default CartPage;

