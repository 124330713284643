import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Alert, AlertTitle } from '@mui/material';

//LOcal Imports
import NavBar from '../components/NavBar';
import TextTrail from '../components/Universal/TextTrail';
import Colors from '../constants/Colors';
import Footer from '../components/Universal/Footer';
import { SCREEN_WIDTH_CONSTANTS } from '../constants/Devices';
import DropDownField from '../components/Form/DropDownField';
import { pickupLocations } from '../constants/pickup-locations';
import StandardButton from '../components/StandardButton';
import { useLazyCalculateShippingRateQuery } from '../features/ShippingCalculator/ShippingCalculatorApiSlice';
import { useGetCartItemsQuery, useLazyGetCartItemCountQuery } from '../features/cart/cartApiSlice';
import { useLazySubmitOrderQuery, useLazyCreateIntentQuery } from '../features/order/orderApiSlice';
import { LascoPaySignatureResponseModel, OrderAddress, OrderRequestModel, PaymentGateway } from '../features/order/types';
import { knutsfordLocations } from '../constants/knutsford-shipping-locations';
import { ShippingDeliveryCalculatorRequest } from '../features/ShippingCalculator/types';
import { updateCartCount } from '../features/cart/cartSlices';
import { useAppDispatch } from '../store';
import { zipmailLocations } from '../constants/zipmail-shipping-locations';
import CheckoutItem from '../components/Product/CheckoutItem';
import { packagehubLocations } from '../constants/packagehub-shipping-location';
import { setNotification } from "../features/notification/notificationSlice";

let CheckoutPage = () => {

	const LASCOPAY_URL: any = process.env.REACT_APP_LASCOPAY_URL

	// dispatch
	const dispatch = useAppDispatch();
	let navigate = useNavigate();

	// API calls
	let [triggerCalculateShippingRate] = useLazyCalculateShippingRateQuery();
	let [triggerSubmitOrder] = useLazySubmitOrderQuery();
	let [triggerCreateIntent] = useLazyCreateIntentQuery();
	let [triggerItemCount] = useLazyGetCartItemCountQuery();
	let { data: cartData, refetch } = useGetCartItemsQuery();

	// Variables
	const formRef = useRef<any>(null);
	let [shippingRate, setShippingRate] = useState<number>(0);
	let [totalCartWeight, setTotalCartWeight] = useState(0);
	let [orderError, setOrderError] = useState<any>();
	let [showOrderError, setShowOrderError] = useState<boolean>(false);
	let [lascoPaySignatureData, setLascoPaySignatureData] = useState<LascoPaySignatureResponseModel>({} as LascoPaySignatureResponseModel);

	let [orderAddress, setOrderAddress] = useState<OrderAddress | null>(null)
	let [modalDeliveryMethod, setModalDeliveryMethod] = useState<string | null>(null);
	let [pickupPaymentMethod, setPickupPaymentMethod] = useState<string | null>(null);
	let [showErrorMessage, setShowErrorMessage] = useState(false)
	let [confirmModelPageNumber, setConfirmModalPageNumber] = useState(0);
	let [showButtonLoader, setShowButtonLoader] = useState(false);
	let [showButtonLoaderTemp, setShowButtonLoaderTemp] = useState(false);
	let [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		setShowButtonLoader(showButtonLoaderTemp)
	}, [showButtonLoaderTemp])

	useEffect(() => {
		console.log("<--- CART DATA --->", cartData);
		let totalWeight = 0;
		cartData?.response?.cartItems.forEach(item => {
			totalWeight += (item.product.weightLb * item.quantity);
		})

		setTotalCartWeight(totalWeight)

	}, [cartData])

	let setCartItemCount = () => {
		triggerItemCount()
			.unwrap()
			.then(response => {
				if (response.response) dispatch(updateCartCount(response.response?.count))
			})
	}

	let submitOrder = async (onSuccess: Function, onFailure: Function) => {
		if (modalDeliveryMethod === null || modalDeliveryMethod === "") {
			setErrorMessage("Please select delivery method")
			setShowErrorMessage(true)
			return;
		}
		if (orderAddress === null) {
			setErrorMessage("Please select delivery location")
			setShowErrorMessage(true)
			return;
		}
		if (pickupPaymentMethod === null || pickupPaymentMethod === "") {
			setErrorMessage("Please select Payment method")
			setShowErrorMessage(true)
			return;
		}
		if (modalDeliveryMethod !== null) {
			let request: OrderRequestModel = {
				orderMethod: modalDeliveryMethod.toUpperCase(),
				orderAddress: orderAddress,
				deliveryRate: shippingRate,
				// TODO: pass the accurate payment gate way name to the database
				paymentGateway: (pickupPaymentMethod === null || pickupPaymentMethod === "Pay Online") ? PaymentGateway.AMBER : pickupPaymentMethod,
				paymentInfo: null
			}
			setShowButtonLoaderTemp(true)

			//TODO: Handle Payment on the API side and send a redirect to the front end.
			//set order status to INITIATED-PAYMENT until the payment is complete
			//if the payment 
			if (pickupPaymentMethod === "Pay Online") {
				triggerCreateIntent(request)
					.unwrap()
					.then(response => {
						if (response.response) setLascoPaySignatureData(response.response)
						if (formRef.current) {
							formRef.current.submit();
						}
					})
					.catch(error => {
						if (error.data.message === 'Insufficient product quantities') {
							dispatch(setNotification({
								message: "Some of the item in your cart have lower available quantities than asked for. Please adjust your quantities to proceed",
								show: true,
								action: () => navigate(`/account/cart`)
							}))
							return;
						}

						dispatch(setNotification({
							message: error.data.message,
							show: true,
						}))

					})
			} else {
				triggerSubmitOrder(request)
					.unwrap()
					.then(response => {
						refetch();
						onSuccess()
						setCartItemCount()
						setConfirmModalPageNumber(confirmModelPageNumber + 1)
						setShowButtonLoaderTemp(false)
					})
					.catch(error => {
						onFailure()
						setConfirmModalPageNumber(confirmModelPageNumber + 1)
						setShowButtonLoaderTemp(false)
						dispatch(setNotification({
							message: error.data.message,
							show: true,
						}))
					})
				// setShowButtonLoaderTemp(false)
				setErrorMessage("")
			}
		}
	}

	let shippingRateCalculator = (totalOrderWeight: number, deliveryType: string) => {
		let request: ShippingDeliveryCalculatorRequest = {
			orderWeight: totalOrderWeight,
			deliveryType: deliveryType.toUpperCase(),
		}

		triggerCalculateShippingRate(request)
			.unwrap()
			.then(response => {
				if (response.response) {
					setShippingRate(response.response?.price)
				} else {
					if (deliveryType == "KNUTSFORD") setShippingRate(700)
					if (deliveryType == "ZIPMAIL") setShippingRate(500)
				}

			})
			.catch(err => {
				if (deliveryType == "KNUTSFORD") setShippingRate(700)
				if (deliveryType == "ZIPMAIL") setShippingRate(500)
			})
	}

	let selectDeliveryMethodLocation = (location: string | null) => {
		if (location === null) {
			setOrderAddress(location);
			return;
		}

		let address: OrderAddress = {
			address_line_1: "N/A",
			address_line_2: "N/A",
			city: "N/A",
			parish_state_province: "N/A",
			postal_code: "N/A",
			is_primary: false,
			is_knutsford_address: false,
			is_zipmail_address: false
		}
		if (modalDeliveryMethod === 'Pickup') {
			let selectedLocation = pickupLocations.find(item => item.location === location);
			if (selectedLocation) {
				address.address_line_1 = selectedLocation.location;
				address.parish_state_province = selectedLocation.parish;
			}
		}
		if (modalDeliveryMethod === 'ZipMail') {
			address.address_line_1 = location;
			address.is_zipmail_address = true;
		}
		if (modalDeliveryMethod === 'Knutsford') {
			let selectedLocation = knutsfordLocations.find(item => item.location === location);
			if (selectedLocation) {
				address.address_line_1 = location;
				address.city = selectedLocation.city;
				address.parish_state_province = selectedLocation.parish;
				address.is_knutsford_address = true;
			}
		}
		setOrderAddress(address);
	}

	let getProductTotal = (plusShippingRate = false) => {
		let productTotal = 0;
		cartData?.response?.cartItems?.forEach(val => {
			productTotal = productTotal + (val.quantity * val.product.productPrice)
		});
		if (plusShippingRate) return productTotal + shippingRate
		return productTotal
	}


	let getPaymentMethod = () => {
		if (modalDeliveryMethod === 'Pickup') return ["Pay Online", "Pay on Pickup"]
		if (modalDeliveryMethod === 'PackageHub') return ["Pay Online", "Pay on Pickup"]
		if (modalDeliveryMethod === 'ZipMail') return ["Pay Online"]
		if (modalDeliveryMethod === 'Knutsford') return ["Pay Online"]
		return ["Pay Online", "Pay on Pickup"]
	}

	let setDeliveryMethodAndResetAll = (method: string) => {
		setModalDeliveryMethod(method)
		setPickupPaymentMethod(null)
		selectDeliveryMethodLocation(null)
		setShippingRate(0)
	}

	return (
		<PageContainer>
			<div>
				<NavBar />
				<ContentContainer>
					<ContentWrapper>
						<TextTrail
							textArray={["Your Account", "Checkout"]}
							containerStyling={{ marginBottom: 15 }}
						/>

						<BoldText>Checkout</BoldText>
						{showErrorMessage && <Alert severity="error" style={{ marginBottom: 20 }} onClose={() => setShowErrorMessage(false)}>
							<AlertTitle>Error</AlertTitle>
							{errorMessage}
						</Alert>}

						<SectionDivider>

							<CheckoutPreContainer>
								<DeliveryMethodContainer>
									<DarkText style={{ marginBottom: 15 }}>Delivery Information</DarkText>
									<Text>
										Select a delivery method:
									</Text>
									<div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', marginBottom: 15 }}>

										<StandardButton
											value={"Pickup"}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: modalDeliveryMethod === 'Pickup' ? Colors.orange : Colors.borderPrimary, borderStyle: "solid" }}
											textStyling={{ color: Colors.carbonBlack }}
											onClick={() => {
												setDeliveryMethodAndResetAll("Pickup")
												setShippingRate(500)
											}}
										/>
										<StandardButton
											value={"Zipmail"}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: modalDeliveryMethod === 'ZipMail' ? Colors.orange : Colors.borderPrimary, borderStyle: "solid" }}
											textStyling={{ color: Colors.carbonBlack }}
											onClick={() => {
												setDeliveryMethodAndResetAll("ZipMail")
												shippingRateCalculator(totalCartWeight, "ZipMail")
											}}
										/>
										<StandardButton
											value={"Knutsford"}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: modalDeliveryMethod === 'Knutsford' ? Colors.orange : Colors.borderPrimary, borderStyle: "solid" }}
											textStyling={{ color: Colors.carbonBlack }}
											onClick={() => {
												setDeliveryMethodAndResetAll("Knutsford")
												// shippingRateCalculator(totalCartWeight, "Knutsford")
												setShippingRate(0)
											}}
										/>
									</div>

									<div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', marginBottom: 15 }}>

										<StandardButton
											value={"PackageHub"}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: modalDeliveryMethod === 'PackageHub' ? Colors.orange : Colors.borderPrimary, borderStyle: "solid" }}
											textStyling={{ color: Colors.carbonBlack }}
											onClick={() => {
												setDeliveryMethodAndResetAll("PackageHub")
												// shippingRateCalculator(totalCartWeight, "Knutsford")
												setShippingRate(0)
											}}
										/>
									</div>

									{modalDeliveryMethod && <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>

										{modalDeliveryMethod && modalDeliveryMethod == 'Pickup' && <DropDownField
											dropdownOptions={pickupLocations.map(item => item.location)}
											containerStyling={{ width: "100%" }}
											onSelect={(location: any) => selectDeliveryMethodLocation(location)}
										/>}
										{modalDeliveryMethod && modalDeliveryMethod == 'ZipMail' && <DropDownField
											dropdownOptions={zipmailLocations.map(item => {
												if (!item.location.includes("Post Office") && !item.location.includes("post office")) return item.location + " Post Office"
												return item.location
											})}
											containerStyling={{ width: "100%" }}
											onSelect={(location: any) => selectDeliveryMethodLocation(location)}
										/>}
										{modalDeliveryMethod && modalDeliveryMethod == 'Knutsford' && <DropDownField
											dropdownOptions={knutsfordLocations.map(item => item.location)}
											containerStyling={{ width: "100%" }}
											onSelect={(location: any) => selectDeliveryMethodLocation(location)}
										/>}
										{modalDeliveryMethod && modalDeliveryMethod == 'PackageHub' && <DropDownField
											dropdownOptions={packagehubLocations.map(item => item.location)}
											containerStyling={{ width: "100%" }}
											onSelect={(location: any) => selectDeliveryMethodLocation(location)}
										/>}
									</div>}
								</DeliveryMethodContainer>
								<PaymentInformationContainer>
									<DarkText style={{ marginBottom: 15 }}>Payment Information</DarkText>
									<Text>
										Select a payment method:
									</Text>
									<div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', marginBottom: 15 }}>

										{modalDeliveryMethod && (modalDeliveryMethod == 'Pickup' || modalDeliveryMethod == 'PackageHub') && <StandardButton
											value={"Pay on Pickup"}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: pickupPaymentMethod === 'Pay on Pickup' ? Colors.orange : Colors.borderPrimary, borderStyle: "solid" }}
											textStyling={{ color: Colors.carbonBlack }}
											onClick={() => {
												setPickupPaymentMethod("Pay on Pickup")
											}}
										/>}
										<StandardButton
											value={"Pay Online"}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: pickupPaymentMethod === 'Pay Online' ? Colors.orange : Colors.borderPrimary, borderStyle: "solid" }}
											textStyling={{ color: Colors.carbonBlack }}
											onClick={() => {
												setPickupPaymentMethod("Pay Online")
											}}
										/>
									</div>
									<LascoPayText>
										<Text style={{ fontStyle: 'italic', color: Colors.black400 }}>
											Powered By Lasco Pay
										</Text>
										<img src="https://merchant-portal.lascobizja.com/template/assets/images/lasco-favicon.png" style={{ width: 50 }} />
									</LascoPayText>
								</PaymentInformationContainer>
							</CheckoutPreContainer>

							<SectionContainer>
								<ConfirmationContainer>
									<Text style={{ fontSize: 18, color: Colors.carbonBlack, fontWeight: 500, marginBottom: 15 }}>Order Summary</Text>

									{/* TODO confirmation info breakdown */}
									{cartData?.response?.cartItems &&
										cartData?.response?.cartItems?.length > 0
										&& cartData?.response?.cartItems.map(item => (
											<CheckoutItem
												imageUrl={item.product.imageUrls[0]}
												name={item.product.productName}
												price={item.product.productPrice}
												quantity={item.quantity}
												availableQuantity={item.product.quantity}
												category={item.product.category}
												totalWeight={item.product.weightLb * item.quantity}
												containerStyling={{ marginBottom: 15 }}
											/>
										))}


									<ConfirmationInfoBreakDown>
										<span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
											<Text>Subtotal</Text>
											<Text style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(getProductTotal())}</Text>
										</span>

										<span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
											<Text>Shipping</Text>
											<Text style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(shippingRate)}</Text>
										</span>

										<span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
											<Text>Discount</Text>
											<Text style={{ textAlign: 'right' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}</Text>
										</span>

										<span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<Text style={{ fontSize: 18, color: Colors.carbonBlack }}>Total</Text>
											<Text style={{ textAlign: 'right', fontSize: 18, color: Colors.carbonBlack }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(getProductTotal(true))}</Text>
										</span>
									</ConfirmationInfoBreakDown>
									<div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
										<StandardButton
											value={"Cancel"}
											// disable={cartData?.response?.cartItems.length === 0}
											containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: Colors.orange, borderStyle: "solid" }}
											textStyling={{ color: Colors.orange }}
											onClick={() => navigate(`/account/cart`)}
										>
											Checkout
										</StandardButton>
										<StandardButton
											value={"Confirm"}
											disable={
												showButtonLoader ||
												(pickupPaymentMethod === null || pickupPaymentMethod === "" || modalDeliveryMethod === null || modalDeliveryMethod === "" || orderAddress === null)
											}
											containerStyling={{ backgroundColor: Colors.orange }}
											textStyling={{ color: showButtonLoader || (pickupPaymentMethod === null || pickupPaymentMethod === "" || modalDeliveryMethod === null || modalDeliveryMethod === "" || orderAddress === null) ? Colors.black : Colors.white }}
											onClick={() => submitOrder(
												() => {
													navigate(`/account/order-history`)
												},
												() => {
													/**
													 * TODO: Add MUI alert to top of screen
													 */
													navigate(`/account/cart`)
												}
											)}
										>
											Checkout
										</StandardButton>
									</div>
								</ConfirmationContainer>
							</SectionContainer>

						</SectionDivider>

					</ContentWrapper>
				</ContentContainer>
			</div>
			<Footer />

			{/* NOTE: The below is used to submit the data to lasco payment website url, see form ref variable for action */}
			<form ref={formRef} action={`${LASCOPAY_URL}/standard-checkout`} method="post" style={{ visibility: 'hidden' }}>
				{Object.keys(lascoPaySignatureData).map((key) => (
					<input key={key} type="hidden" name={key} value={lascoPaySignatureData[key]} />
				))}
				<button type='submit'>Submit</button>
			</form>
		</PageContainer>
	)
}


const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;

`;

const ContentWrapper = styled.div`
    width: 100%;
`;

let BoldText = styled.h3`
  font-family: Poppins;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
  margin-bottom: 15px;
`;

let SectionDivider = styled.div`
	display: flex;
	gap: 20px;
	@media only screen and (max-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
		flex-direction: column;
	}
`;

let SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

let CheckoutPreContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	@media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}){
		min-width: 450px;
	}
`;

let DeliveryMethodContainer = styled.div`
	background-color: ${Colors.white};
	border-radius: 8px;
	padding: 15px;
	max-width: 600px;
`;

let PaymentInformationContainer = styled.div`
	background-color: ${Colors.white};
	border-radius: 8px;
	padding: 15px;
	max-width: 600px;
`;

let ConfirmationContainer = styled.div`
	background-color: ${Colors.white};
	border-radius: 8px;
	padding: 15px;
	max-width: 600px;
`;

let Text = styled.p`
	font-family: Poppins;
	text-align: left;
	padding: 0;
	margin: 0;
	color: ${Colors.black600};
	font-size: 16px;
`;

const DarkText = styled.p`
	font-family: Poppins;
	text-align: left;
	padding: 0;
	margin: 0;
	color: ${Colors.carbonBlack};
	font-weight: 500;
	font-size: 16px;
`;

let ConfirmationInfoBreakDown = styled.div`
	background-color: ${Colors.white100};
	border-radius: 6px;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
	padding: 15px;
	margin-bottom: 15px;
`;

let LascoPayText = styled.span`
	display: inline-flex;
	align-items: center;
	gap: 10px;
`;


export default CheckoutPage;