import React, { CSSProperties, FC } from 'react';
import styled from "styled-components";
import Colors from '../../constants/Colors';
import { SCREEN_WIDTH_CONSTANTS } from '../../constants/Devices';
import { PRODUCT_STATUS, PRODUCT_STATUS_MAPPING } from '../../constants/ProductStatus';
import { format } from 'date-fns'
import { useNavigate } from "react-router-dom";
import { Chip, Divider } from '@mui/material';
import { FiLayers, FiTruck } from "react-icons/fi";
import { OrderDetailModel } from '../../features/order/types';

interface OrderHistoryCardInterface {
    imageUrl?: string;
    cancelable: boolean;
    date: string;
    status: string;
    productName?: string,
    containerStyling?: CSSProperties;
    orderCount: number;
    orderTotal: number;
    orderId: number;
    orderMethod: string;
    trackingNumber: string | null
    orderDetails?: OrderDetailModel[]
}

let OrderHistoryCard: FC<OrderHistoryCardInterface> = (props) => {
    let navigate = useNavigate();
    return (
        <Container style={{ ...props.containerStyling }}>
            <InfoContainer>
                <OptionsContainer>
                    <Text
                        style={{
                            color: Colors.darkGrey,
                            fontSize: "16px",
                            fontWeight: 'bold'
                        }}
                    >
                        {format(new Date(props.date), 'EEE PP')}
                    </Text>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 15
                        }}
                    >
                        <Text
                            style={{
                                color: Colors.lightGrey2,
                                fontSize: "16px"
                            }}
                        >
                            Order ID: Ord-{props.orderId}
                        </Text>
                        <Text
                            style={{
                                fontSize: "16px",
                                textDecoration: "underline",
                                cursor: "pointer",
                                color: Colors.blue
                            }}
                            onClick={() => navigate(`/account/order-history/invoice/${props.orderId}`)}
                        >
                            Invoice
                        </Text>
                    </div>
                </OptionsContainer>

                <OrderProductContainer>

                    {props.orderDetails?.map((detail, index) => (
                        <ProductInformationContainer>
                            <Text style={{ fontSize: "16px", fontWeight: 'bold' }}>
                                {detail.product.productName ?? "N/A"}
                            </Text>

                            <div>
                                <Chip
                                    color='success'
                                    label={
                                        <p style={{ color: "#fff" }}>
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(detail.total)} JMD
                                        </p>
                                    } />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                                <Chip
                                    avatar={<FiLayers />}
                                    label={`Qty ${detail.quantity ?? "0"}`} />
                                <Chip
                                    avatar={<FiTruck />}
                                    label={props.orderMethod} />
                            </div>
                            {props.orderMethod !== "PICKUP" && <Text
                                style={{
                                    color: Colors.lightGrey2,
                                    fontSize: "16px"
                                }}
                            >
                                Tracking Number: <span style={{ textDecoration: "underline", fontSize: "16px" }}>{props.trackingNumber ?? "N/A"}</span>
                            </Text>}
                            <Text
                                style={{
                                    color: props.status === PRODUCT_STATUS.completed ? Colors.green
                                        : (props.status === PRODUCT_STATUS.processing || props.status === PRODUCT_STATUS['Order Paid'])  ? Colors.orange
                                            : Colors.red,
                                    fontSize: "16px"
                                }}
                            >
                                {PRODUCT_STATUS_MAPPING[props.status]}
                            </Text>
                            {props.orderDetails && index !== props.orderDetails?.length - 1 ? <Divider /> : null}
                        </ProductInformationContainer>
                    ))}
                </OrderProductContainer>

            </InfoContainer>
        </Container>
    )
}

const Container = styled.div`
    /* height: 125px; */
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        /* height: 80px; */
        /* max-width: 600px; */
    }
`;

const InfoContainer = styled.div`
    /* padding-left: 10px; */
    width: 100%;
    background-color: ${Colors.lightGrey1}
`;

const Text = styled.p`
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.darkGrey};
    text-align: left;
`;

const OrderProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    /* width: 100%; */
    background-color: ${Colors.white};
`;

const ProductInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;


const OptionsContainer = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top: 15px; */
`;


export default OrderHistoryCard;