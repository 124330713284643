import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { GeneralResponseType, PaginationResponseType } from "../types";
import {ProductSearchTypes, ProductType} from "./types";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const productApiSlice = createApi({
    reducerPath: "ProductApi",
    baseQuery: fetchBaseQuery({baseUrl: `${CUSTOMER_API_BASE_URL}/item`}),
    tagTypes: ['Product'],
    endpoints:(builder)=>({
        search: builder.query<GeneralResponseType<PaginationResponseType<ProductType>>, ProductSearchTypes>({
            query:(criteria)=>({
                url: "/accessories",
                method: "POST",
                body: criteria
            }),
            providesTags:['Product'],
            // keepUnusedDataFor: 60 //Seconds to keep cache data
        }),

        getProduct:builder.query<GeneralResponseType<ProductType>, string>({
            query:(productId)=> `/accessories/${productId}`,
        }),

        getProductsByIds: builder.query<GeneralResponseType<Array<ProductType>>, Array<number>>({
            query:(criteria)=>({
                url: "/list",
                method: "POST",
                body: criteria
            }),
            providesTags:['Product'],
            keepUnusedDataFor: 60 //Seconds to keep cache data
        }),

        getFeaturedProducts:builder.query<GeneralResponseType<PaginationResponseType<ProductType>>, {page:number, count:number}>({
            query:({page, count})=> ({
                url: `/accessories/featured-products?page=${page}&count=${count}`,
                method: "GET",
            }),
            keepUnusedDataFor: 300 //Seconds to keep cache data
        }),
    })
})

export const {useLazySearchQuery, useGetProductQuery,useGetFeaturedProductsQuery, useLazyGetProductsByIdsQuery} = productApiSlice;