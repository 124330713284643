import {AuthStateType} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState: AuthStateType = {
    accessToken: null,
    error: null,
    isAuthUser: false,
    isGuestUser: false,
};

const authSlice = createSlice({
    name : "AuthSlice",
    initialState,
    reducers:{
        setAuthUser(state, action: PayloadAction<string | undefined>){
            console.log("setAuthUser ACTION!");
            state.accessToken = action.payload;
            state.isAuthUser = true;
            state.isGuestUser = false;
        },
        setGuestUser (state, action: PayloadAction<string | undefined>){
            console.log("setGuestUser ACTION!");
            state.accessToken = action.payload;
            state.isAuthUser = false;
            state.isGuestUser = true;
        },
        resetGlobalState(){
            console.log("Reset Global State action!");
            storage.removeItem("persist:rtk:auth");
        }

    },
});

export const {setAuthUser, setGuestUser, resetGlobalState } = authSlice.actions

export const authReducer = persistReducer({
    key: 'rtk:auth',
    storage,
    whitelist:['accessToken']
}, authSlice.reducer);



