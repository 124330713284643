import React, { useEffect, useState } from "react";
import styled from "styled-components";
// COMPONENT IMPORT
// import Email from "../components/Email";
import TextInput from "../components/TextInput";
import Button from "../components/StandardButton";
import Footer from "../components/Universal/Footer";
import Colors from "../constants/Colors";
import { useLazyResetPasswordLinkQuery, useLazyResetPasswordQuery } from "../features/auth/authApiSlice";
import ModalContainer from "../components/Modal/ModalContainer";
import MessageModal from "../components/Modal/MessageModal";
import { isValidEmailAddress } from "../utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";

const ForgetPage = () => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(false);
  let [messageModalVisible, setMessageModalVisible] = useState(false);
  let [messageModalMessage, setMessageModalMessage] = useState("");
  let [resetPassword, setResetPassword] = useState(false);
  let [passwordToken, setPasswordToken] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [passwordError, setPasswordError] = useState(false);
  let [confirmPasswordError, setConfirmPasswordError] = useState(false);
  let [triggerGetResetLink] = useLazyResetPasswordLinkQuery();
  let [triggerResetPassword] = useLazyResetPasswordQuery();

  let getResetLink = () => {
    if (email === "") {
      setMessageModalMessage("Field is empty")
      setMessageModalVisible(true)
      setEmailError(true)
      return;
    }

    if (!isValidEmailAddress(email)) {
      setMessageModalMessage("Not a valid email address")
      setMessageModalVisible(true)
      setEmailError(true)
      return;
    }

    triggerGetResetLink({ email })
      .unwrap()
      .then(response => {
        setMessageModalMessage("Password reset link sent to email")
        setMessageModalVisible(true)
      })
      .catch(error => {
        setMessageModalMessage("Error sending password reset link")
        setMessageModalVisible(true)
      })
  }

  let resetPasswordRequest = () => {
    if(password===""||confirmPassword===""){
      setMessageModalMessage("Field(s) are empty")
      setMessageModalVisible(true)
      setPasswordError(true)
      setConfirmPasswordError(true)
    }

    if(password!==confirmPassword){
      setMessageModalMessage("Fields don't match")
      setMessageModalVisible(true)
      setPasswordError(true)
      setConfirmPasswordError(true)
    }

    triggerResetPassword({password: password, token: passwordToken})
    .unwrap()
    .then(()=>{
      setMessageModalMessage("Password has been reset")
      setMessageModalVisible(true)
      navigate("/");
    })
    .catch(()=>{
      setMessageModalMessage("An error occured.")
      setMessageModalVisible(true)
    })
  }

  useEffect(() => {
    let passwordReset = searchParams.get("password-reset");
    let passwordToken = searchParams.get("token");
    if (passwordReset !== null && passwordToken !== null) {
      setPasswordToken(passwordToken)
      setResetPassword(passwordReset === "true")
    }
  }, [searchParams])

  return (
    <Container>
      <Content>
        <div>
          <Hero>
            <img src="images/rec-pwd-hero.jpg" alt="Welcome hero" />
          </Hero>
          <Form>
            <Heading>Recover Password</Heading>
            {!resetPassword&&<TextInput
              placeholder='Email'
              iconName="mail"
              showErrorBorder={emailError}
              type='email'
              onChange={(val: string) => {
                setEmail(val);
                if (val === "" && !emailError) setEmailError(true)
                if (val !== "" && emailError) setEmailError(false)
              }} />}

            {resetPassword && <>
              <TextInput
                placeholder='Password'
                type='password'
                showErrorBorder={passwordError}
                iconName='lock'
                onChange={(val: string) => {
                  setPassword(val);
                  if (val === "" && !passwordError) setPasswordError(true)
                  if (val !== "" && passwordError) setPasswordError(false)
                }} />
              <TextInput
                placeholder='Confirm Password'
                type='password'
                showErrorBorder={confirmPasswordError}
                iconName='lock'
                onChange={(val: string) => {
                  setConfirmPassword(val);
                  if (val === "" && !confirmPasswordError) setConfirmPasswordError(true)
                  if (val !== "" && confirmPasswordError) setConfirmPasswordError(false)
                }} />
            </>}
            <Button value={resetPassword ? 'Reset Password' : 'Send Reset Email'} 
              onClick={resetPassword ? resetPasswordRequest : getResetLink} />
            <LoginHere>
              Already have an account?
              <LoginLink href="/login"> Login Here</LoginLink>
            </LoginHere>
          </Form>
        </div>
      </Content>
      <ModalContainer visible={messageModalVisible}>
        <MessageModal
          message={messageModalMessage}
          onClickConfirm={() => setMessageModalVisible(false)}
        />
      </ModalContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;


const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Hero = styled.div`
  & > img {
    width: 100%;
    max-width:250px;
    min-width: 250px;
  }
`;

const Heading = styled.h3`
  margin-right: auto;
  color: #707070;
  font-weight: 500;
`;

const Form = styled.form`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 350px;
  min-width: 250px;
`;

const LoginHere = styled.span`
  margin-top: 20px;
`;

const LoginLink = styled.a`
  color: ${Colors.green};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export default ForgetPage;
