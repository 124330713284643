import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Colors from "../constants/Colors";

interface SearchBarInterface {
  placeholder?: string;
  containerStyling?: CSSProperties;
  onChange?: Function;
  onSearchClick?: Function
}

const SearchBar: FC<SearchBarInterface> = (props) => {
  return (
    <Container className="col-sm-12 col-lg-4" style={{ ...props.containerStyling }}>
      <Search
        placeholder={props.placeholder ? props.placeholder : "Search"}
        onChange={(event) => { if (props.onChange) props.onChange(event.target.value) }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            console.log("here")
            if (props.onSearchClick) props.onSearchClick()
          }
        }}
      />
      <FeatherIcon
        icon={"search"} size={24} className="search"
        style={{ stroke: Colors.black400, strokeWidth: 2, cursor: 'pointer', }}
        onClick={() => props.onSearchClick && props.onSearchClick()} />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${Colors.white};
  border-radius: 6px;
  height: 36px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  align-items: center;
  & > .search {
    margin-right: 20px;
  }

`;

const Search = styled.input`
  border: none;
  background: none;
  width: 80%;
  margin-left: 20px;
  margin-right: auto;
  color: ${Colors.black400};
  font-weight: 400;
  :focus{
    outline: none;
  }
`;

export default SearchBar;
