import React, { FC, useState } from 'react';
import styled from "styled-components";
import { FiX } from "react-icons/fi";

//Local Imports
import Colors from '../../constants/Colors';
import Checkable from '../Form/Checkable';
import InputField from '../Form/InputField';
import StandardButton from '../StandardButton';
import { Divider } from '@mui/material';

interface SearchFilterModalInterface {
    closeModal: Function;
    onMinValueChange: Function;
    onMaxValueChange: Function;
    onCategoryChange: Function;
    onFilterSubmit: Function;
    searchCategory: string;
    minPrice: number;
    maxPrice: number;
}

let SearchFilterModal: FC<SearchFilterModalInterface> = (props) => {

    const [isUnder1000Selected, setIsUnder1000Selected] = useState(false)
    const [isBetween1000and5000Selected, setIsBetween1000and5000Selected] = useState(false)
    const [isBetween5000and15000Selected, setIsBetween5000and15000Selected] = useState(false)
    const [isAbove15000Selected, setIsAbove15000Selected] = useState(false)
    const [isMinMaxDisabled, setIsMinMaxDisabled] = useState(false)
    // const[minValue, setMinValue] = useState(0)
    // const[maxValue, setMaxValue] = useState(0)

    return (
        <ModalWrapper>
            <ModalContainer>
                <HeaderContainer>
                    <Text
                        style={{ fontWeight: '500' }}
                    >
                        Search Filter
                    </Text>
                    <FiX style={{ stroke: Colors.black }} size={24} onClick={()=>props.closeModal()}/>
                </HeaderContainer>

                <Divider />

                <Text
                    style={{ fontSize: 16, fontWeight: '500' }}
                >
                    Category
                </Text>

                <CheckboxContainer>
                    {
                        ["Electronics", "Care and Maintenance", "Headlight, Bulbs and Lights", "Vinyl, Wraps and Tints", "Body Modification and Addons"].map(value => (
                            <Checkable
                                labelText={value}
                                value={props.searchCategory === value}
                                onChange={(e: boolean) => {
                                    props.onCategoryChange(value)
                                }}
                            />
                        ))
                    }
                </CheckboxContainer>
                
                <Divider />

                <Text
                    style={{ fontSize: 16, fontWeight: '500' }}
                >
                    Price
                </Text>

                <CheckboxContainer>
                    <Checkable
                        labelText='Under $1000'
                        value={isUnder1000Selected}
                        onChange={(e: boolean) => {
                            setIsUnder1000Selected(e);
                            setIsBetween1000and5000Selected(false)
                            setIsBetween5000and15000Selected(false)
                            setIsAbove15000Selected(false)
                            props.onMinValueChange(1)
                            props.onMaxValueChange(1000)
                            // setMinValue(1)
                            // setMaxValue(1000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                    <Checkable
                        labelText='$1000 to $5000'
                        value={isBetween1000and5000Selected}
                        onChange={(e: boolean) => {
                            setIsUnder1000Selected(false);
                            setIsBetween1000and5000Selected(e)
                            setIsBetween5000and15000Selected(false)
                            setIsAbove15000Selected(false)
                            props.onMinValueChange(1000)
                            props.onMaxValueChange(5000)
                            // setMinValue(1000)
                            // setMaxValue(5000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                    <Checkable
                        labelText='$5000 to $15,000'
                        value={isBetween5000and15000Selected}
                        onChange={(e: boolean) => {
                            setIsUnder1000Selected(false);
                            setIsBetween1000and5000Selected(false)
                            setIsBetween5000and15000Selected(e)
                            setIsAbove15000Selected(false)
                            props.onMinValueChange(5000)
                            props.onMaxValueChange(15000)
                            // setMinValue(5000)
                            // setMaxValue(15000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                    <Checkable
                        labelText='$15,000 & above'
                        value={isAbove15000Selected}
                        onChange={(e: boolean) => {
                            setIsUnder1000Selected(false);
                            setIsBetween1000and5000Selected(false)
                            setIsBetween5000and15000Selected(false)
                            setIsAbove15000Selected(e)
                            props.onMinValueChange(15000)
                            props.onMaxValueChange(100000)
                            // setMinValue(15000)
                            // setMaxValue(100000)
                            setIsMinMaxDisabled(e)
                        }}
                    />
                </CheckboxContainer>

                <div style={{ display: "flex", flexDirection: "row", gap: 15}}>
                    <InputField
                        placeholder="Min"
                        type="number"
                        value={props.minPrice}
                        min={0}
                        containerStyling={{ flex: 1, marginBottom: 0 }}
                        onChange={(value) => {
                            // setMinValue(value)
                            props.onMinValueChange(value);
                        }}
                        disabled={isMinMaxDisabled}
                    />
                    <InputField
                        placeholder="Max"
                        type="number"
                        value={props.maxPrice}
                        max={1000000}
                        containerStyling={{ flex: 1, marginBottom: 0 }}
                        onChange={(value) => {
                            // setMaxValue(value)
                            props.onMaxValueChange(value)
                        }}
                        disabled={isMinMaxDisabled}
                    />
                </div>

                <div>
                    <StandardButton
                        value='Confirm'
                        containerStyling={{ backgroundColor: Colors.orange }}
                        onClick={props.onFilterSubmit}
                    />
                </div>

            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

let ModalContainer = styled.div`
  min-width: 300px;
  max-width: 380px;
  /* height: 410px; */
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

let HeaderContainer = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

let CheckboxContainer = styled.div`
    
`;

let Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
`;

export default SearchFilterModal;