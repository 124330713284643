import React, { FC, useState } from 'react';
import styled from "styled-components";

import Button from '../../components/StandardButton'
import Colors from '../../constants/Colors';
import { isValidPhoneNumber } from '../../utils/utils';
import InputField from '../Form/InputField';

interface MessageModalInterface {
    firstName: string | any
    lastName: string | any
    phoneNumber: string | any;
    onChangeFirstName: Function;
    onChangeLastName: Function;
    onChangePhoneNumber: Function;
    onClickConfirm: Function;
    onClickCancel: Function;
}

let SingleSignOnPersonalInfoUpdateModal: FC<MessageModalInterface> = (props) => {

    let [phoneNumberError, setPhoneNumberError] = useState(false);
    let [firstNameError, setFirstNameError] = useState(false);
    let [lastNameError, setLastNameError] = useState(false);

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{ marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px" }}>Update Personal Information</Text>
                <p>Please update the following information below. This will be used when packaging items</p>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: 15
                }}>
                    <Label>Full Name</Label>
                    <InputField
                        placeholder={'First Name'}
                        value={props.firstName}
                        showErrorBorder={firstNameError}
                        containerStyling={{ marginBottom: 15 }}
                        onChange={(val) => {
                            props.onChangeFirstName(val)
                            if (val === "" && !firstNameError) setFirstNameError(true)
                            if (val !== "" && firstNameError) setFirstNameError(false)
                        }}
                    />
                    <InputField
                        placeholder={'Last Name'}
                        value={props.lastName}
                        showErrorBorder={lastNameError}
                        onChange={val => {
                            props.onChangeLastName(val)
                            if (val === "" && !lastNameError) setLastNameError(true)
                            if (val !== "" && lastNameError) setLastNameError(false)
                        }}
                    />
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: 15
                }}>
                    <Label>Phone Number</Label>
                    <InputField
                        placeholder={'format: 876-000-0000'}
                        value={props.phoneNumber}
                        type={'tel'}
                        showErrorBorder={phoneNumberError}
                        max={16}
                        min={16}
                        onChange={val => {
                            props.onChangePhoneNumber(val)
                            if (val === "" && !phoneNumberError) setPhoneNumberError(true)
                            // if (val !== "" && phoneNumberError) setPhoneNumberError(false)
                            if (!isValidPhoneNumber(val) && !phoneNumberError) setPhoneNumberError(true)
                            if (isValidPhoneNumber(val) && phoneNumberError) setPhoneNumberError(false)
                        }}
                    />
                </div>

                <div style={{
                    display: 'grid',
                    gridColumnGap: 15,
                    gridTemplateColumns: '1fr 1fr',
                    width: "100%",
                }}>
                    <Button
                        value="Cancel"
                        // containerStyles={{ marginLeft: 10 }}
                        containerStyling={{ width: '100%', backgroundColor: Colors.red }}
                        onClick={props.onClickCancel}
                    />
                    <Button
                        value="Done"
                        disable={(firstNameError || lastNameError || phoneNumberError)
                            || (props.firstName === "" || props.lastName === "" || props.phoneNumber === "")
                            || (props.firstName === null || props.lastName === null || props.phoneNumber === null)}
                        // containerStyles={{ marginLeft: 10 }}
                        containerStyling={{ width: '100%' }}
                        onClick={props.onClickConfirm}
                    />
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.darkGrey};
`;

let Label = styled.span`

`

export default SingleSignOnPersonalInfoUpdateModal;
