import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { CartItemModel, CartStateType } from "./types";

const initialState: CartStateType = {
    items: [],
    count: 0,
    error: null
}

const cartSlice = createSlice({
    name: "CartSlice",
    initialState,
    reducers: {
        setCartItems(state, action: PayloadAction<Array<CartItemModel>>) {
            state.items = action.payload
        },
        addCartItem(state, action: PayloadAction<CartItemModel>){
            state.items?.push(action.payload)
        },
        updateCartCount(state,action: PayloadAction<number>){
            state.count = action.payload
        }
    }
})

export const {setCartItems, addCartItem,updateCartCount} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;