import React, { useEffect, useState } from "react";
import styled from "styled-components";
// COMPONENT IMPORT
import TextInput from "../components/TextInput";
import Button from '../components/StandardButton'
import ModalContainer from "../components/Modal/ModalContainer";
import { useNavigate } from "react-router-dom";
import Colors from "../constants/Colors";
import MessageModal from "../components/Modal/MessageModal";
import { LoginResponseType } from "../features/auth/types";
import { useLazyFirebaseLoginQuery, useLazyLoginQuery } from "../features/auth/authApiSlice";
import { persistor, useAppDispatch, useAppSelector } from "../store";
import { setAuthUser } from "../features/auth/authSlice";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { firebaseAuth } from "../firebaseSetup";
import Footer from "../components/Universal/Footer";
import UpdatePersonalInfoModal from "../components/Modal/UpdatePersonalInfoModal";
import SingleSignOnPersonalInfoUpdateModal from "../components/Modal/SingleSignOnPersonalInfoUpdateModal";
import { useLazyGetCustomerInfoQuery, useUpdateCustomerInfoMutation } from "../features/customer/customerApiSlice";
import { CustomerType } from "../features/customer/types";
import { setCustomerInfo, updateCustomerInfo } from "../features/customer/customerSlice";
import { isValidPhoneNumber } from "../utils/utils";

interface firebasePersistInfoType {
    firstName?: string,
    lastName?: string,
    email: string,
    phoneNumber?: string
}

const WelcomePage = (props) => {
    let [triggerFirebaseLogin] = useLazyFirebaseLoginQuery();
    let [triggerGetCustomerInfo] = useLazyGetCustomerInfoQuery();
    let [triggerUpdateCustomerInfo] = useUpdateCustomerInfoMutation();
    const stateCustomerInfo = useAppSelector(state => state.customer)
    let navigate = useNavigate();

    let [customer, setCustomer] = useState<CustomerType>(stateCustomerInfo.customer ? stateCustomerInfo.customer : {} as CustomerType);

    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");

    let [emailError, setEmailError] = useState(false);
    let [passwordError, setPasswordError] = useState(false);

    let [messageModalVisible, setMessageModalVisible] = useState(false);
    let [messageModalMessage, setMessageModalMessage] = useState("");

    let [updateCustomerInfoModal, setUpdateCustomerModalVisible] = useState(false);
    let [customerFullName, setCustomerFullName] = useState("")

    let [triggerLogin] = useLazyLoginQuery();

    let dispatch = useAppDispatch();

    useEffect(() => {
        //Setup local storage
        persistor.persist();
    }, [])

    useEffect(() => {
        setCustomer(stateCustomerInfo.customer as CustomerType)
    }, [stateCustomerInfo.customer])

    let updateCustomerInfoCall = () => {

        if (customer.firstName === "" || customer.lastName === "" || customer.phoneNumber === "") {
            setMessageModalMessage("There are still empty fields")
            setMessageModalVisible(true)
            return;
        }

        if (!isValidPhoneNumber(customer.phoneNumber)) {
            setMessageModalMessage("Phone number format is incorrect")
            setMessageModalVisible(true)
            return;
        }

        triggerUpdateCustomerInfo(customer)
            .unwrap()
            .then((data => {
                dispatch(updateCustomerInfo(data.response?.updated_user ? data.response.updated_user : customer))
                setUpdateCustomerModalVisible(false)
                navigate("/");
            }))
            .catch((error) => {
                setUpdateCustomerModalVisible(false)
                setMessageModalMessage(error.message);
                setMessageModalVisible(true)
            })
    }

    let submitLoginRequest = () => {
        if (email === "" || password === "") {
            setMessageModalMessage("There are still empty fields")
            setMessageModalVisible(true)
            return;
        }

        triggerLogin({ email, password })
            .unwrap()
            .then((payload: LoginResponseType) => {
                // console.log("Unwrap success", payload.response?.access_token)
                dispatch(setAuthUser(payload.response?.access_token));
                navigate("/")
            })
            .catch(({ data }: { data: LoginResponseType }) => {
                // console.error("Unwrap error", data)
                setMessageModalMessage(data.message);
                setMessageModalVisible(true)
            })
    }

    let submitGoogleAuth = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(firebaseAuth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;
                console.log("<--- user --->", user)
                let fullName = user.displayName?.split(" ");

                let firebasePersistInfo: firebasePersistInfoType = {
                    email: user.email!
                }

                if (user.phoneNumber) {
                    firebasePersistInfo.phoneNumber = user.phoneNumber
                }

                if (fullName && fullName.length > 1) {
                    firebasePersistInfo.firstName = fullName[0];
                    firebasePersistInfo.lastName = fullName[1];
                }
                console.log(firebasePersistInfo);

                triggerFirebaseLogin(firebasePersistInfo).unwrap()
                    .then((data: LoginResponseType) => {
                        dispatch(setAuthUser(data.response?.access_token));
                        GetAndUpdateCustomerInfo()
                        // navigate("/");
                    })
                    .catch((error) => {
                        setMessageModalMessage("Couldn't login now, please try again soon");
                        setMessageModalVisible(true)
                    })

                // ...
            }).catch((error) => {
                setMessageModalMessage("Couldn't login now, please try again soon");
                setMessageModalVisible(true)
            });
    }

    let GetAndUpdateCustomerInfo = () => {
        triggerGetCustomerInfo()
            .unwrap()
            .then(data => {
                dispatch(setCustomerInfo(data?.response))
                if (data.response?.customer.socialAuthProvider && (data.response?.customer.phoneNumber === "" || data.response?.customer.phoneNumber === null)) {
                    setUpdateCustomerModalVisible(true)
                } else {
                    navigate("/");
                }
                // setCustomer(stateCustomerInfo.customer ? stateCustomerInfo.customer : {} as CustomerType)

            })
    }

    return (
        <Container>
            <Content>
                <div>
                    <Hero>
                        <img src="images/welcome-hero.jpg" alt="Undrawn Welcome hero" />
                    </Hero>
                    <Form>
                        <Heading>Welcome back!</Heading>
                        <TextInput
                            placeholder='Email'
                            iconName="mail"
                            showErrorBorder={emailError}
                            type='email'
                            onChange={(val: string) => {
                                setEmail(val);
                                if (val === "" && !emailError) setEmailError(true)
                                if (val !== "" && emailError) setEmailError(false)
                            }} />
                        <TextInput
                            placeholder='Password'
                            iconName='lock'
                            showErrorBorder={passwordError}
                            type='password'
                            onChange={(val: string) => {
                                setPassword(val);
                                if (val === "" && !passwordError) setPasswordError(true)
                                if (val !== "" && passwordError) setPasswordError(false)
                            }} />
                        <Forget href="/recovery">Forgot Password</Forget>
                        <Button value='LOGIN' onClick={submitLoginRequest} />

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SubHeading>Or</SubHeading>
                        </div>

                        <Button value='Sign in with Google' containerStyling={{ marginTop: "10px", backgroundColor: "#4285f4" }} onClick={submitGoogleAuth} />

                        <LinkContainer>
                            Don't have an account?
                            <Link href="/sign-up"> Register Here</Link>
                        </LinkContainer>
                    </Form>
                </div>
            </Content>
            <ModalContainer visible={messageModalVisible}>
                <MessageModal
                    message={messageModalMessage}
                    onClickConfirm={() => setMessageModalVisible(false)}
                />
            </ModalContainer>

            {customer !== null && <ModalContainer visible={updateCustomerInfoModal}>
                <SingleSignOnPersonalInfoUpdateModal
                    firstName={customer.firstName}
                    lastName={customer.lastName}
                    phoneNumber={customer.phoneNumber}
                    onChangeFirstName={(val: string) => setCustomer({ ...customer, firstName: val })}
                    onChangeLastName={(val: string) => setCustomer({ ...customer, lastName: val })}
                    onChangePhoneNumber={(val: string) => setCustomer({ ...customer, phoneNumber: val })}
                    onClickConfirm={() => { updateCustomerInfoCall() }}
                    onClickCancel={() => { setUpdateCustomerModalVisible(false) }}
                />
            </ModalContainer>}
            <Footer />
        </Container>
    );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Hero = styled.div`
  & > img {
    width: 100%;
    max-width:350px;
    min-width: 250px;
  }
`;

const Heading = styled.h1`
  margin-right: auto;
  color: #707070;
  font-weight: 500;
`;

const SubHeading = styled.h3`
  color: #707070;
  font-weight: 500;
`;

const Form = styled.form`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 350px;
  min-width: 250px;
`;

const Forget = styled.a`
  margin-bottom: 20px;
  margin-left: auto;
  font-size: 14px;
  cursor: pointer;
  color: #9e9e9e;
  &:hover {
    text-decoration: underline;
  }
`;

const LinkContainer = styled.span`
  margin-top: 20px;
`;

const Link = styled.a`
  color: ${Colors.green};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export default WelcomePage;
