import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {resetGlobalState} from "../features/auth/authSlice";
import {setNotification} from "../features/notification/notificationSlice";

export const unauthenticatedMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action) && (action.payload.status === 401 || action.payload.status === 403)) {
            const {data} = action.payload
            api.dispatch(resetGlobalState())

            console.warn('Authentication error!')

            console.warn("action", action)

            api.dispatch(setNotification({message: data.message, show: true}));
        }

        return next(action)
    }