import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {LoginCredentialType, RegisterType} from "./types";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL


export const authApiSlice = createApi({
    reducerPath: "AuthApi",
    baseQuery: fetchBaseQuery({baseUrl: `${CUSTOMER_API_BASE_URL}/auth/customer`}),
    endpoints:(builder)=>({
        login: builder.query<any, LoginCredentialType>({
            query:(loginCredential)=>({
                url: "/login",
                method: "POST",
                body: loginCredential
            }),
        }),

        resetPasswordLink: builder.query<any, {email: string}>({
            query:(request)=>({
                url: "/password-reset/link",
                method: "POST",
                body: request
            }),
        }),

        resetPassword: builder.query<any, {password: string, token: string}>({
            query:(request)=>({
                url: "/password-reset",
                method: "POST",
                body: request
            }),
        }),

        firebaseLogin:builder.query({
            query:(firebaseCredential)=>({
                url:"/firebase-login",
                method:"POST",
                body: firebaseCredential
            })
        }),

        register: builder.query<any, RegisterType>({
            query:(registerCredential)=>({
                url: "/signup",
                method: 'POST',
                body: registerCredential
            })
        })
    })
})

export const {useLazyLoginQuery, useLazyRegisterQuery, useLazyFirebaseLoginQuery,useLazyResetPasswordLinkQuery, useLazyResetPasswordQuery} = authApiSlice;