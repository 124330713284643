import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux'

// COMPONENT IMPORTS
import NavBar from "../components/NavBar";
import Colors from "../constants/Colors";
import { RootState } from '../store'
import Footer from "../components/Universal/Footer";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";
import Button from "../components/StandardButton";
import FeatherIcon from "feather-icons-react";
import { useLazySubmitContactUsFormQuery } from "../features/contactUs/contactUsApiSlice";
import ModalContainer from "../components/Modal/ModalContainer";
import { isValidEmailAddress, isValidPhoneNumber } from "../utils/utils";
import MessageModal from "../components/Modal/MessageModal";
import { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";

const ContactUs = (props) => {

  const stateCustomerInfo = useSelector((state: RootState) => state.customer)
  const dispatch = useDispatch();

  let [triggerSubmitContactUsInfo] = useLazySubmitContactUsFormQuery()

  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [message, setMessage] = useState("")
  let [phoneNumber, setPhoneNumber] = useState("")
  let [nameError, setNameError] = useState(false)
  let [emailError, setEmailError] = useState(false)
  let [messageError, setMessageError] = useState(false)
  let [phoneNumberError, setPhoneNumberError] = useState(false)

  let [messageModalVisible, setMessageModalVisible] = useState(false);
  let [messageModalMessage, setMessageModalMessage] = useState("");

  let submitForm = () => {
    if (name === "" || email === "" || phoneNumber === "") {
      setMessageModalMessage("There are still empty fields")
      setMessageModalVisible(true)
      return;
    }

    if (name.split(" ").length == 1) {
      setMessageModalMessage("Please enter your full name")
      setMessageModalVisible(true);
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setMessageModalMessage("Phone number format is incorrect")
      setMessageModalVisible(true)
      setPhoneNumberError(true)
      return;
    }

    if (!isValidEmailAddress(email)) {
      setMessageModalMessage("Email format is incorrect")
      setMessageModalVisible(true)
      setEmailError(true)
      return;
    }

    triggerSubmitContactUsInfo({
      customerName: name,
      customerEmail: email,
      customerMessage: message,
      customerPhoneNumber: phoneNumber
    })
      .unwrap()
      .then(response => {
        setMessageModalMessage("Form Information Submitted")
        setMessageModalVisible(true)
      })
  }

  return (
    <Container>
      <NavBar />
      <ContentContainer>
        <div style={{maxWidth: 800}}>
        <Heading>Contact</Heading>
          <PositionContainer>
            <FormContainer>
              <Heading>Send a message!</Heading>
              <TextInput
                placeholder='Full Name'
                iconName='user'
                showErrorBorder={nameError}
                type='text'
                onChange={(val: string) => {
                  setName(val);
                  if (val === "" && !nameError) setNameError(true)
                  if (val !== "" && nameError) setNameError(false)
                }} />
              <TextInput
                placeholder='Email'
                iconName="mail"
                showErrorBorder={emailError}
                type='email'
                onChange={(val: string) => {
                  setEmail(val);
                  if (val === "" && !emailError) setEmailError(true)
                  if (val !== "" && emailError) setEmailError(false)
                }} />
              <TextInput
                placeholder='Phone Number'
                iconName="phone"
                showErrorBorder={phoneNumberError}
                type='text'
                onChange={(val: string) => {
                  setPhoneNumber(val);
                  if (val === "" && !phoneNumberError) setPhoneNumberError(true)
                  if (val !== "" && phoneNumberError) setPhoneNumberError(false)
                }} />
              <TextArea
                placeholder='Message'
                showErrorBorder={messageError}
                onChange={(val: string) => {
                  setMessage(val);
                  if (val === "" && !messageError) setMessageError(true)
                  if (val !== "" && messageError) setMessageError(false)
                }} />
              <Button
                containerStyling={{backgroundColor: Colors.orange}}
                textStyling={{color: Colors.black}}
              value='Send Message' onClick={() => submitForm()} />
            </FormContainer>
            <InfoContainer>
              <Heading>Our information</Heading>
                <Text>Whatsapp</Text>
                <Text style={{marginBottom: 15}}><a href="tel:876-812-3292">876-812-3292</a></Text>
                <Text>Telephone</Text>
                <Text><a href="tel:876-812-3292">876-812-3292</a></Text>
                <Text><a href="tel:876-416-8624">876-416-8624</a></Text>
                <Text><a href="tel:/ 876-810-5367">876-810-5367</a></Text>
                <Text style={{marginBottom: 15}}><a href="tel:876-425-9280"> 876-425-9280</a></Text>
                <Text>Email</Text>
                <Text><a href="mailto:service.baileyautoedits@gmail.com">service.baileyautoedits@gmail.com</a></Text>
            </InfoContainer>
          </PositionContainer>
        </div>
      </ContentContainer>
      <ModalContainer visible={messageModalVisible}>
        <MessageModal
          message={messageModalMessage}
          onClickConfirm={() => setMessageModalVisible(false)}
        />
      </ModalContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PositionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 800px;
  @media only screen and (max-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 350px;
  min-width: 200px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
`;

const InfoContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  /* margin: auto; */
  width: 100%;
  max-width: 350px;
  min-width: 200px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
`;

const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const Heading = styled.h3`
  font-family: Poppins;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
  margin-bottom: 15px;
`;

const Text =styled.span`
  font-size: 16px;
  /* font-weight: 500; */
  color: ${Colors.black};
`;

export default ContactUs;
