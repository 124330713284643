import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import { SCREEN_WIDTH_CONSTANTS } from '../../constants/Devices';
import useWindowDimensions from '../../utils/useWindowDimensions';

interface ListingCardInterface {
    imageUrl?: any;
    productName: string;
    productType: string;
    productCategory: string;
    price: number;
    containerStyling?: CSSProperties;
    onClick?: Function;
    quantity: number;
}

let ListingCard: FC<ListingCardInterface> = props => {

    let screenDimension = useWindowDimensions()

    if (screenDimension.width && screenDimension.width <= 560) return <ListingCardMobile {...props} />;
    return <ListingCardLarge {...props} />
}

/**
 * Tablet and Desktop product listing card
 * @param props 
 * @returns 
 */
let ListingCardLarge: FC<ListingCardInterface> = (props) => {
    return (
        <ListingContainer style={props.containerStyling} onClick={() => props.onClick && props.onClick()}>
            <ImageContainer>
                <ImageComponent src={props.imageUrl ? props.imageUrl : 'images/undraw_polaroid_re_481f.svg'} />
            </ImageContainer>

            <InfoContainer>
                <ProductTitle>{props.productName.substring(0, 40)}</ProductTitle>
                <CategoryText>{props.productCategory}</CategoryText>
            </InfoContainer>

            <CardBottomContainer>
                <PriceText
                    style={{ color: props.quantity > 0 ? Colors.green : Colors.red }}
                >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.price)}</PriceText>
                {
                    props.quantity > 0 ?
                        <StatusAvailable>In Stock</StatusAvailable>
                        : <StatusUnavailable>Out of Stock</StatusUnavailable>
                }
            </CardBottomContainer>
        </ListingContainer>
    )
}

/**
 * Mobile Product listing card
 * @param props 
 * @returns 
 */
let ListingCardMobile: FC<ListingCardInterface> = (props) => {
    return (
        <MobileCardContainer onClick={() => props.onClick && props.onClick()}>
            <MobileImageContainer>
                <ImageComponent src={props.imageUrl ? props.imageUrl : 'images/undraw_polaroid_re_481f.svg'} />
            </MobileImageContainer>
            <MobileInfoContainer>
                <ProductTitle>{props.productName.substring(0, 40)}</ProductTitle>
                <CategoryText>{props.productCategory}</CategoryText>
                <PriceText
                    style={{ color: props.quantity > 0 ? Colors.green : Colors.red }}
                >{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.price)}</PriceText>
            </MobileInfoContainer>
        </MobileCardContainer>
    )
}

//=============================Mobile Styles========================

let MobileCardContainer = styled.div`
    width: 100%;
    height: 134px;
    /* max-height: 140px; */
    border-radius: 8px;
    background-color: ${Colors.white};
    display: flex;
    flex-direction: row;
`;

let MobileImageContainer = styled.span`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

let MobileInfoContainer = styled.div`
    flex: 2;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    padding: 10px;
`;

//=============================Desktop and Tablet Styles========================

let CardBottomContainer = styled.div`
    padding: 15px;
    padding-top: 5px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

let ListingContainer = styled.div`
    display: grid;
    grid-template-rows: 3fr 1fr;
    background-color: #fff;
    min-width:  170px;
    max-width: 220px;
    height: 340px;
    border-radius: 8px;
    @media only screen and (max-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        max-width: 200px;
        /* height: 320px; */
    }
`;

let ImageContainer = styled.div`
    padding-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

let InfoContainer = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    /* width: 100%; */
    overflow-wrap: break-word;
`;

let ImageComponent = styled.img`
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

let ProductTitle = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.black};
    text-align: left;
    text-overflow: ellipsis;
`;

let CategoryText = styled.span`
    font-weight: 300;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: ${Colors.black400};
    text-align: left;
`;

let StatusAvailable = styled.p`
    font-weight: 300;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: ${Colors.green};
    text-align: left;
`;

let StatusUnavailable = styled.p`
    font-weight: 300;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: ${Colors.red};
    text-align: left;
`;

let PriceText = styled.p`
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: #fff;
    text-align: left;
`;

export default ListingCard;