import React, { FC, useState } from 'react';
import styled from "styled-components";

import Button from '../../components/StandardButton'
import Colors from '../../constants/Colors';
import { knutsfordLocations } from '../../constants/knutsford-shipping-locations';
import { zipmailLocations } from '../../constants/zipmail-shipping-locations';
import { CustomerShippingAddressType } from '../../features/customer/types';
import Checkable from '../Form/Checkable';
import DropDownField from '../Form/DropDownField';
import InputField from '../Form/InputField';

interface CustomerAddressInfoModalInterface {
    onClickConfirm: Function;
    onClickCancel: Function;
}

let AddCustomerAddressInfoModal: FC<CustomerAddressInfoModalInterface> = (props) => {

    let [isMailingAddressSelected, setIsMailingAddressSelected] = useState(false)
    let [isZipmailSelected, setIsZipmailSelected] = useState(false)
    let [isKnutsfordAddressSelected, setIsKnutsfordAddressSelected] = useState(false);
    let [addressLine1Error, setAddressLine1Error] = useState(false)
    let [parishError, setParishError] = useState(false)
    let [cityError, setCityError] = useState(false)
    let [zipMailError, setZipmailError] = useState(false)
    let [knutsfordError, setKnutsfordError] = useState(false)

    let [address, setAddress] = useState<CustomerShippingAddressType>({} as CustomerShippingAddressType)

    let submitAddress = () => {

        if(isMailingAddressSelected){
            if(address.addressLine1==""){
                setAddressLine1Error(true)
                return;
            }
            if(address.parishStateProvince==""){
                setParishError(true)
                return;
            }
            if(address.city==""){
                setCityError(true)
                return;
            }

            let tempAddress = {...address,
                isKnutsfordAddress: isKnutsfordAddressSelected,
                isZipmailAddress: isZipmailSelected
            }

            setAddress(tempAddress)
            props.onClickConfirm(address)
            return;
        }

        if(isZipmailSelected){
            if(!address.addressLine1||address.addressLine1==""||address.addressLine1=="-none-"){
                setZipmailError(true)
                return;
            }

            let tempAddress = {...address, 
                parishStateProvince: "N/A",
                city: "N/A",
                addressLine2: "N/A",
                postalCode: "N/A",
                isKnutsfordAddress: isKnutsfordAddressSelected,
                isZipmailAddress: isZipmailSelected
            }

            setAddress(tempAddress)
            props.onClickConfirm(tempAddress)
            return;
        }

        if(isKnutsfordAddressSelected){
            if(!address.addressLine1||address.addressLine1==""||address.addressLine1=="-none-"){
                setKnutsfordError(true)
                return;
            }

            let knutsfordObject = knutsfordLocations.find(val=> val.location == address.addressLine1)

            if(knutsfordObject===undefined){
                setKnutsfordError(true)
                return;
            }

            let tempAddress = {...address, 
                parishStateProvince: knutsfordObject.parish,
                city: knutsfordObject.city,
                addressLine2: knutsfordObject.city,
                postalCode: "N/A",
                isKnutsfordAddress: isKnutsfordAddressSelected,
                isZipmailAddress: isZipmailSelected
            }

            setAddress(tempAddress)
            props.onClickConfirm(tempAddress)
            return;
        }
    }

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{ marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px" }}>Add Address</Text>

                <div style={{
                    width: "100%",
                    borderBottom: `1px solid ${Colors.lightGrey2}`,
                    marginBottom: 20,
                    paddingBottom: 10
                }}>
                    <SectionLabel>Address Type</SectionLabel>
                </div>

                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                }}>
                    <Checkable
                        value={isMailingAddressSelected}
                        onChange={(val: boolean) => {
                            setIsMailingAddressSelected(val)
                            //
                            setIsKnutsfordAddressSelected(false)
                            setIsZipmailSelected(false)
                        }}
                        labelText={'Mailing Address'} />
                    <Checkable
                        value={isZipmailSelected}
                        onChange={(val: boolean) => {
                            setIsZipmailSelected(val)
                            //
                            setIsMailingAddressSelected(false)
                            setIsKnutsfordAddressSelected(false)
                        }}
                        labelText={'Zip Mail Address'} />
                    <Checkable
                        value={isKnutsfordAddressSelected}
                        onChange={(val: boolean) => {
                            setIsKnutsfordAddressSelected(val)
                            //
                            setIsZipmailSelected(false)
                            setIsMailingAddressSelected(false)
                        }}
                        labelText={'KnutsFord Express Address'} />
                </div>

                {(isMailingAddressSelected||isZipmailSelected||isKnutsfordAddressSelected)&&<div style={{
                    width: "100%",
                    borderBottom: `1px solid ${Colors.lightGrey2}`,
                    marginBottom: 20,
                    paddingBottom: 10
                }}>
                    <SectionLabel>Address Info</SectionLabel>
                </div>}

                {/* Mailing Address */}
                {isMailingAddressSelected && <div style={{
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: 15
                    }}>
                        <Label>Address Line 1</Label>
                        <InputField
                            placeholder={'Address line 1'}
                            error={addressLine1Error}
                            value={address.addressLine1}
                            onChange={(val)=>setAddress({...address, addressLine1: val})}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: 15
                    }}>
                        <Label>Address Line 2</Label>
                        <InputField
                            placeholder={'Address Line 2'}
                            value={address.addressLine2}
                            onChange={(val)=>setAddress({...address, addressLine2: val})}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: 15
                    }}>
                        <Label>City</Label>
                        <InputField
                            placeholder={'City'}
                            value={address.city}
                            error={cityError}
                            onChange={(val)=>setAddress({...address, city: val})}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: 15
                    }}>
                        <Label>Parish</Label>
                        <DropDownField
                            dropdownOptions={["Kingston", "St. Andrew", "St. Catherine", "Clarendon", "Manchester", "St. Elizabeth", "Westmoreland", "Hanover", "St. James", "Trelawny", "St. Ann", "St. Mary", "Portland", "St. Thomas"]}
                            containerStyling={{ width: "100%" }}
                            boxStyling={{ border: `1px solid ${Colors.lightGrey2}`, borderRadius: 8 }}
                            error={parishError}
                            value={address.addressLine1}
                            onSelect={(val)=>{setAddress({...address, parishStateProvince: val})}}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: 15
                    }}>
                        <Label>Postal Code</Label>
                        <InputField
                            placeholder={'postal code'}
                            value={address.postalCode}
                            onChange={(val)=>setAddress({...address, postalCode: val})}
                        />
                    </div>
                </div>}

               {isZipmailSelected && <DropDownField
                    dropdownOptions={zipmailLocations.map((value)=>value.location)}
                    containerStyling={{width: "100%", marginBottom: 20}}
                    value={address.addressLine1}
                    error={zipMailError}
                    onSelect={(val)=>setAddress({...address, addressLine1: val})}
                />}

                {isKnutsfordAddressSelected && <DropDownField
                    dropdownOptions={knutsfordLocations.map((value)=>value.location)}
                    containerStyling={{width: "100%", marginBottom: 20}}
                    error={knutsfordError}
                    value={address.addressLine1}
                    onSelect={(val)=>setAddress({...address, addressLine1: val})}
                />}

                <div style={{
                    display: 'grid',
                    gridColumnGap: 15,
                    gridTemplateColumns: '1fr 1fr',
                    width: "100%",
                }}>
                    <Button
                        value="Cancel"
                        // containerStyles={{ marginLeft: 10 }}
                        containerStyling={{ width: '100%', backgroundColor: Colors.red }}
                        onClick={props.onClickCancel}
                    />
                    <Button
                        value="Done"
                        // containerStyles={{ marginLeft: 10 }}
                        containerStyling={{ width: '100%' }}
                        onClick={submitAddress}
                    />
                </div>
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.darkGrey};
`;

let Label = styled.span`

`

let SectionLabel = styled.span`
    text-align: left;
    font-size: 18px;
`

export default AddCustomerAddressInfoModal;
