export const knutsfordLocations = [
    {
     "location": "Knutsford Express - Kingston",
     "city": "Kingston",
     "parish": "Kingston"
    },
    {
     "location": "Knutsford Express - Mandeville",
     "city": "Mandeville",
     "parish": "Mandeville"
    },
    {
     "location": "Knutsford Express - Gutters",
     "city": "Gutters",
     "parish": "St. Elizabeth"
    },
    {
     "location": "Knutsford Express - Luana",
     "city": "Luana",
     "parish": "St. Elizabeth"
    },
    {
     "location": "Knutsford Express - Savanna-La-Mar",
     "city": "Savanna-La-Mar",
     "parish": "Westmoreland"
    },
    {
     "location": "Knutsford Express - Negril",
     "city": "Negril",
     "parish": "Westmoreland"
    },
    {
     "location": "Knutsford Express - Montego Bay",
     "city": "Montego Bay",
     "parish": "St. James"
    },
    {
     "location": "Knutsford Express - Montego Bay Airport",
     "city": "Montego Bay",
     "parish": "St. James"
    },
    {
     "location": "Knutsford Express - Falmouth",
     "city": "Falmouth",
     "parish": "Trelawny"
    },
    {
     "location": "Knutsford Express - Ocho Rios",
     "city": "Ocho Rios",
     "parish": "St. Ann"
    },
    {
     "location": "Knutsford Express - Port Maria",
     "city": "Port Maria",
     "parish": "St. Mary"
    },
    {
     "location": "Knutsford Express - Annotto Bay",
     "city": "Annotto Bay",
     "parish": "St. Mary"
    },
    {
     "location": "Knutsford Express - Port Antonio",
     "city": "Port Antonio",
     "parish": "Portland"
    }
   ]