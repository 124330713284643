import React, {CSSProperties, FC} from 'react';
import styled from "styled-components";
import Colors from '../constants/Colors';
import {FiTrash2} from "react-icons/fi";
import {SCREEN_WIDTH_CONSTANTS} from '../constants/Devices';

interface WishlistCardInterface {
    imageUrl: string;
    productName: string;
    itemStatus: string;
    itemPrice: number;
    productId: number;
    containerStyling?: CSSProperties;
    onDelete: Function;
}

let WishlistCard: FC<WishlistCardInterface> = (props) => {
    return (
        <Container style={{...props.containerStyling}}>
            <ImageContainer>
                <Image src={props.imageUrl} />
            </ImageContainer>
            <InfoContainer>
                <Text style={{ fontWeight: '500' }}>{props.productName}</Text>
                <Text
                    style={{
                        color: props.itemStatus === "Available" ? Colors.green : Colors.red,
                        fontSize: "16px"
                    }}
                >
                    {props.itemStatus}
                </Text>
                <OptionsDateContainer>
                    <Text
                        style={{
                            color: Colors.black600,
                            fontSize: "16px"
                        }}
                    >
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.itemPrice)} JMD
                    </Text>

                    <OptionsContainer>
                        <FiTrash2
                            color={Colors.red}
                            style={{cursor: 'pointer'}}
                            size={24}
                            onClick={()=> props.onDelete({productId: props.productId})}
                        />
                    </OptionsContainer>
                </OptionsDateContainer>
            </InfoContainer>
        </Container>
    )
}

const Container = styled.div`
    padding: 15px;
    /* height: 125px; */
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        min-width: 300px;
        
    }
`;

const ImageContainer = styled.div`
    width: 150px;
    height: 100%;
    max-height: 150px;
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
    width: 100%;
`;

const InfoContainer = styled.div`
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Text = styled.p`
    font-weight: 400;
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: ${Colors.carbonBlack};
    text-align: left;
`;

const OptionsDateContainer = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export default WishlistCard;