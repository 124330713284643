import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface NotificationStateType {
    message: string,
    show: boolean,
    action?: Function
}

const initialState: NotificationStateType = {
    message: "",
    show: false
}

const notificationSlice = createSlice({
    name: "NotificationSlice",
    initialState,
    reducers:{
        setNotification(state, action: PayloadAction<NotificationStateType>){
            console.log("NotificationSlice", action.payload);
            const {message, show} = action.payload
            state.message = message;
            state.show = show;
        }
    }
})

export const {setNotification} = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer;