import React, { useState } from 'react';
import styled from 'styled-components';
import { FiStar } from "react-icons/fi";
import { skipToken } from '@reduxjs/toolkit/query/react'
import Lottie from 'react-lottie';
import Carousel from 'react-material-ui-carousel'
import { Divider } from '@mui/material';
import { useParams } from "react-router-dom";

import NavBar from '../components/NavBar';
import TextTrail from '../components/Universal/TextTrail';
import Colors from '../constants/Colors';
import StandardButton from '../components/StandardButton';
import { SCREEN_WIDTH_CONSTANTS } from '../constants/Devices';
import ModalContainer from '../components/Modal/ModalContainer';
import SuccessTransactionModal from '../components/Modal/SuccessTransactionModal';
import { useGetProductQuery } from "../features/product/productApiSlice";
import spinnerLottie from "../assets/lottie/loading-orange.json"
import noDataLottie from "../assets/lottie/no-data.json";
import errorLottie from "../assets/lottie/questioning.json";
import { useLazyAddToWishListQuery } from "../features/wishlist/wishlistApiSlice";
import { CartItemRequestModel } from '../features/cart/types';
import { useAddItemToCartMutation, useLazyGetCartItemCountQuery } from '../features/cart/cartApiSlice'
import { useAppDispatch } from '../store';
import { setNotification } from "../features/notification/notificationSlice";
import { updateCartCount } from '../features/cart/cartSlices';
import { lottieOptions } from "../utils/lottieOptions";
import Footer from '../components/Universal/Footer';
import useWindowDimensions from '../utils/useWindowDimensions';
import Incrementer from '../components/Form/Incrementer';

let ProductInfoPage = () => {
	const { productId } = useParams();
	const {
		data: productData,
		error: productError,
		isLoading,
		isSuccess,
		isError
	} = useGetProductQuery(productId ?? skipToken);
	const selectedProduct = productData?.response;

	const dispatch = useAppDispatch();
	const dimensions = useWindowDimensions();

	const [triggerAddWishlistItem] = useLazyAddToWishListQuery();
	const [triggerAddItemToCart] = useAddItemToCartMutation();
	let [triggerItemCount] = useLazyGetCartItemCountQuery();

	let [successModalOpen, setSuccessModalOpen] = useState(false);
	let [successModalMessage, setSuccessModalMessage] = useState("");
	const [quantity, setQuantity] = useState(1);

	let setCartItemCount = () => {
		triggerItemCount()
			.unwrap()
			.then(response => {
				if (response.response) dispatch(updateCartCount(response.response?.count))
			})
	}


	let addItemToCart = () => {
		let request: CartItemRequestModel = {
			productId: productId ? parseInt(productId) : 0,
			quantity: quantity,
			details: null
		}

		if(quantity<=0){
			dispatch(setNotification({ message: "Quantity must be at least 1", show: true }))
			return;
		}

		if (selectedProduct?.quantity && quantity > selectedProduct?.quantity) {
			dispatch(setNotification({ message: "Asking amount exceeds available amount", show: true }))
			return;
		}

		if (selectedProduct?.status === 'Unavailable') {
			dispatch(setNotification({ message: "This item is unavailable", show: true }))
			return;
		}

		triggerAddItemToCart(request)
			.unwrap()
			.then(response => {
				// dispatch(addCartItem(response.response as any))
				console.log("<--- LOG --->", response)
				setSuccessModalMessage("Item added to cart!");
				setSuccessModalOpen(true)
				setCartItemCount()
			})
			.catch(error => {
				dispatch(setNotification({ message: error.data.message, show: true }))
			})
	}

	/**
	 * @todo: Maybe could be extracted to a reusable component
	 * @description: Helper for when no data is available to show
	 * */
	const FallbackRender = () => {
		/**
		 * @description: A general function that will return a lottie option object base on parameter
		 * @todo: Could maybe be extracted to a util file for re-usability
		 * */


		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

				{/**
								 @description: Render when data is loading
								 */}
				{isLoading && <Lottie options={lottieOptions(true, true, spinnerLottie)}
					height={450}
					width={450}
					isStopped={false}
					isPaused={false}
				/>}

				{/**
								 @description: Render when no data was returned the server
								 */}
				{(isSuccess && selectedProduct === null) && <Lottie options={lottieOptions(true, true, noDataLottie)}
					height={350}
					width={250}
					isStopped={false}
					isPaused={false}
				/>}

				{/**
								 @description: Render when an error took place
								 */}
				{(isError && productError) && <div>
					<h4 style={{ textAlign: 'center' }}>
						{productError['data']['message']}
					</h4>
					<Lottie options={lottieOptions(false, true, errorLottie)}
						isStopped={false}
						isPaused={false}
					/>

				</div>}

			</div>
		)

	}

	/**
	 * @description: This is the handler for save to wishlist functionality
	 * */
	const wishlistClickHandler = () => {
		if(selectedProduct&&selectedProduct?.quantity<=0){
			dispatch(setNotification({ message: "Item is out of stock", show: true }))
			return;
		}
		triggerAddWishlistItem({ productId: productId! })
			.unwrap()
			.then(data => {
				if (data) {
					setSuccessModalMessage("Item added to wishlist!");
					setSuccessModalOpen(true);
				}

			})
			.catch(error => {
				dispatch(setNotification({ message: error.data.message, show: true }))
			})
	}

	let BuyAddToCartSection = () => {
		return (
			<BuyAddToCartSectionContainer>
				<div>
					<ItemPriceText
						style={{ fontWeight: 'bold' }}>
						{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedProduct ? selectedProduct.productPrice : 0)} JMD
					</ItemPriceText>
					<ItemCountText>
						{selectedProduct && selectedProduct.quantity > 0 ? `${selectedProduct?.quantity} Available in stock` : "Out of Stock"}
					</ItemCountText>

				</div>

				<Incrementer
					availableQuantity={selectedProduct && selectedProduct.quantity ? selectedProduct.quantity : 0}
					updateShoppingQuantity={setQuantity}
					value={selectedProduct && selectedProduct.quantity > 0 ? quantity : 0}
				/>

				<StandardButton
					value="Add to cart"
					onClick={() => addItemToCart()}
				/>
				<StandardButton
					leftIcon={<FiStar size={20} style={{ stroke: Colors.carbonBlack }} />}
					containerStyling={{ backgroundColor: Colors.white, border: 'solid', borderColor: Colors.borderPrimary, borderWidth: 0.5 }}
					textStyling={{ color: Colors.carbonBlack }}
					value="Save to Wishlist"
					onClick={wishlistClickHandler}
				/>

			</BuyAddToCartSectionContainer>
		)
	}

	return (
		<Container>
			<NavBar />
			{selectedProduct ? <ContentContainer>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: 20, width: '100%', maxWidth: 1500 }}>
					<TrailContainer style={{ maxWidth: 770 }}>
						<TextTrail
							textArray={["Accessories", selectedProduct.productName.length > 50 ? selectedProduct.productName.substring(0, 45) + "..." : selectedProduct.productName]}
						/>
					</TrailContainer>
				</div>

				<ContentPositioningContainer>
					<ProductInfoContentContainer style={{ maxWidth: 770, width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>

						<GeneralContainer style={{ width: '100%', minWidth: 350, maxWidth: 450, }}>
							{selectedProduct.imageUrls.length > 0 ? <Carousel
								navButtonsAlwaysVisible={true}
								animation={'slide'}
								height={420}
							>

								{selectedProduct.imageUrls.map(url =>
									<div style={{
										height: 400,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
										<img
											src={url}
											style={{
												height: '100%'
											}}
											alt="product-image"
										/>
									</div>
								)}
							</Carousel> : null
							}
						</GeneralContainer>

						{dimensions.width && dimensions.width < 1024 && <div>
							<BuyAddToCartSection />
						</div>}

					</ProductInfoContentContainer>

					<ProductInformationContainer>
						<GeneralContainer>
							<Text
								style={{ fontWeight: "500", color: Colors.black }}
							>
								{selectedProduct.productName}
							</Text>

							<GeneralContainer
								style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '15px' }}>
								<Text style={{  color: Colors.black400, fontSize: 16, fontWeight: 500 }}>Category:</Text>
								<Text
									style={{ color: Colors.black400, marginLeft: 20, fontSize: 16 }}>{selectedProduct.category}</Text>
							</GeneralContainer>

							<GeneralContainer
								style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '15px' }}>
								<Text style={{  color: Colors.black400, fontSize: 16, fontWeight: 500 }}>Sub-category:</Text>
								<Text
									style={{ color: Colors.black400, marginLeft: 20, fontSize: 16 }}>{selectedProduct.subCategory}</Text>
							</GeneralContainer>

							<GeneralContainer
								style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '15px' }}>
								<Text style={{  color: Colors.black400, fontSize: 16, fontWeight: 500 }}>Weight:</Text>
								<Text
									style={{ color: Colors.black400, marginLeft: 20, fontSize: 16 }}>{selectedProduct.weightLb}lb</Text>
							</GeneralContainer>
						</GeneralContainer>

						<Divider />

						<GeneralContainer style={{maxWidth: 770, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}> {/* Product Description Container */}
							<Text style={{ fontWeight: 500}}>Description</Text>
							<Text style={{  color: Colors.black400, marginTop: 15, fontSize: 16 }}>
								{selectedProduct.productDescription}
							</Text>
						</GeneralContainer>

						<Divider />

						<GeneralContainer>
							<Text style={{ fontWeight: 500}}>Details</Text>
							<List>
								{selectedProduct?.details?.descriptionPoints?.map((item, index) => (
									<ListItem key={`description-item-${index}`}>{item}</ListItem>
								))}
							</List>
						</GeneralContainer>

						<Divider />

						<GeneralContainer>
							<Text style={{ fontWeight: 600 }}>Package Content</Text>
							<List>
								{selectedProduct.details?.packageContent?.map((item, index) => (
									<ListItem key={`package-item-${selectedProduct.productId}`}>{item}</ListItem>
								))}
							</List>
						</GeneralContainer>
					</ProductInformationContainer>

					{dimensions.width && dimensions.width >= 1024 && <div>
						<BuyAddToCartSection />
					</div>}
				</ContentPositioningContainer>

			</ContentContainer>
				:
				<FallbackRender />
			}

			{/* Modals */}
			{/*Handle both cart and wishlist modal for success scenario*/}
			<ModalContainer visible={successModalOpen}>
				<SuccessTransactionModal
					closeModal={() => setSuccessModalOpen(!successModalOpen)}
					text={successModalMessage}
				/>
			</ModalContainer>
			<Footer />
		</Container>
	)
}

const Container = styled.div`
	//width: 100vw;
	min-height: 100vh;
	max-height: 100%;
	background-color: ${Colors.background};
	position: relative;
`;

const ContentContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

const ContentPositioningContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 1500px;
	@media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}){
		flex-direction: row;
	}
`;

const BuyAddToCartSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	border-radius: 8px;
	background-color: ${Colors.white};
	padding: 15px;
	min-width: 200px;
`;

const ProductInformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	border-radius: 8px;
	background-color: ${Colors.white};
	padding: 15px;
	max-width: 770px;
	@media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
		min-width: 630px;
	}
	
`;

const List = styled.ul``;

const ListItem = styled.li`
	word-wrap: break-word;
	color: ${Colors.black400};
`;

const TrailContainer = styled.div`
	width: 100%;
`;

const GeneralContainer = styled.div`
	width: 100%;
`;

const ProductInfoContentContainer = styled.div`
	@media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 20px;
	}
`;

let Text = styled.p`
	font-weight: 400;
	font-size: 18px;
	padding: 0;
	margin: 0;
	color: ${Colors.carbonBlack};
`;



//===========================Text Components=========================

let ItemPriceText = styled.p`
	font-weight: 400;
	font-size: 18px;
	padding: 0;
	margin: 0;
	color: ${Colors.carbonBlack};
	text-align: center;
	@media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
		text-align: left;
	}
`;

let ItemCountText = styled.p`
	font-weight: 400;
	font-size: 16px;
	padding: 0;
	margin: 0;
	color: ${Colors.black400};
	text-align: center;
	@media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
		text-align: left;
	}
`;




export default ProductInfoPage;