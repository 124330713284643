import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';

interface DropDownFieldInterface {
    dropdownOptions: Array<string>;
    boxStyling?: CSSProperties;
    containerStyling?: CSSProperties;
    onSelect?: Function;
    value?: string;
    error?: boolean;
}

let DropDownField: FC<DropDownFieldInterface> = (props) => {
    return (
        <div style={props.boxStyling}>
            <InputField
                style={{ ...props.containerStyling, border: props.error ? `2px solid ${Colors.red} ` : `2px solid ${Colors.borderPrimary}` }}
                value={props.value}
                onChange={(event) => { if (props.onSelect) props.onSelect(event.target.value) }}
            >
                <option selected value=""> -- Select an Option -- </option>
                {
                    props.dropdownOptions.map((data, index) =>
                        <Options key={index} value={data} >{data}</Options>
                    )
                }
            </InputField>
        </div>
    )
}

let InputField = styled.select`
    width: 340px;
    border-radius: 6px;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
`;

let Options = styled.option`
    background: #fff;
    width: 100%;
`;

export default DropDownField;