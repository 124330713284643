import { ProductType } from "../product/types";

export interface OrderAddress{
	address_line_1: string; 
	address_line_2: string; 
	city: string; 
	parish_state_province: string; 
	postal_code: string; 
	is_primary: boolean; 
	is_zipmail_address: boolean; 
	is_knutsford_address: boolean; 
}

export interface OrderRequestModel{
	orderMethod: string; 
	orderAddress: OrderAddress | null; 
	deliveryRate: number;
	paymentGateway: any;
	paymentInfo?: any;
}

export enum PaymentGateway{
	AMBER = "AMBER",
	CASH = "CASH"
}

export interface OrderModel{
	id: number; 
	customerId: number; 
	status: string; 
	orderDate: string; 
	orderTotal: number; 
	deliveryRate: number; 
	fulfilledBy: number; 
	paymentId: number; 
	orderMethod: string; 
	trackingNumber: string; 
	address: OrderAddress; 
	orderDetails?: Array<OrderDetailModel>
}

export interface ExpandedOrderModel{
	id: number; 
	customerId: number; 
	status: string; 
	orderDate: string; 
	orderTotal: number; 
	deliveryRate: number; 
	fulfilledBy: number; 
	payment: Payment; 
	orderMethod: string; 
	trackingNumber: string; 
	address: OrderAddress; 
	orderDetails?: Array<OrderDetailModel>
}

export interface Payment {
	id: number
	paymentType: string
	paymentAmount: number
	paymentStatus: string
	paymentDate: string
	otherDetails: any
	transactionId: string
	signature: any
	updatedAt: string
  }

export interface OrderDetailModel{
	orderDetailId: number; 
	unitPrice: number; 
	quantity: number; 
	total: number; 
	product: ProductType; 
	details: any; 
	orderInitiationId: number; 
}

export interface LascoPaySignatureResponseModel{
	ClientId: string;
	TransactionId: string;
	CurrencyCode: string;
	Amount: number;
	ReturnToMerchant: string;
	AutoRedirect: string;
	CustomerInvoice: string;
	CardTokenize: string;
	Signature: string
}

