import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL;

export const contactUsApiSlice = createApi({
    reducerPath: "ContactUsApi",
    baseQuery: fetchBaseQuery({baseUrl: `${CUSTOMER_API_BASE_URL}/contact`}),
    endpoints: (builder) => ({
        submitContactUsForm: builder.query<any, {customerName: string, customerEmail: string, customerPhoneNumber: string, customerMessage: string}>({
            query:(contactUs)=>({
                url: "/form",
                method: "POST",
                body: contactUs
            }),
        }),
    })
})

export const {useLazySubmitContactUsFormQuery} = contactUsApiSlice;