import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components'
import Colors from '../../constants/Colors';

interface InputFieldInterface {
    placeholder: string;
    type?: string;
    min?: number;
    max?: number;
    containerStyling?: CSSProperties;
    value?: any;
    disabled?: boolean;
    onChange?: Function;
    pattern?: string
    error?: boolean;
    showErrorBorder?: boolean;
}

let InputField: FC<InputFieldInterface> = (props) => {
    return (
        <Container
            style={{
                borderColor: props.showErrorBorder ? Colors.red : !props.disabled ? Colors.lightGrey2 : 'none',
                borderWidth: props.showErrorBorder||!props.disabled ? 1 : 0,
                borderStyle: props.showErrorBorder||!props.disabled ? 'solid' : 'none',
                ...props.containerStyling
            }}
        >
            <Input
                placeholder={props.placeholder}
                type={props.type ? props.type : 'text'}
                min={props.min}
                max={props.max}
                pattern={props.pattern}
                value={props.value}
                disabled={props.disabled}
                // style={{ ...props.containerStyling, border: props.error ? `2px solid ${Colors.red}` : 'none' }}
                onChange={(event) => { if (props.onChange) props.onChange(event.target.value) }}
            />

        </Container>

    )
}

const Container = styled.div`
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  & > .input-icon {
    color: #9e9e9e;
  }
`;

let Input = styled.input`
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    border: none;
    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: ${Colors.lightGrey1};
    }
`;

export default InputField;