import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../../store";
import {GeneralResponseType} from "../types";
import {CartItemCountResponse, CartItemModel, CartItemRequestModel, CartItemsResponse} from "./types";
import {refreshTokenUtil} from "../../utils/tokenUtil";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const cartApiSlice = createApi({
    reducerPath: 'CartApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${CUSTOMER_API_BASE_URL}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.accessToken
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['Cart'],

    endpoints: (builder) => ({
        addItemToCart: builder.mutation<GeneralResponseType<Array<CartItemModel>>, CartItemRequestModel>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let cartItemResponse = await fetchWithBQ({
                    url: "/cart",
                    method: "POST",
                    body: arg,
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    }
                });

                return cartItemResponse as any;
            },
            invalidatesTags: ['Cart'],
        }),

        updateCartItem: builder.mutation<GeneralResponseType<Array<CartItemModel>>, { cartItemRequest: CartItemRequestModel, cartItemId: string }>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let cartItemResponse = await fetchWithBQ({
                    url: `/cart/${arg.cartItemId}/item`,
                    method: "PUT",
                    body: arg.cartItemRequest,
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    }
                });

                return cartItemResponse as any;
            },
            invalidatesTags: ['Cart'],
        }),

        getCartItems: builder.query<GeneralResponseType<CartItemsResponse>, void>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let cartItemResponse = await fetchWithBQ({
                    url: "/cart",
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                });

                return cartItemResponse as any;
            },
            providesTags: ['Cart'],
            // providesTags: (result) =>
            //     result?.response && result?.response?.cartItems
            //         ? [...result?.response?.cartItems.map(item => ({type: "Cart" as const, id: item.cartItemId})), 'Cart']
            //         : ['Cart'],
            keepUnusedDataFor: 60 //Seconds to keep cache data
        }),

        getCartItemCount: builder.query<GeneralResponseType<CartItemCountResponse>, void>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let cartItemCountResponse = await fetchWithBQ({
                    url: "/cart/count",
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                });

                return cartItemCountResponse as any;
            },
            providesTags: ['Cart'],
        }),

        deleteCartItem: builder.mutation<GeneralResponseType<{ deletedItem: number, cartId: number }>, { cartId: number }>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let cartItemResponse = await fetchWithBQ({
                    url: `/cart/${arg.cartId}/item`,
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                });

                return cartItemResponse as any;
            },
            invalidatesTags: ['Cart'],
        })


    })
})

export const {useAddItemToCartMutation, useUpdateCartItemMutation, useGetCartItemsQuery, useDeleteCartItemMutation, useLazyGetCartItemCountQuery} = cartApiSlice