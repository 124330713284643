import React, { } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import { Divider } from '@mui/material';

//Local Imports
import Colors from '../../constants/Colors';
import Device, { SCREEN_WIDTH_CONSTANTS } from "../../constants/Devices";

let Footer = () => {
    let navigate = useNavigate();
    return (
        <Container>
            <LogoContainer>
                <Logo onClick={() => navigate("/")} >
                    <img src={"/images/logo-grey.svg"} alt="Company Logo" style={{ width: 32, height: 32 }} />
                </Logo>
                <CompanyName onClick={() => navigate("/")}>Bailey Auto Edits</CompanyName>
            </LogoContainer>

            <SocialMediaLinkWrapper>
                <LinkContainerMain>
                    <LinkSetContainer>
                        <LinkText onClick={() => navigate("/contact-us")}>Contact Us</LinkText>
                        <LinkText onClick={() => navigate("/FAQs")}>FAQs</LinkText>
                    </LinkSetContainer>
                    <LinkSetContainer>
                        <LinkText onClick={() => navigate("/terms-and-conditions")}>Terms and Agreements</LinkText>
                        <LinkText onClick={() => navigate("/privacy-policy")}>Privacy Policy</LinkText>
                    </LinkSetContainer>
                </LinkContainerMain>

                <SocialMediaContainer>
                    <div>
                        <a href="https://www.facebook.com/profile.php?id=100087947774182">
                            <FiFacebook size={24} style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }} />
                        </a>
                        <a href="https://www.instagram.com/baileyautoedits/">
                            <FiInstagram size={24} style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }} />
                        </a>
                        <a href="https://twitter.com/BaileyAutoEdits">
                            <FiTwitter size={24} style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }} />
                        </a>
                    </div>
                </SocialMediaContainer>
            </SocialMediaLinkWrapper>

            <Divider />

            <CopyrightContainer>
                <Text>Copyright &copy; 2023 Bailey’s Auto Edits. All rights reserved.</Text>
                <Text>Developed by <a href="https://reprojec.com/">Reprojec</a> Consultancy</Text>
                <Text>Jamaica</Text>
            </CopyrightContainer>

        </Container>
    )
}

let Container = styled.footer`
    background-color: ${Colors.background};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 0.5px solid ${Colors.lightGrey2}
`;

let LogoContainer = styled.span`
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.mobileL}) {
        justify-content: center;
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        justify-content: flex-start;
    }
`;

let LinkContainerMain = styled.div`
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.mobileL}) {
        justify-content: center;
    }
`;

let LinkSetContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

let SocialMediaContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

let SocialMediaLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        flex-direction: row;
        align-items: end;
        justify-content: space-between
    }
`;

let CopyrightContainer = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.mobileL}) {
        text-align: center;
    }
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

let Text = styled.p`
    padding: 0;
    margin: 0;
`;


const Logo = styled.div`
  width: max-content;
  height: max-content;
  margin-right: 15px;
  &:hover {cursor: pointer}
  & > img {
    height: 24px;
    margin-right: 5px;
  }

  @media ${Device.tablet} {
    margin-right: 10px;
  }
`;

const CompanyName = styled.a`
  font-size: 24px;
  font-weight: 400;
  color: ${Colors.black400};
  margin-right: 15px;
  &:hover {cursor: pointer}
`;

const LinkText = styled.a`
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    color: ${Colors.black400};
    &:hover {
        cursor: pointer;
        text-decoration: underline
    }
`;

// let Footer = () => {
//     let navigate = useNavigate();
//     return (
//         <Container>
//             <RowContainer>
//                 <Logo onClick={() => navigate("/")} >
//                     <img src={"/images/logo.svg"} alt="Company Logo" style={{ width: 35, height: 35 }} />
//                 </Logo>
//                 <CompanyName onClick={() => navigate("/")}>Bailey Auto Edits</CompanyName>
//             </RowContainer>

//             <RowContainer style={{ marginTop: 15 }}>
//                 <LinkContainer>
//                     <LinkText onClick={()=> navigate("/contact-us")}>Contact Us</LinkText>
//                     <LinkText onClick={()=> navigate("/FAQs")}>FAQs</LinkText>
//                     <LinkText onClick={()=> navigate("/terms-and-conditions")}>Terms and Agreements</LinkText>
//                     <LinkText onClick={()=> navigate("/privacy-policy")}>Privacy Policy</LinkText>
//                 </LinkContainer>
//             </RowContainer>

//             <RowContainer style={{ marginTop: 15 }}>
//                 <div>
//                     <a href="https://www.facebook.com/profile.php?id=100087947774182">
//                         <FiFacebook size={24} style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }} />
//                     </a>
//                     <a href="https://www.instagram.com/baileyautoedits/">
//                         <FiInstagram size={24} style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }} />
//                     </a>
//                     <a href="https://twitter.com/BaileyAutoEdits">
//                         <FiTwitter size={24} style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }} />
//                     </a>
//                 </div>
//             </RowContainer>
//         </Container>
//     )
// }

// let Container = styled.div`
//     background-color: ${Colors.black};
//     padding: 15px;
// `;

// let RowContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// `;

// let LinkContainer = styled.div`
//     /* display: flex;
//     flex-direction: column;
//     align-items: center; */
//     @media only screen and (max-width: ${SCREEN_WIDTH_CONSTANTS.mobileL}) {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//     }
// `;

// const Logo = styled.div`
//   width: max-content;
//   height: max-content;
//   margin-right: 15px;
//   &:hover {cursor: pointer}
//   & > img {
//     height: 24px;
//     margin-right: 5px;
//   }

//   @media ${Device.tablet} {
//     margin-right: 10px;
//   }
// `;

// const CompanyName = styled.a`
//   font-size: 18px;
//   font-weight: 500;
//   color: ${Colors.white};
//   margin-right: 15px;
//   &:hover {cursor: pointer}
// `;

// const LinkText = styled.a`
//     font-size: 16px;
//     font-weight: 300;
//     margin-left: 10px;
//     margin-right: 10px;
//     color: ${Colors.white};
//     &:hover {
//         cursor: pointer;
//         text-decoration: underline
//     }
// `;

export default Footer;
