import {decodeToken} from "react-jwt";
import {GeneralResponseType} from "../features/types";
import {setAuthUser, setGuestUser} from "../features/auth/authSlice";

const daysToSeconds = (days: number): number => {
    //           hour  min  sec
    return days * 24 * 60 * 60;
}

const shouldRefreshToken = (accessToken: string) => {
    const decodedToken: { exp: number } | null = decodeToken(accessToken);

    // console.log("decodedToken", decodedToken)
    // console.log("decodedToken?.exp", decodedToken?.exp)
    if (decodedToken?.exp) {
        // console.log("decodedToken?.exp", decodedToken?.exp)
        // console.log("Date.now()", Date.now() / 1000)
        // console.log("MilliSecondsTilTokenExpire", decodedToken?.exp - Date.now() / 1000)
        let secondsTilTokenExpire = decodedToken?.exp - (Date.now() / 1000);
        return secondsTilTokenExpire < daysToSeconds(1)
    }

    return false;
}

const isUserRegistered = (accessToken: string) => {
    const decodedToken: { aud: string } | null = decodeToken(accessToken);
    // console.log(decodedToken?.aud);
    return decodedToken?.aud === "B.A.E_Authenticated_user"
}

export const refreshTokenUtil = async (fetchWithBQ: Function, dispatch: Function, getState: Function) => {
    // console.log("<--- INSIDE UseTokenUtil --->");

    const {auth} = getState() as any;
    // console.log("<--- shouldRefreshToken --->", shouldRefreshToken(auth.accessToken));
    let accessToken = auth.accessToken;
    if (shouldRefreshToken(accessToken)) {
        let refreshTokenRes = await fetchWithBQ({
            url: `/auth/token-refresh?token=${accessToken}`,
            method: "GET",
        });

        if (refreshTokenRes.error) return {refreshTokenRes, accessToken}

        accessToken = (refreshTokenRes.data as GeneralResponseType<{ access_token: string }>).response?.access_token;
        if (isUserRegistered(accessToken)) {
            dispatch(setAuthUser(accessToken));
        } else {
            dispatch(setGuestUser(accessToken));
        }

        return {refreshTokenRes: null, accessToken}
    }

}

