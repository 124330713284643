import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { FiUser, FiShoppingCart, FiStar, FiBox, FiLogOut } from "react-icons/fi";

// COMPONENT IMPORTS
import NavBar from "../components/NavBar";
import AccountCard from "../components/AccountCard";
import Colors from "../constants/Colors";
import { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";
import AccountUserCard from "../components/AccountUserCard";
import { useGetCustomerInfoQuery } from '../features/customer/customerApiSlice';
import { setCustomerInfo } from '../features/customer/customerSlice';
import { RootState } from '../store'
import Footer from "../components/Universal/Footer";

const AccountPage = (props) => {

  const stateCustomerInfo = useSelector((state: RootState) => state.customer)
  const dispatch = useDispatch();
  let { data, isSuccess } = useGetCustomerInfoQuery();

  useEffect(() => {
    if (isSuccess && data?.response) {
      dispatch(setCustomerInfo(data?.response))
    }
  }, [data, isSuccess])

  return (
    <Container>
      <div>
        <NavBar />
        <ContentContainer>
          <PageContentContainer>
            <AccountHeadingContainer>
              <AccountHeading>Your Account</AccountHeading>
            </AccountHeadingContainer>
            <AccountContentContainer>

              <AccountContentContainerLeft>
                <AccountUserCard
                  userName={stateCustomerInfo?.customer ? stateCustomerInfo.customer?.firstName + " " + stateCustomerInfo.customer?.lastName : "User Name"} />
              </AccountContentContainerLeft>

              <AccountContainerRight>
                <AccountCard
                  title={"Order History"}
                  content={"View all your orders to date!"}
                  leftIcon={<FiBox size={32} style={{ cursor: 'pointer' }} />}
                />
                <AccountCard
                  title={"Wishlist Items"}
                  content={"View Items you've wishlisted!"}
                  leftIcon={<FiStar size={32} style={{ cursor: 'pointer' }} />}
                />
                <AccountCard
                  title={"Account Information"}
                  content={"View your account details!"}
                  leftIcon={<FiUser size={32} style={{ cursor: 'pointer' }} />}
                />
                <AccountCard
                  title={"Your Cart"}
                  content={"View your cart!"}
                  leftIcon={<FiShoppingCart size={32} style={{ cursor: 'pointer' }} />}
                />

                <AccountCard
                  title={"Sign Out"}
                  content={"Sign out of your account!"}
                  leftIcon={<FiLogOut size={32} style={{ cursor: 'pointer' }} />}
                />
              </AccountContainerRight>
            </AccountContentContainer>
          </PageContentContainer>
        </ContentContainer>
      </div>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const AccountHeadingContainer = styled.div`
  width: 100%;
  /* max-width: 620px; */
  display: flex;
  justify-content: flex-start;

`;

const AccountHeading = styled.h3`
  font-family: Poppins;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
  margin-bottom: 15px;
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  align-items: center;
`;

const AccountContentContainer = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop})  {
    flex-direction: row;
  }
`;

const AccountContentContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  /* max-width: 400px; */
  
`;

const AccountContainerRight = styled(AccountContentContainerLeft)`
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
`;

export default AccountPage;
