import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { FiMoreHorizontal } from "react-icons/fi";

//local imports
import NavBar from "../components/NavBar";
import Colors from "../constants/Colors";
import { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";

//component imports
import ListingCard from "../components/Product/ListingCard";
import { useNavigate } from "react-router-dom";
import { useGetFeaturedProductsQuery } from "../features/product/productApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useLazyGetCartItemCountQuery } from '../features/cart/cartApiSlice'
import Footer from "../components/Universal/Footer";
import { updateCartCount } from "../features/cart/cartSlices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import spinnerLottie from "../assets/lottie/loading-orange.json";
import { lottieOptions } from "../utils/lottieOptions";
import Lottie from 'react-lottie';
import Carousel from 'react-material-ui-carousel';
import { useLazyGetCampaignsQuery } from "../features/campaign/campaignApiSlices";
import { CampaignModel } from "../features/campaign/types";
import useWindowDimensions from "../utils/useWindowDimensions";
import ReprojecCard from "../components/ReprojecCard";

let HomePage = () => {

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const authInfo = useAppSelector(state => state.auth)
  const dimensions = useWindowDimensions()

  let [campaigns, setCampaigns] = useState<Array<CampaignModel>>([]);

  let [featuredProductPage, setFeaturedProductPage] = useState(0);
  let [featuredProductCount, setFeaturedProductCount] = useState(20);

  let [triggerItemCount] = useLazyGetCartItemCountQuery();
  let [triggerGetCampaigns] = useLazyGetCampaignsQuery();

  let { data: featuredProductData } = useGetFeaturedProductsQuery({ page: featuredProductPage, count: featuredProductCount } ?? skipToken);

  let setCartItemCount = () => {
    if (authInfo.isAuthUser) triggerItemCount()
      .unwrap()
      .then(response => {
        if (response.response) dispatch(updateCartCount(response.response?.count))
      })
  }

  let retrieveCampaigns = () => {
    triggerGetCampaigns()
      .unwrap()
      .then(response => {
        if (response.response) setCampaigns(response.response?.content)
      })
  }

  useEffect(() => {
    retrieveCampaigns()
    setCartItemCount()
  }, [])

  return (
    <Container>
      <NavBar />
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        {campaigns.length > 0 ? <CarouselContainer>
          <Carousel
            autoPlay={true}
            className="home-ads-carousel"
            indicatorContainerProps={{
              style: {
                position: 'relative',
                top: -50,
                zIndex: 100,
              }
            }}
            indicatorIconButtonProps={{
              style: {
                color: Colors.borderPrimary
              }
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: Colors.white
              }
            }}
          >
            {campaigns.map((item, key) => (
              <img
                key={`campaign-${key}`}
                width={dimensions.width ? dimensions.width : 0}
                height={'100%'}
                src={item.campaignPictureUrl}
                alt={`${item.campaignCode} Campaign`}
                onClick={() => navigate(`/product/info/${item.productId}`)}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </Carousel>
        </CarouselContainer>:null}

        {featuredProductData !== undefined ? <ContentContainer>
          <SectionContainer>
            <div style={{ gridColumnStart: 2, display: 'flex', gap: 20, flexDirection: 'column' }}>
              <SectionHeaderText>Featured Products</SectionHeaderText>
              {featuredProductData?.response?.content && <>
                <ProductSection>
                  {featuredProductData && featuredProductData?.response?.content?.map((data) =>
                    <ListingCard
                      containerStyling={{ cursor: "pointer" }}
                      key={data.productId}
                      imageUrl={data?.imageUrls?.length && data.imageUrls[0]}
                      productName={data.productName}
                      productType={data.subCategory}
                      productCategory={data.category}
                      quantity={data.quantity}
                      price={data.productPrice}
                      onClick={() => navigate(`/product/info/${data.productId}`)}
                    />
                  )}
                </ProductSection>
              </>}
            </div>
          </SectionContainer>
        </ContentContainer>
          : <ContentContainer>
            <Lottie options={lottieOptions(true, true, spinnerLottie)}
              height={450}
              width={450}
              isStopped={false}
              isPaused={false}
            />
          </ContentContainer>}
      </div>

      <div style={{ padding: 20 }}>
        <ReprojecCard />
      </div>

      <Footer />
      
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  height: 100%;
  background-color: ${Colors.background};
  position: relative;
  padding: 20px;
  padding-top: 0px;
`;


// Section Styling
let SectionHeaderText = styled.h3`
  padding: 0;
  margin: 0;
  color: ${Colors.black};
  text-align: left;
`;

let SectionContainer = styled.div`
  
  /* width: 100%; */
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
    display: grid;
    grid-template-columns: auto 80% auto;
  }
`;

let CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

let ProductSection = styled.div`
  /* width: 100%; */
  display: inline-flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items:flex-start;
  /* justify-content: center; */
`;

export default HomePage;
