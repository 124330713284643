import React, { FC } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Colors from "../constants/Colors";

interface TextInputInterface {
  iconName?: string,
  placeholder: string,
  type: string,
  showErrorBorder?: boolean;
  onChange?: Function
}

const TextInput: FC<TextInputInterface> = (props) => {
  return (
    <Container
      style={{
        borderColor: props.showErrorBorder ? Colors.red : 'none',
        borderWidth: props.showErrorBorder ? 2 : 0,
        borderStyle: props.showErrorBorder ? 'solid' : 'none'
      }}
    >
      <FeatherIcon icon={props.iconName ? props.iconName : "user"} size={24}/>
      <Input
        placeholder={props.placeholder}
        type={props.type}
        onChange={(event) => { if (props.onChange) props.onChange(event.target.value) }}
      />
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  min-height: 36px;
  /* width: 100%; */
  margin-bottom: 20px;
  padding-left: 15px;
  background-color: #ebebeb;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  & > .input-icon {
    color: #9e9e9e;
  }
`;

const Input = styled.input`
  border: none;
  background: none;
  /* height: 28px; */
  width: 100%;
  /* min-width: 20%; */
  margin-left: 10px;
  &:focus {
    outline: none;
  }
`;

export default TextInput;
