import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";


const firebaseConfig = {

    apiKey: "AIzaSyCP5RohGqieJK7Taz6xRjAGhheNRgycY8U",

    authDomain: "bae-dev-8d5c4.firebaseapp.com",

    projectId: "bae-dev-8d5c4",

    storageBucket: "bae-dev-8d5c4.appspot.com",

    messagingSenderId: "810322576379",

    appId: "1:810322576379:web:e44908cd6dff21ef66415e",

    measurementId: "G-ZL8TWNPBN7"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const firebaseAuth = getAuth(app);


