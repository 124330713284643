import React, { FC } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Colors from "../constants/Colors";

interface TextAreaInterface {
  // iconName?: string,
  placeholder: string,
  showErrorBorder?: boolean;
  onChange?: Function
}

const TextArea: FC<TextAreaInterface> = (props) => {
  return (
    <Container
      style={{
        borderColor: props.showErrorBorder ? Colors.red : 'none',
        borderWidth: props.showErrorBorder ? 2 : 0,
        borderStyle: props.showErrorBorder ? 'solid' : 'none'
      }}
    >
      {/* <FeatherIcon icon={props.iconName ? props.iconName : "user"} size={24}/> */}
      <Input
        placeholder={props.placeholder}
        rows={5}
        // type={props.type}
        onChange={(event) => { if (props.onChange) props.onChange(event.target.value) }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  min-height: 80px;
  /* width: 100%; */
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ebebeb;
  & > .input-icon {
    color: #9e9e9e;
  }
`;

const Input = styled.textarea`
  border: none;
  background: none;
  height: 100%;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

export default TextArea;
