import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { FAQs } from '../constants/FAQs';

// COMPONENT IMPORTS
import NavBar from "../components/NavBar";
import Colors from "../constants/Colors";
import Footer from "../components/Universal/Footer";
import Divider from "@mui/material/Divider";

const FAQsPage = (props) => {

  return (
    <Container>
      <div>
        <NavBar />
        <ContentContainer>
          <FormContainer>


            <h2 style={{ textAlign: 'center' }}><b>Frequently Asked Questions</b></h2>

            {FAQs.map((item, index) => (
              <div
                style={{ marginBottom: 25 }}
                key={`FAQ-${index}`}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <FeatherIcon icon="help-circle" stroke={`${Colors.orange}`} size="20" style={{ marginRight: 10 }} />
                  </div>
                  <h4 style={{ marginBottom: 10, marginTop: 10 }}>{item.Q}</h4>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                  <div>
                    <FeatherIcon icon="check-circle" stroke={`${Colors.green}`} size="20" style={{ marginRight: 10 }} />
                  </div>
                  <p style={{ margin: 0 }}>{item.A}</p>
                </div>
                <Divider light style={{marginTop: 10}}/>
              </div>
            ))}

          </FormContainer>
        </ContentContainer>
      </div>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 800px;
  min-width: 250px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
`;
export default FAQsPage;
