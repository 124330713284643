import React from "react";
import styled from "styled-components";
import { FiArrowRight } from "react-icons/fi";

//internal imports
import Colors from "../constants/Colors";
import { Divider } from "@mui/material";
import { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";
import useWindowDimensions from "../utils/useWindowDimensions";

let ReprojecCard = () => {
    let windowDimensions = useWindowDimensions()
    return (
        <Container>
            <MainContentContainer>
                {windowDimensions.width&&windowDimensions.width<600&&<LogoContainer>
                    <img src={"/logos/reprojec-logo.svg"} alt="Reprojec Logo" style={{ width: 35, height: 35 }} />
                    <Text style={{fontWeight: 500, fontSize: 18}}>Reprojec</Text>
                </LogoContainer>}
                <MainTextContainer>
                    <HeaderText>
                        We are available for a new project        
                    </HeaderText>

                    <Text>Have any project in mind? Let’s start today</Text>
                </MainTextContainer>
                {windowDimensions.width&&windowDimensions.width>=600&&<LogoContainer>
                    <img src={"/logos/reprojec-logo.svg"} alt="Reprojec Logo" style={{ width: 35, height: 35 }} />
                    <Text style={{fontWeight: 500, fontSize: 18}}>Reprojec</Text>
                </LogoContainer>}
            </MainContentContainer>
            <Divider style={{backgroundColor: Colors.white}}/>
            <LowerContentContainer>
                <div style={{display: 'inline-flex', alignItems: 'center', gap: 10}}>
                    <FiArrowRight   style={{ stroke: Colors.white, strokeWidth: 2 }}/>
                    <Text>reprojecbiz@gmail.com</Text>
                </div>
                <div style={{display: 'inline-flex', alignItems: 'center', gap: 10}}>
                    <FiArrowRight   style={{ stroke: Colors.white, strokeWidth: 2 }}/>
                    <LinkText href="https://www.reprojec.com">www.reprojec.com</LinkText>
                </div>
            </LowerContentContainer>
        </Container>
    )
}

const Container = styled.div`
    padding: 20px;
    border-radius: 6px;
    background-color: ${Colors.carbonBlack};
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const MainContentContainer = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: inline-flex;
        align-items: start;
        justify-content: space-between;
    }
`;

const LowerContentContainer = styled.div`
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
        display: inline-flex;
        align-items: start;
        justify-content: space-between;
    }
`;

const MainTextContainer = styled.div``;

const LogoContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 5px;
`;

const HeaderText = styled.h3`
    color: ${Colors.white};
    margin: 0px;
`;

const Text = styled.p`
    color: ${Colors.white};
    font-weight: 400;
    margin: 0px;
`;

const LinkText = styled.a`
    color: ${Colors.white};
    font-weight: 400;
    margin: 0px;
    text-decoration: none;
`;

export default ReprojecCard;