import React, { FC } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import color from "../constants/Colors";
import { useAppDispatch } from "../store";
import { resetGlobalState } from "../features/auth/authSlice";
import { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";

interface AccountCardInterface {
  title?: String;
  content?: String;
  onClick?: Function;
  leftIcon?: any;
}

const AccountCard: FC<AccountCardInterface> = (props) => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let reRoute = () => {
    let path = "newPath";

    if (props.title === "Order History") {
      path = "/account/order-history";
    }
    if (props.title === "Wishlist Items") {
      path = "/account/wishlist";
    }
    if (props.title === "Account Information") {
      path = "/account/information";
    }
    if (props.title === "Account Preferences") {
      path = "/account/preferences";
    }
    if (props.title === "Your Cart") {
      path = "/account/cart";
    }
    if (props.title === "Sign Out") {
      path = "/login";
      dispatch(resetGlobalState())
    }
    return navigate(path);
  };

  return (
    <Container onClick={reRoute}>
      <div>
        {props.leftIcon ? props.leftIcon : null}
      </div>
      <div>
        <Title>{props.title}</Title>
        <Content>{props.content}</Content>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  background: ${color.white};
  max-width: 400px;
  min-width: 350px;
  &:hover {
    cursor: pointer;
  }
  /* @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
    max-width: 400px;
  } */
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  padding-left: 10px;
  color: ${color.carbonBlack}
`;

const Content = styled.span`
  padding-left: 10px;
  color: ${color.black600}
`;

export default AccountCard;
