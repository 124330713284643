import React, { CSSProperties, FC } from 'react';
import styled from "styled-components";
import Colors from '../../constants/Colors';
import useWindowDimensions from '../../utils/useWindowDimensions';

interface TextTrailInterface {
    textArray: Array<string>;
    containerStyling?: CSSProperties;
    collapse?: boolean;
}

let TextTrail: FC<TextTrailInterface> = ({ textArray, containerStyling }) => {

    let { height, width } = useWindowDimensions();

    const addTrailingPeriods = (text: String) => {
        return (
            <TrailingText style={{ color: Colors.orange }}>
                {width && width < 600 && text.length > 15 ? text.substring(0, 15) + "..." : text}
            </TrailingText>
        );
    }

    let trail: Array<JSX.Element> = [];

    /**
     * TODO: Update mobileScreenSize to the right value
     */
    let mobileScreenSize: Number = 460;

    if (width && width < mobileScreenSize && textArray.length > 2) {
        trail.push(
            <>
                <TrailingText>{textArray[0]}</TrailingText>
                <TrailSeparator>{'...'}</TrailSeparator>
                {addTrailingPeriods(textArray[textArray.length - 1])}
            </>
        );
    } else {
        textArray.forEach((text, index, array) => {
            if (array.length - 1 !== index && array.length !== 1) trail.push(
                <>
                    <TrailingText>{text}</TrailingText>
                    <TrailSeparator>{'>'}</TrailSeparator>
                </>
            )
            if (array.length - 1 === index) trail.push(
                addTrailingPeriods(text)
            )
        })
    }



    return (
        <Container style={{ ...containerStyling }}>
            {trail?.length ? trail : null}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const TrailingText = styled.p`
    font-weight: 500;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.fontGrey};
    text-align: left;
`;

let TrailSeparator = styled.p`
    font-weight: 500;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.lightGrey2};
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
`;

export default TextTrail;