import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { FiPlus, FiMinus } from "react-icons/fi";

//Internal Imports
import Colors from '../../constants/Colors';
import { SCREEN_WIDTH_CONSTANTS } from '../../constants/Devices';

interface CheckoutItemInterface {
    imageUrl: string;
    name: string;
    price: number;
    category: string;
    quantity: number;
    availableQuantity: number;
    totalWeight: number;
    containerStyling?: CSSProperties
}

let CheckoutItem: FC<CheckoutItemInterface> = (props) => {
    return (
        <CardContainer style={{ ...props.containerStyling }}>
            <ImageContainer>
                <Image src={props.imageUrl} />
            </ImageContainer>
            <Content>
                <div>
                    <Text style={{ color: Colors.carbonBlack, fontWeight: '500' }}>{props.name}</Text>
                    <Text>{props.category}</Text>
                    <span style={{ display: 'inline-flex', gap: 15 }}>
                        <Pill>WT {props.totalWeight} lb</Pill>
                        <Pill>QTY {props.quantity}</Pill>
                    </span>
                </div>
                <span>
                    <Text style={{ color: Colors.carbonBlack, fontWeight: '500' }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.price)}</Text>
                </span>
            </Content>
        </CardContainer>
    )
};

let CardContainer = styled.div`
    padding: 15px;
    border-radius: 6px;
    border: 2px solid ${Colors.borderPrimary};
    display: flex;
    flex-direction: row;
`;

const ImageContainer = styled.div`
    /* min-width: 100px; */
    width: 100px;
    height: 100px;
    border-radius: 6px;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.iPad}){
		width: 150px;
        height: 150px;
	}
`;

const Image = styled.img`
    height: 100%;
`;

const Content = styled.div`
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* align-items: center; */
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.iPad}){
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }      
`;

let Text = styled.p`
	font-family: Poppins;
	padding: 0;
	margin: 0;
	color: ${Colors.black600};
	font-size: 16px;
`;

let Pill = styled.span`
    background-color: ${Colors.white100};
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px
`;

export default CheckoutItem;