import React, { FC } from 'react';
import styled from "styled-components";
import Lottie from 'react-lottie';

import Colors from '../../constants/Colors';
import doneTaskLottie from '../../assets/lottie/done-task.json';
import StandardButton from '../StandardButton';

interface SuccessTransactionModalInterface {
    closeModal: Function;
    text: string
}

let SuccessTransactionModal: FC<SuccessTransactionModalInterface> = (props) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: doneTaskLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{marginBottom: "20px", textAlign: "center", fontWeight: '500' }}>{props.text}</Text>
                <Lottie options={defaultOptions}
                    height={150}
                    width={150}
                    isStopped={false}
                    isPaused={false} />
                    <StandardButton
                        onClick={props.closeModal}
                        value={"Done"}
                        containerStyling={{width: '100%', marginTop: '20px'}}
                    />
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: ${Colors.darkGrey};
`;

export default SuccessTransactionModal;