import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../../store";
import {GeneralResponseType, PaginationResponseType} from "../types";
import { CampaignModel } from "./types";
import {refreshTokenUtil} from "../../utils/tokenUtil";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const campaignApiSlice = createApi({
    reducerPath: 'CampaignApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${CUSTOMER_API_BASE_URL}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.accessToken
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['Campaign'],

    endpoints: (builder) => ({

        getCampaigns: builder.query<GeneralResponseType<PaginationResponseType<CampaignModel>>, void>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let cartItemResponse = await fetchWithBQ({
                    url: "/campaign",
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                });

                return cartItemResponse as any;
            },
            providesTags: ['Campaign'],
            // providesTags: (result) =>
            //     result?.response && result?.response?.cartItems
            //         ? [...result?.response?.cartItems.map(item => ({type: "Cart" as const, id: item.cartItemId})), 'Cart']
            //         : ['Cart'],
            keepUnusedDataFor: 60 //Seconds to keep cache data
        })

    })
})

export const {useLazyGetCampaignsQuery} = campaignApiSlice