import React, { useEffect, useState } from "react";
import styled from "styled-components";

import NavBar from "../components/NavBar";
import AccountUserCard from "../components/AccountUserCard";
import TextTrail from "../components/Universal/TextTrail";
import Colors from "../constants/Colors";
import DeliveryMethod from "../components/DeliveryMethod";
import { Screen, SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";
import { useAppDispatch, useAppSelector } from '../store'
import {
  useGetCustomerInfoQuery,
  useAddAddressMutation,
  useUpdateCustomerInfoMutation,
  useDeleteAddressMutation
} from '../features/customer/customerApiSlice';
import { addAddress, deleteCustomerAddresses, setCustomerInfo, updateCustomerInfo } from '../features/customer/customerSlice'
import ModalContainer from '../components/Modal/ModalContainer'
import UpdatePersonalInfoModal from "../components/Modal/UpdatePersonalInfoModal";
import { CustomerShippingAddressType, CustomerType } from '../features/customer/types';
import MessageModal from "../components/Modal/MessageModal";
import AddCustomerAddressInfoModal from "../components/Modal/AddCustomerAddressInfoModal";
import Footer from "../components/Universal/Footer";
import { isValidPhoneNumber } from "../utils/utils";
import { Chip, Divider } from "@mui/material";

const AccountInformationPage = (props) => {
  const stateCustomerInfo = useAppSelector(state => state.customer)
  const dispatch = useAppDispatch();

  let [customer, setCustomer] = useState<CustomerType>(stateCustomerInfo.customer ? stateCustomerInfo.customer : {} as CustomerType);
  let [updateCustomerInfoModal, setUpdateCustomerModalVisible] = useState(false);
  let [addCustomerAddressModal, setAddCustomerAddressModal] = useState(false);
  let [messageModalVisible, setMessageModalVisible] = useState(false);
  let [messageModalMessage, setMessageModalMessage] = useState("");

  let [triggerUpdateCustomerInfo] = useUpdateCustomerInfoMutation();
  let [triggerAddAddress] = useAddAddressMutation();
  let [triggerDeleteAddress] = useDeleteAddressMutation();

  let { data, isSuccess } = useGetCustomerInfoQuery();

  useEffect(() => {
    if (isSuccess && data?.response) {
      dispatch(setCustomerInfo(data?.response))
    }
  }, [data, isSuccess])

  let openCustomerUpdateModal = () => {
    setCustomer(stateCustomerInfo.customer ? stateCustomerInfo.customer : {} as CustomerType)
    setUpdateCustomerModalVisible(true)
  }

  let updateCustomerInfoCall = () => {

    if (customer.firstName === "" || customer.lastName === "" || customer.phoneNumber === "") {
      setMessageModalMessage("There are still empty fields")
      setMessageModalVisible(true)
      return;
    }

    if (!isValidPhoneNumber(customer.phoneNumber)) {
      setMessageModalMessage("Phone number format is incorrect")
      setMessageModalVisible(true)
      return;
    }

    triggerUpdateCustomerInfo(customer)
      .unwrap()
      .then((data => {
        dispatch(updateCustomerInfo(data.response?.updated_user ? data.response.updated_user : customer))
        setUpdateCustomerModalVisible(false)
      }))
      .catch((error) => {
        setUpdateCustomerModalVisible(false)
        setMessageModalMessage(error.message);
        setMessageModalVisible(true)
      })
  }

  let deleteCustomerAddressCall = (addressId: number) => {
    triggerDeleteAddress(addressId)
      .unwrap()
      .then(data => {
        dispatch(deleteCustomerAddresses(addressId))
      })
      .catch((error) => {
        setUpdateCustomerModalVisible(false)
        setMessageModalMessage(error.message);
        setMessageModalVisible(true)
      })
  }

  let addCustomerAddress = (address: CustomerShippingAddressType) => {
    console.log(address)
    triggerAddAddress(address)
      .unwrap()
      .then(data => {
        dispatch(addAddress(data.response ? data.response : address))
        setAddCustomerAddressModal(false)
      })
      .catch((error) => {
        setAddCustomerAddressModal(false)
        setMessageModalMessage(error.message);
        setMessageModalVisible(true)
      })
  }

  return (
    <Container>
      <NavBar />
      <Content>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <div style={{ width: '100%' }}>
            <TextTrail
              textArray={["Your Account", "Account Information"]}
              containerStyling={{ marginTop: "20px 0" }}
            />
          </div>

          <CardContainer>
            <ContentContainerFirst>
              <AccountUserCard
                userName={stateCustomerInfo ? stateCustomerInfo.customer?.firstName + " " + stateCustomerInfo.customer?.lastName : "N/A"} />
            </ContentContainerFirst>

            <ContentContainerSecond>
              <div>
                <PersonalHeader>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Personal Information
                  </span>
                  <ChipContainer>
                    <Chip
                      color={'primary'}
                      onClick={openCustomerUpdateModal}
                      label={
                        <p style={{ color: "#fff" }}>
                          Edit
                        </p>
                      } />
                  </ChipContainer>
                </PersonalHeader>

                <NameContainer>
                  <div style={{ width: "90px" }}>
                    <span
                      style={{
                        fontWeight: "medium",
                      }}
                    >
                      Name
                    </span>
                  </div>
                  <div style={{ width: "220px" }}>
                    <span>
                      {stateCustomerInfo.customer
                        ? stateCustomerInfo.customer?.firstName + " " + stateCustomerInfo.customer?.lastName
                        : "N/A"}
                    </span>
                  </div>
                </NameContainer>

                <EmailContainer>
                  <div style={{ width: "90px" }}>
                    <span
                      style={{
                        fontWeight: "medium",
                      }}
                    >
                      Email
                    </span>
                  </div>
                  <div style={{ width: "220px" }}>
                    <span>
                      {stateCustomerInfo.customer
                        ? stateCustomerInfo.customer?.email
                        : "N/A"}
                    </span>
                  </div>
                </EmailContainer>

                <PhoneContainer>
                  <div style={{ width: "90px", flexWrap: "wrap" }}>
                    <span
                      style={{
                        fontWeight: "medium",
                      }}
                    >
                      Phone Number
                    </span>
                  </div>
                  <div style={{ width: "220px" }}>
                    <span>
                      {stateCustomerInfo.customer
                        ? stateCustomerInfo.customer?.phoneNumber
                        : "N/A"}
                    </span>
                  </div>
                </PhoneContainer>

              </div>

              <div>
                <AddressHeader>
                  <span style={{ fontWeight: "500", fontSize: "20px" }}>
                    Addresses
                  </span>
                  <ChipContainer>
                    <Chip
                      color={'success'}
                      onClick={() => setAddCustomerAddressModal(true)}
                      label={
                        <p style={{ color: "#fff" }}>
                          Add
                        </p>
                      } />
                  </ChipContainer>
                </AddressHeader>

                {stateCustomerInfo && stateCustomerInfo.addresses?.map((address, index) => (
                  <AddressPrimary key={address.id}>
                    <Left>
                      <span>
                        {address.addressLine1}
                      </span>
                      {!address.isZipmailAddress && <span>
                        {address.addressLine2}
                      </span>}
                      {!address.isZipmailAddress && <span>
                        {address.parishStateProvince}
                      </span>}
                      {!address.isZipmailAddress && <span>
                        {address.city}
                      </span>}
                      {(!address.isZipmailAddress && !address.isKnutsfordAddress) && <span>
                        {address.postalCode}
                      </span>}
                      <ChipContainer>
                        <Chip
                          color='error'
                          onClick={() => deleteCustomerAddressCall(address.id)}
                          label={
                            <p style={{ color: "#fff" }}>
                              Delete
                            </p>
                          } />
                      </ChipContainer>
                    </Left>
                    <Right>
                      {stateCustomerInfo.addresses && (address.isKnutsfordAddress || address.isZipmailAddress) &&
                        <ChipContainer>
                          <Chip
                            variant="outlined"
                            label={address.isKnutsfordAddress ? "Knutsford" : "Zipmail"} />
                        </ChipContainer>}
                    </Right>
                  </AddressPrimary>
                ))}

              </div>
            </ContentContainerSecond>
          </CardContainer>
        </div>

      </Content>

      <ModalContainer visible={updateCustomerInfoModal}>
        <UpdatePersonalInfoModal
          firstName={customer.firstName}
          lastName={customer.lastName}
          phoneNumber={customer.phoneNumber}
          onChangeFirstName={(val: string) => setCustomer({ ...customer, firstName: val })}
          onChangeLastName={(val: string) => setCustomer({ ...customer, lastName: val })}
          onChangePhoneNumber={(val: string) => setCustomer({ ...customer, phoneNumber: val })}
          onClickConfirm={() => { updateCustomerInfoCall() }}
          onClickCancel={() => { setUpdateCustomerModalVisible(false) }}
        />
      </ModalContainer>

      <ModalContainer visible={addCustomerAddressModal}>
        <AddCustomerAddressInfoModal
          onClickConfirm={(val: CustomerShippingAddressType) => { addCustomerAddress(val) }}
          onClickCancel={() => { setAddCustomerAddressModal(false) }}

        />
      </ModalContainer>

      <ModalContainer visible={messageModalVisible}>
        <MessageModal
          message={messageModalMessage}
          onClickConfirm={() => setMessageModalVisible(false)}
        />
      </ModalContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const Content = styled.div`
  padding: 0 20px;
  /* width: max-content; */
  height: 100%;
  margin: auto;
  justify-content: center;

  @media ${Screen.iPad} {
    display: flex;
    flex-wrap: nowrap;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    max-width: 700px;
  }
`;

const ContentContainerFirst = styled.div`
  width: 100%;
`;

const ContentContainerSecond = styled(ContentContainerFirst)`
  margin-top: 40px;
`;

const PersonalHeader = styled.div`
  border-bottom: 2px solid ${Colors.lightGrey2};
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NameContainer = styled.div`
  border-bottom: 2px solid ${Colors.lightGrey2};
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
`;

const Edit = styled.span`
  text-decoration: underline;
  width: 30px;
  margin-left: auto;
  color: ${Colors.blue};
  &:hover {
    cursor: pointer;
  }
`;

const Add = styled(Edit)``;

const Delete = styled(Edit)`
  margin-left: 15px;
  color: ${Colors.red};
`;

const EmailContainer = styled(NameContainer)``;

const PhoneContainer = styled(NameContainer)`
  border-bottom: none;
`;

const AddressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${Colors.lightGrey2};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const AddressPrimary = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 20px;
`;

const EditDelete = styled.div`
  margin-top: 8px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

const Right = styled.div`
  margin-left: auto;
`;

const ChipContainer = styled.div`
    margin-top: 15px;
    /* margin-right: 10px; */
`

export default AccountInformationPage;
