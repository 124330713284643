export const FAQs = [
    {
        "Q": "Where are you located?",
        "A": "We are currently based entirely online with plans to establish a physical location soon."
    },
    {
        "Q": "How can I get my item?",
        "A": "We provide delivery of your items through pick up at limited location in the kingston and St. Catherine area, for customers outside our pick up areas we provide delivery through Knutsford Express and Zipmail"
    },
    {
        "Q": "Can I track my package?",
        "A": "Items sent through Zipmail and Knustford will have a tracking number attached to the order after the item has shipped. This is tracking number is viewable from the orders page."
    },
    {
        "Q": "What are your operating hours?",
        "A": "Monday to Saturday 9am to 5pm"
    },
    {
        "Q": "How soon could I get my order?",
        "A": "Orders are processed within 24 hours. If the method of delivery is Pickup a representative will reach out and arrange for item pick up else if the method is zipmail and knutsford the item will be shipped after processing."
    },
    {
        "Q": "When are you going to get item `X`?",
        "A": "Our inventory is always growing, also if there is enough demand for an item we will promptly stock said item(s) ."
    },
    {
        "Q": "How can we contact you?",
        "A": "Our contact information is on our website's contact us page."
    },
    {
        "Q": "Will item `X` fit my car?",
        "A": "If an item is for a specific car make an model that information will be explicitly stated on the product page otherwise the item is universal which will also be stated explicitly."
    }
]