import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Category from "../components/Category";
import NavBar from "../components/NavBar";
import Footer from "../components/Universal/Footer";
import { Screen, SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";

const CategoryPage = (props) => {
  let navigate = useNavigate();
  return (
    <Container>
      <Nav>
        <NavBar />
      </Nav>
      <div style={{ padding: "0 20px", maxWidth: "100%" }}>
        <Heading>Accessory Categories</Heading>
        <ContentContainer>
          <Category
            containerStyling={{ paddingRight: "20px" }}
            source="images/headlights-img.jpg"
            alternate="BMW m3 image"
            label="Headlights and Bulbs"
            onClick={() => navigate("/search?search_category=Headlight, Bulbs and Lights")}
          />
          <Category
            containerStyling={{}}
            source="images/vinyl-img.jpg"
            alternate="Mustang image"
            label="Vinyl, Wraps and Tints"
            onClick={() => navigate("/search?search_category=Vinyl, Wraps and Tints")}
          />
          <Category
            containerStyling={{ paddingRight: "20px" }}
            source="images/care-img.jpg"
            alternate="Soapy car image"
            label="Care and Maintenance"
            onClick={() => navigate("/search?search_category=Care and Maintenance")}
          />
          <Category
            source="images/electronics-img.jpg"
            alternate="Car radio image"
            label="Electronics"
            onClick={() => navigate("/search?search_category=Electronics")}
          />
          <Category
            containerStyling={{ paddingRight: "20px" }}
            source="images/detailing-img.jpg"
            alternate="Subaru wrx image"
            label="Detailing"
            onClick={() => navigate("/search?search_category=Body Modification and Addons")}
          />
        </ContentContainer>
      </div>
      <Footer/>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Nav = styled.div``;

const Heading = styled.h2`
  font-weight: 500;
  font-size: 24px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, 100%);
  grid-auto-flow: row;
  justify-content: flex-start;
  //tablet
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: flex-start;
  }
`;

export default CategoryPage;
