import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import NavBar from '../components/NavBar';
import TextTrail from '../components/Universal/TextTrail';
import WishlistCard from '../components/WishlistCard';
import Colors from '../constants/Colors';
import { useDeleteWishListItemMutation, useLazyGetAllWishListItemsQuery } from "../features/wishlist/wishlistApiSlice";
import Footer from '../components/Universal/Footer';
import Pagination from '@mui/material/Pagination';

let WishlistPage = () => {
    const [pageNumber, setPageNumber] = useState(0);
    const [itemCount, setItemCount] = useState(10);
    let [lastPage, setLastPage] = useState(true);
    let [numOfPages, setNumOfPages] = useState(0)
    const [triggerGetWishlist, { data: wishlistData }] = useLazyGetAllWishListItemsQuery()
    const [triggerDeleteWishlist] = useDeleteWishListItemMutation()

    useEffect(() => {
        getWishlistList()
    }, [])

    let getWishlistList = (pageNum?) => {
        triggerGetWishlist({ pageNo: pageNum!==undefined ? pageNum : pageNumber, count: itemCount }, true)
            .unwrap()
            .then(data => {
                if (data.response) {
                    setPageNumber(data.response?.pageNo)
                    setLastPage(data.response.last)
                    setNumOfPages(data.response.totalPages)
                }
            })
    }


    return (
        <Container>
            <div>
                <NavBar />
                <ContentContainer>
                    <ContentWrapper>
                        <TextTrail
                            textArray={['Your Account', 'Wishlist']}
                            containerStyling={{ marginTop: '20px' }}
                        />

                        <OrderListContainer>
                            {wishlistData?.response?.content.map(({ product }) =>
                                <WishlistCard
                                    containerStyling={{ marginBottom: "10px" }}
                                    key={product.productId}
                                    imageUrl={product.imageUrls[0]}
                                    productName={product.productName}
                                    itemStatus={product.status}
                                    itemPrice={product.productPrice}
                                    productId={product.productId}
                                    onDelete={triggerDeleteWishlist}
                                />)}
                        </OrderListContainer>

                        <StepperContainer>
                            <Pagination
                                count={numOfPages}
                                page={pageNumber + 1}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    setPageNumber(value - 1);
                                    getWishlistList(value - 1)
                                }}
                            />
                        </StepperContainer>
                    </ContentWrapper>
                </ContentContainer>
            </div>
            <Footer />
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 20px 20px;

`;

const ContentWrapper = styled.div`
    width: 100%;
    max-width: 770px;
    min-width: 350px;
`;

const OrderListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
`;

export default WishlistPage;