export let PRODUCT_STATUS = {
    processing: 'UNFULFILLED',
    completed: 'FULFILLED',
    canceled: 'CANCELED',
    'Processing Order': 'PROCESSING',
    'Unfinished Order': 'INITIATED',
    'Order Paid': 'INITIATED_PAYMENT_SUCCESSFUL',
    'Order Payment Failed': 'INITIATED_PAYMENT_FAILED'
}

export let PRODUCT_STATUS_MAPPING = {
    'UNFULFILLED': 'Processing',
    'PROCESSING': 'Processing Order',
    'FULFILLED': 'Completed',
    'CANCELED': 'Canceled',
    'INITIATED': 'Unfinished Order',
    'INITIATED_PAYMENT_SUCCESSFUL': 'Order Paid',
    'INITIATED_PAYMENT_FAILED': 'Order Payment Failed'
}