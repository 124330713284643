import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store";
import { GeneralResponseType, PaginationResponseType } from "../types";
import { ExpandedOrderModel, LascoPaySignatureResponseModel, OrderDetailModel, OrderModel, OrderRequestModel } from "./types";
import {refreshTokenUtil} from "../../utils/tokenUtil";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const OrderShippingApiSlice = createApi({
    reducerPath: "OrderApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: `${CUSTOMER_API_BASE_URL}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.accessToken
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['Order'],

    endpoints: (builder)=>({
        createIntent: builder.query<GeneralResponseType<LascoPaySignatureResponseModel>, OrderRequestModel>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let orderResponse = await fetchWithBQ({
                    url: "/order-create-payment-intent",
                    method: "POST",
                    body: arg,
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })

                return orderResponse as any;
            }
        }),

        submitOrder: builder.query<GeneralResponseType<{order: OrderModel, orderDetails: OrderDetailModel}>, OrderRequestModel>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let orderResponse = await fetchWithBQ({
                    url: "/order/submit-order",
                    method: "POST",
                    body: arg,
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })

                return orderResponse;
            }
        }),

        getOrderList: builder.query<GeneralResponseType<PaginationResponseType<OrderModel>>, { page: number, count: number }>({
            async queryFn(arg, { getState, dispatch }, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let orderResponse = await fetchWithBQ({
                    url: `/order/list?page=${arg.page}&count=${arg.count}`,
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })

                return orderResponse as any;
            }
        }),

        getLatestOnlinePaymentOrder: builder.query<GeneralResponseType<ExpandedOrderModel>, null>({
            async queryFn(arg, { getState, dispatch }, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let orderResponse = await fetchWithBQ({
                    url: `/order/latest-online-payment`,
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })

                return orderResponse;
            }
        }),

        getOrderInvoice: builder.query<GeneralResponseType<string>, { orderId: string }>({
            async queryFn(arg, { getState, dispatch }, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if (utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let orderResponse = await fetchWithBQ({
                    url: `/order/${arg.orderId}`,
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })

                return orderResponse as any;
            }
        }),
    })
})

export const { useLazySubmitOrderQuery, useLazyGetOrderListQuery, useLazyCreateIntentQuery, useGetOrderInvoiceQuery, useLazyGetLatestOnlinePaymentOrderQuery } = OrderShippingApiSlice;