import React, {useEffect, useState} from "react";
import {useGetOrderInvoiceQuery} from "../features/order/orderApiSlice";
import {skipToken} from '@reduxjs/toolkit/query/react'
import {useParams} from "react-router-dom";
import Lottie from 'react-lottie';
import noDataLottie from "../assets/lottie/no-data.json";
import spinnerLottie from "../assets/lottie/loading-orange.json"
import {lottieOptions} from "../utils/lottieOptions";
import NavBar from "../components/NavBar";
import styled from "styled-components";
import Colors from "../constants/Colors";

const OrderInvoicePage = () => {
    const {orderId} = useParams();
    const {data, isError, isSuccess, isLoading} = useGetOrderInvoiceQuery({orderId: orderId!} ?? skipToken);
    const [fileUrl, setFileUrl] = useState("");
    const [base64File, setBase64File] = useState("");
    const [dateString, setDateString] = useState("");

    useEffect(() => {
        if(data?.response){
            setBase64File("data:application/pdf;base64,"+data?.response);
            setFileUrl("data:application/octet-stream;base64,"+data?.response);
            setDateString(new Intl.DateTimeFormat().format(Date.now()));
        }

    }, [data?.response]);

    return (
        <Container>
            <NavBar />
            {isSuccess && <div style={{display:"flex", justifyContent:"center"}}>
                <a
                href={fileUrl}
                download={`Invoice_${orderId}_${dateString}.pdf`}
                rel="noreferrer"
                target="_blank"
            >Click to download invoice</a>
            </div>}

            {isSuccess && <object data={base64File}
                     type="application/pdf"
                     style={{minHeight: "100vh", width: "100%"}}
                     title="Order Invoice">
                <p>
                    Could not display invoice{" "}
                </p>
            </object>}

            {isLoading && <Lottie options={lottieOptions(true, true, spinnerLottie)}
                                  height={450}
                                  width={450}
                                  isStopped={false}
                                  isPaused={false}
            />}
            {isError && <Lottie options={lottieOptions(true, true, noDataLottie)}
                                height={350}
                                width={250}
                                isStopped={false}
                                isPaused={false}
            />}
        </Container>
    );
}

const Container = styled.div`
  min-height: 100vh;
  max-height: 100%;
  background-color: ${Colors.background};
  position: relative;
`;

export default OrderInvoicePage;