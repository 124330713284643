const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "560px",
  tablet: "768px",
  iPad: "834px",
  tabletS: "600px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const SCREEN_WIDTH_CONSTANTS = size;

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,

  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

export const Screen = {
  mobileM: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.laptop})`,
  iPad: `(min-width: ${size.iPad})`,
  laptopL: `(min-width: ${size.laptopL})`
};

export default device;

// export default Screen;
