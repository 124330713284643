import React, { useState } from "react";
import styled from "styled-components";
// COMPONENT IMPORT
import TextInput from '../components/TextInput'
import Button from '../components/StandardButton'
// import { normalUserRegister } from "../redux/thunk/authThunk";
import ModalContainer from "../components/Modal/ModalContainer";
import MessageModal from "../components/Modal/MessageModal";
import { useNavigate } from "react-router-dom";
import Colors from "../constants/Colors";
import {useLazyRegisterQuery} from "../features/auth/authApiSlice";
import {useAppDispatch, useAppSelector} from "../store";
import {RegisterResponseType, RegisterType} from "../features/auth/types";
import {setAuthUser} from "../features/auth/authSlice";
import Footer from "../components/Universal/Footer";
import { isValidPhoneNumber } from "../utils/utils";


const SignUpPage = (props) => {

  let navigate = useNavigate();

  let [firstName, setFirstName] = useState("");
  // let [lastName, setLastName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");

  let [firstNameError, setFirstNameError] = useState(false);
  // let [lastNameError, setLastNameError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [phoneNumberError, setPhoneNumberError] = useState(false);
  let [passwordError, setPasswordError] = useState(false);
  let [confirmPasswordError, setConfirmPasswordError] = useState(false);

  let [messageModalVisible, setMessageModalVisible] = useState(false);
  let [messageModalMessage, setMessageModalMessage] = useState("");

  let [triggerRegister] = useLazyRegisterQuery();
  let dispatch = useAppDispatch();

  let submitRegistration = () => {

    if(firstName==="" || email==="" || phoneNumber==="" || password==="" || confirmPassword==="") {
      setMessageModalMessage("There are still empty fields")
      setMessageModalVisible(true)
      return;
    }

    if(firstName.split(" ").length==1){
      setMessageModalMessage("Please enter your full name")
      setMessageModalVisible(true)
      return;
    }

    if(!isValidPhoneNumber(phoneNumber)){
      setMessageModalMessage("Phone number format is incorrect")
      setMessageModalVisible(true)
      return;
    }

    if (password !== confirmPassword) {
      setMessageModalMessage("Passwords do not match")
      setPasswordError(true)
      setConfirmPasswordError(true)
      setMessageModalVisible(true)
      return;
    }

    let registerRequest:RegisterType = {
      firstName: firstName.split(' ')[0],
      lastName: firstName.split(' ')[1],
      phoneNumber,
      email,
      password
    }

      triggerRegister(registerRequest)
          .unwrap()
          .then((data: RegisterResponseType) =>{
              console.log(data)
              dispatch(setAuthUser(data.response?.access_token))
              navigate("/");
          })
          .catch(( {data} : {data: RegisterResponseType})=> {
              console.error(data)
              setMessageModalMessage(data.message)
              setMessageModalVisible(true)
          })
  }

  return (
    <Container>
      <Content className='col-sm-12 col-lg-6'>
        <Hero>
          <img src="images/signUpHero.jpg" alt="UnDrawn Join Us" />
        </Hero>

        <Form className='col-sm-12'>
          <Heading>Join Us!</Heading>
          <TextInput
            placeholder='Full Name'
            type='text'
            showErrorBorder={firstNameError}
            onChange={(val: string) => {
              setFirstName(val);
              if (val === "" && !firstNameError) setFirstNameError(true)
              if (val !== "" && firstNameError) setFirstNameError(false)
            }} />
          {/* <TextInput
            placeholder='Last Name'
            type='text'
            showErrorBorder={lastNameError}
            onChange={(val: string) => {
              setLastName(val);
              if (val === "" && !lastNameError) setLastNameError(true)
              if (val !== "" && lastNameError) setLastNameError(false)
            }} /> */}
          <TextInput
            placeholder='Email'
            type='email'
            showErrorBorder={emailError}
            iconName="mail"
            onChange={(val: string) => {
              setEmail(val);
              if (val === "" && !emailError) setEmailError(true)
              if (val !== "" && emailError) setEmailError(false)
            }} />
          <TextInput
            placeholder='Phone Number ( 876-000-0000 )'
            type="tel"
            showErrorBorder={phoneNumberError}
            iconName="smartphone"
            onChange={(val: string) => {
              setPhoneNumber(val);
              if (val === "" && !phoneNumberError) setPhoneNumberError(true)
              // if (val !== "" && phoneNumberError) setPhoneNumberError(false)
              if (!isValidPhoneNumber(val) && !phoneNumberError) setPhoneNumberError(true)
              if (isValidPhoneNumber(val) && phoneNumberError) setPhoneNumberError(false)
            }} />
          <TextInput
            placeholder='Password'
            type='password'
            showErrorBorder={passwordError}
            iconName='lock'
            onChange={(val: string) => {
              setPassword(val);
              if (val === "" && !passwordError) setPasswordError(true)
              if (val !== "" && passwordError) setPasswordError(false)
            }} />
          <TextInput
            placeholder='Confirm Password'
            type='password'
            showErrorBorder={confirmPasswordError}
            iconName='lock'
            onChange={(val: string) => {
              setConfirmPassword(val);
              if (val === "" && !confirmPasswordError) setConfirmPasswordError(true)
              if (val !== "" && confirmPasswordError) setConfirmPasswordError(false)
            }} />
          <Button
            value='REGISTER'
            onClick={submitRegistration}
          />
          <Login>
            Already have an account?
            <LoginLink href="/login"> Login Here</LoginLink>
          </Login>
        </Form>
      </Content>
      <ModalContainer visible={messageModalVisible}>
        <MessageModal
          message={messageModalMessage}
          onClickConfirm={() => setMessageModalVisible(false)}
        />
      </ModalContainer>
      <Footer/>
    </Container>
  );
};


// SYTLED COMPONENTS
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 20px;
`;

const Hero = styled.div`
  & > img {
    width: 100%;
    max-width:350px;
    min-width: 250px;
  }
`;

const Heading = styled.h1`
  margin-right: auto;
  color: #707070;
  font-weight: 500;
`;

const Form = styled.form`
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 350px;
  min-width: 250px;
`;

const Login = styled.span`
  margin-top: 20px;
`;

const LoginLink = styled.a`
  color: ${Colors.green};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export default SignUpPage;
