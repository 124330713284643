import React, { FC } from 'react';
import styled from "styled-components";

import Button from '../../components/StandardButton'
import Colors from '../../constants/Colors';

interface MessageModalInterface {
    message: string;
    onClickConfirm: Function;
}

let MessageModal: FC<MessageModalInterface> = (props) => {

    return (
        <ModalWrapper>
            <ModalContainer>
                <Text style={{ marginBottom: "15px", textAlign: "center", fontWeight: '500', fontSize: "18px" }}>Message</Text>
                <Text style={{ marginBottom: "15px", textAlign: "center" }}>{props.message}</Text>
                <Button
                    value="Done"
                    // containerStyles={{ marginLeft: 10 }}
                    containerStyling={{width: '100%'}}
                    onClick={props.onClickConfirm}
                />
            </ModalContainer>
        </ModalWrapper>
    )
}

let ModalWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
`;

let ModalContainer = styled.div`
    min-width: 300px;
    max-width: 380px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let Text = styled.p`
    font-weight: 400;
    font-size: 16px;
    padding: 0;
    margin: 0;
    color: ${Colors.darkGrey};
`;

export default MessageModal;
