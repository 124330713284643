export const pickupLocations = [
    {
        location: "Tropical Plaza",
        parish: "Kingston"
    },
    {
        location: "Manor Park Plaza",
        parish: "Kingston"
    },
    {
        location: "Portmore Mall",
        parish: "St. Catherine"
    },
    {
        location: "Cross Roads Police Station",
        parish: "Kingston"
    },
    {
        location: "University of Technology, Papine",
        parish: "Kingston"
    },
    {
        location: "University of West Indies, Mona",
        parish: "Kingston"
    },
    {
        location: "Spanish Town Police Station",
        parish: "St. Catherine"
    },
    {
        location: "Constant Spring Police Station",
        parish: "Kingston"
    },
    {
        location: "Oasis Shopping Center (Spanish Town)",
        parish: "St. Catherine"
    },
]