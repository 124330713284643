import React, { FC } from 'react';
import styled from 'styled-components';

let ModalContainer:FC<{visible: boolean}> = (props) => {
    if(!props.visible) return null;
    return (
        <ModalBackgroundContainer>
            {props.children}
        </ModalBackgroundContainer>
    )
}

let ModalBackgroundContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    left: 0;
    top: 0;
    z-index: 900;
`;

export default ModalContainer;