import React, { useEffect } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import Lottie from 'react-lottie';
import { format } from 'date-fns'
import { useSelector } from 'react-redux';

//Local Imports
import Colors from '../constants/Colors';
import NavBar from '../components/NavBar';
import StandardButton from '../components/StandardButton';
import { useLazyGetLatestOnlinePaymentOrderQuery } from '../features/order/orderApiSlice';
import spinnerLottie from "../assets/lottie/loading-orange.json";
import { lottieOptions } from "../utils/lottieOptions";
import { RootState } from '../store';
import Footer from '../components/Universal/Footer';

let PaymentConfirmationPage = () => {
    let navigate = useNavigate();

    const stateCustomerInfo = useSelector((state: RootState) => state.customer)

    let [triggerGetOrder, { data, isLoading }] = useLazyGetLatestOnlinePaymentOrderQuery();

    useEffect(() => {
        triggerGetOrder(null)
            .unwrap();
    }, [])

    return (
        <Container>
            <NavBar />
            <PageContentWrapper>
                {!isLoading ? <CardContainer>
                    <HeaderContainer>
                        <ImageComponent src={data?.response?.status === "INITIATED_PAYMENT_SUCCESSFUL" ? 'images/undraw_Confirmed.png' : 'images/undraw_warning.svg'} />
                        {<Text>{data?.response === null ? "No Order Found" : data?.response?.status === "INITIATED_PAYMENT_SUCCESSFUL" ? "Order Placed!" : "Payment Failed"}</Text>}
                        <Text
                            style={{ fontSize: 16 }}
                        > {data?.response === null ? "No online payment order was found" : data?.response?.status === "INITIATED_PAYMENT_SUCCESSFUL" ? "Your order will be processed soon"  :  "Your order could not be processed at this time"}</Text>
                    </HeaderContainer>

                    <Divider />

                    {data?.response?.status === "INITIATED_PAYMENT_SUCCESSFUL"&&<InformationContainer>
                        <DataContainer>
                            <DataText>Order</DataText>
                            <DataText style={{ textAlign: 'right', color: Colors.carbonBlack }}>Ord-{data.response.id}</DataText>
                        </DataContainer>
                        <DataContainer>
                            <DataText>Customer</DataText>
                            <DataText style={{ textAlign: 'right', color: Colors.carbonBlack }}>{stateCustomerInfo.customer?.firstName+" "+stateCustomerInfo.customer?.lastName}</DataText>
                        </DataContainer>
                        <DataContainer>
                            <DataText>Order Date</DataText>
                            <DataText style={{ textAlign: 'right', color: Colors.carbonBlack }}>{format(new Date(data.response.orderDate), 'EEE PP')}</DataText>
                        </DataContainer>
                        <DataContainer>
                            <DataText>Delivery Method</DataText>
                            <DataText style={{ textAlign: 'right', color: Colors.carbonBlack }}>{data.response.orderMethod}</DataText>
                        </DataContainer>
                        <DataContainer>
                            <DataText>Delivery Location</DataText>
                            <DataText style={{ textAlign: 'right', color: Colors.carbonBlack }}>{data.response.address.address_line_1}</DataText>
                        </DataContainer>
                        <DataContainer>
                            <DataText>Order Total</DataText>
                            <DataText style={{ textAlign: 'right', color: Colors.carbonBlack }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.response.orderTotal)}</DataText>
                        </DataContainer>
                    </InformationContainer>}

                    <ButtonContainer>
                        <StandardButton
                            value={"Done"}
                            // disable={cartData?.response?.cartItems.length === 0}
                            containerStyling={{ backgroundColor: Colors.white, borderWidth: 2, borderColor: Colors.orange, borderStyle: "solid" }}
                            textStyling={{ color: Colors.orange }}
                            onClick={() => navigate(`/account/order-history`)}
                        >
                            Done
                        </StandardButton>
                    </ButtonContainer>

                    <div style={{ height: 10, width: '100%', backgroundColor: Colors.orange }} />
                </CardContainer>
                    : <Lottie options={lottieOptions(true, true, spinnerLottie)}
                        height={450}
                        width={450}
                        isStopped={false}
                        isPaused={false}
                    />
                }
            </PageContentWrapper>
            <Footer />
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const PageContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
`;

const CardContainer = styled.div`
    background-color: ${Colors.white};
    border-radius: 6px;
    width: 100%;
    min-width: 380px;
    max-width: 450px;
    padding: 20px;
    padding-bottom: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Text = styled.p`
    font-weight: 400;
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: ${Colors.carbonBlack};
    text-align: left;
`;

const DataText = styled(Text)`
    color: ${Colors.black400};
    font-size: 14px;
`;

const DataContainer = styled.span`
    display: inline-flex;
    justify-content: space-between;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

let ImageComponent = styled.img`
    flex-shrink: 0;
    width: 200px;
    height: 200px;
    object-fit: contain;
`;

let ButtonContainer = styled.span`
    display: inline-flex
`;

export default PaymentConfirmationPage;