const Colors = {
  fontGrey: '#707070',
  fontYellow: '#F89406',
  white: '#FFFFFF',
  background: '#F4F4F4',
  lightGrey1: "#EBEBEB",
  lightGrey2: "#B4B4B6",
  mediumGrey: '#707070',
  darkGrey: '#515151',
  green: '#009B72',
  red: '#FF3900',
  orange: '#F89406',
  blue: '#1C448E',
  skyBlue: '#00A7E1',
  black: '#212229',
  
  // New Colors
  black400: '#909094',
  black600: '#646469',
  carbonBlack: '#212229',
  borderPrimary: '#D0D0D0',
  faintInactive: '#D9D9DA',
  white100: '#F1F1F1'
}

export default Colors;   