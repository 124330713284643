import {Navigate, Outlet} from "react-router-dom";
import {useAppSelector} from "../store";
import React, {useEffect, useState} from "react";
import { isExpired, decodeToken } from "react-jwt";


const ProtectedRoute=({redirectPath = "/login"})=>{


    const stateAuth = useAppSelector((state) => state.auth);
    const decodedToken : {aud: string } | null = decodeToken(stateAuth?.accessToken as string);

    // console.log("<--- stateAuth --->", stateAuth)
    // console.log("<--- decodedToken --->", decodedToken)
    // console.log("<--- decodedToken?.firstName --->", decodedToken?.firstName)

    if(decodedToken?.aud === "B.A.E_Authenticated_user") return <Outlet/>
    // if(!stateAuth.isAuthUser) return <Navigate to={redirectPath} replace />
    return <Navigate to={redirectPath} replace />
}

export default ProtectedRoute;