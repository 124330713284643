import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiChevronDown, FiX } from "react-icons/fi";
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';

//local imports
import NavBar from '../components/NavBar';
import Colors from '../constants/Colors';
import { SCREEN_WIDTH_CONSTANTS } from '../constants/Devices';
import Lottie from 'react-lottie';
import ListingCard from '../components/Product/ListingCard';
import SearchFilterModal from '../components/Modal/SearchFilterModal';
import ModalContainer from '../components/Modal/ModalContainer';
import MessageModal from "../components/Modal/MessageModal";
import { useLazySearchQuery } from "../features/product/productApiSlice";
import { ProductSearchParams, ProductSearchTypes, ProductType } from "../features/product/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from '../components/Universal/Footer';
import { lottieOptions } from "../utils/lottieOptions";
import spinnerLottie from "../assets/lottie/loading-orange.json";
import Checkable from '../components/Form/Checkable';
import InputField from '../components/Form/InputField';
import StandardButton from '../components/StandardButton';


let SearchProductPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();
    let [searchFilterModalOpen, setSearchFilterModalOpen] = useState(false);
    // let [itemCount, setItemCount] = useState(1);
    let [itemCount, setItemCount] = useState(10);
    let [pageNumber, setPageNumber] = useState(0);
    let [maxPrice, setMaxPrice] = useState(0);
    let [minPrice, setMinPrice] = useState(0);
    let [searchText, setSearchText] = useState("");
    let [searchCategory, setCategory] = useState("");
    let [messageModalVisible, setMessageModalVisible] = useState(false);
    let [messageModalMessage, setMessageModalMessage] = useState("");
    let [searchResults, setSearchResult] = useState<ProductType[] | undefined>();
    let [disableNextButton, setDisableNextButton] = useState<boolean | undefined>(true);
    let [disablePreviousButton, setDisablePreviousButton] = useState<boolean | undefined>(true);
    let [lastPage, setLastPage] = useState<boolean | undefined>(false);
    let [numOfPages, setNumOfPages] = useState(0)

    const [isUnder1000Selected, setIsUnder1000Selected] = useState(false)
    const [isBetween1000and5000Selected, setIsBetween1000and5000Selected] = useState(false)
    const [isBetween5000and15000Selected, setIsBetween5000and15000Selected] = useState(false)
    const [isAbove15000Selected, setIsAbove15000Selected] = useState(false)
    const [isMinMaxDisabled, setIsMinMaxDisabled] = useState(false)

    let [triggerSearch, { data, isLoading }] = useLazySearchQuery();

    useEffect(() => {
        console.log("triggered")
        let search_string = searchParams.get(ProductSearchParams.search_string);
        let search_category = searchParams.get(ProductSearchParams.search_category);
        let page_number = searchParams.get(ProductSearchParams.page_number);
        let item_count = searchParams.get(ProductSearchParams.item_count);
        let min_price = searchParams.get(ProductSearchParams.min_price);
        let max_price = searchParams.get(ProductSearchParams.max_price);


        console.log("search_category --- " + search_category);
        console.log("search_string --- " + search_string);

        newSubmitSearch(search_category!, search_string!, page_number!, min_price!, max_price!, item_count!);

    }, [searchParams])

    let submitFilter = () => {
        if (maxPrice > 0) {
            searchParams.set(ProductSearchParams.min_price, String(minPrice));
            searchParams.set(ProductSearchParams.max_price, String(maxPrice));
        }
        if (maxPrice === 0 && minPrice === 0) {
            searchParams.delete(ProductSearchParams.min_price)
            searchParams.delete(ProductSearchParams.max_price)
        }

        searchParams.set(ProductSearchParams.search_category, searchCategory);
        setSearchParams(searchParams)
        setSearchFilterModalOpen(false);
    }

    const newSubmitSearch = (searchCategoryParam: string, searchStringParam: string, searchPageNumber: string, searchMinPrice: string, searchMaxPrice: string, searchItemCount: string) => {
        console.log("this is called;")

        let parsePageNumber = parseInt(searchPageNumber);
        let parseMinPrice = parseInt(searchMinPrice);
        let parseMaxPrice = parseInt(searchMaxPrice);
        let parseItemCount = parseInt(searchItemCount);

        if (parseMinPrice && parseMaxPrice && parseMinPrice > parseMaxPrice) {
            setMessageModalVisible(true);
            setMessageModalMessage("Min value cannot be higher than max value!");
            return;
        }

        let tempCriteria: ProductSearchTypes = {
            pageNumber: parsePageNumber ? parsePageNumber : pageNumber,
            searchString: searchStringParam == null ? "" : searchStringParam,
            itemCount: parseItemCount ? parseItemCount : itemCount,
            searchFilter: null
        }

        if (searchCategoryParam || parseMinPrice || parseMaxPrice) {
            tempCriteria.searchFilter = {
                category: searchCategoryParam ? searchCategoryParam : searchCategory,
                minPrice: parseMinPrice ? parseMinPrice : minPrice,
                maxPrice: parseMaxPrice ? parseMaxPrice : maxPrice
            };
        }

        triggerSearch(tempCriteria, true)
            .unwrap()
            .then((data) => {
                setPageNumber(data?.response?.pageNo || 0)
                setLastPage(data?.response?.last)
                setNumOfPages(data?.response?.totalPages || 0)
                setSearchResult(data?.response?.content)
                setDisableNextButton(data?.response?.last)
                setDisablePreviousButton(data?.response?.pageNo === 0)
            })
            .catch(error => console.error(error))

    }

    const submitSearch = (searchCategoryParam?: string, searchStringParam?: string) => {
        console.log("this is called;")
        if (minPrice > maxPrice) {
            setMessageModalVisible(true);
            setMessageModalMessage("Min value cannot be higher than max value!");
            return;
        }

        let tempCriteria: ProductSearchTypes = {
            pageNumber,
            searchString: searchStringParam == null ? "" : searchStringParam,
            itemCount: itemCount.toString() === "-none-" ? 10 : itemCount,
            searchFilter: null
        }

        if (searchCategoryParam || searchCategory !== "" || maxPrice || minPrice) {
            tempCriteria.searchFilter = {
                category: searchCategory !== "" ? searchCategory : searchCategoryParam,
                minPrice,
                maxPrice
            };
        }

        triggerSearch(tempCriteria, true)
            .unwrap()
            .then((data) => {
                setPageNumber(data?.response?.pageNo || 0)
                setSearchResult(data?.response?.content)
                setDisableNextButton(data?.response?.last)
                setDisablePreviousButton(data?.response?.pageNo === 0)
            })
            .catch(error => console.error(error))
    }

    return (
        <Container>
            <NavBar
                onSearchChange={(value) => setSearchText(value)}
                onSearchClick={() => {
                    searchParams.set(ProductSearchParams.search_string, searchText)
                    setSearchParams(searchParams)
                }}
            />
            <div>
                <ContentContainer>

                    <FilterContainer>
                        <HeaderContainer>
                            <Text
                                style={{ fontWeight: '500' }}
                            >
                                Search Filter
                            </Text>
                        </HeaderContainer>

                        <Divider />

                        <Text
                            style={{ fontSize: 16, fontWeight: '500', paddingLeft: 20 }}
                        >
                            Category
                        </Text>

                        <CheckboxContainer>
                            {
                                ["Electronics", "Care and Maintenance", "Headlight, Bulbs and Lights", "Vinyl, Wraps and Tints", "Body Modification and Addons"].map(value => (
                                    <Checkable
                                        labelText={value}
                                        value={searchCategory === value}
                                        onChange={(e: boolean) => {
                                            setCategory(value)
                                        }}
                                    />
                                ))
                            }
                        </CheckboxContainer>

                        <Divider />

                        <Text
                            style={{ fontSize: 16, fontWeight: '500', paddingLeft: 20 }}
                        >
                            Price
                        </Text>

                        <CheckboxContainer>
                            <Checkable
                                labelText='Under $1000'
                                value={isUnder1000Selected}
                                onChange={(e: boolean) => {
                                    setIsUnder1000Selected(e);
                                    setIsBetween1000and5000Selected(false)
                                    setIsBetween5000and15000Selected(false)
                                    setIsAbove15000Selected(false)
                                    setMinPrice(1)
                                    setMaxPrice(1000)
                                    // setMinValue(1)
                                    // setMaxValue(1000)
                                    setIsMinMaxDisabled(e)
                                }}
                            />
                            <Checkable
                                labelText='$1000 to $5000'
                                value={isBetween1000and5000Selected}
                                onChange={(e: boolean) => {
                                    setIsUnder1000Selected(false);
                                    setIsBetween1000and5000Selected(e)
                                    setIsBetween5000and15000Selected(false)
                                    setIsAbove15000Selected(false)
                                    setMinPrice(1000)
                                    setMaxPrice(5000)
                                    // setMinValue(1000)
                                    // setMaxValue(5000)
                                    setIsMinMaxDisabled(e)
                                }}
                            />
                            <Checkable
                                labelText='$5000 to $15,000'
                                value={isBetween5000and15000Selected}
                                onChange={(e: boolean) => {
                                    setIsUnder1000Selected(false);
                                    setIsBetween1000and5000Selected(false)
                                    setIsBetween5000and15000Selected(e)
                                    setIsAbove15000Selected(false)
                                    setMinPrice(5000)
                                    setMaxPrice(15000)
                                    // setMinValue(5000)
                                    // setMaxValue(15000)
                                    setIsMinMaxDisabled(e)
                                }}
                            />
                            <Checkable
                                labelText='$15,000 & above'
                                value={isAbove15000Selected}
                                onChange={(e: boolean) => {
                                    setIsUnder1000Selected(false);
                                    setIsBetween1000and5000Selected(false)
                                    setIsBetween5000and15000Selected(false)
                                    setIsAbove15000Selected(e)
                                    setMinPrice(15000)
                                    setMaxPrice(100000)
                                    // setMinValue(15000)
                                    // setMaxValue(100000)
                                    setIsMinMaxDisabled(e)
                                }}
                            />
                        </CheckboxContainer>

                        <div style={{ display: "flex", flexDirection: "row", gap: 15, paddingRight: 20, paddingLeft: 20 }}>
                            <InputField
                                placeholder="Min"
                                type="number"
                                value={minPrice}
                                min={0}
                                containerStyling={{ flex: 1, marginBottom: 0 }}
                                onChange={(value) => {
                                    // setMinValue(value)
                                    setMinPrice(value);
                                }}
                                disabled={isMinMaxDisabled}
                            />
                            <InputField
                                placeholder="Max"
                                type="number"
                                value={maxPrice}
                                max={1000000}
                                containerStyling={{ flex: 1, marginBottom: 0 }}
                                onChange={(value) => {
                                    // setMaxValue(value)
                                    setMaxPrice(value)
                                }}
                                disabled={isMinMaxDisabled}
                            />
                        </div>

                        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                            <StandardButton
                                value='Confirm'
                                containerStyling={{ backgroundColor: Colors.orange }}
                                onClick={submitFilter}
                            />
                        </div>
                    </FilterContainer>

                    <SectionContainer>

                        <FilterInfoContainer>
                            <MediumText>{searchCategory}</MediumText>
                            <FilterOptionContainer onClick={() => setSearchFilterModalOpen(!searchFilterModalOpen)}>
                                <MediumText>Filter</MediumText>
                                <FiChevronDown color={Colors.fontGrey} size={24} />
                            </FilterOptionContainer>
                        </FilterInfoContainer>

                        <ProductSection>
                            {searchResults && searchResults.length > 0 && searchResults?.map((data, index) =>
                                <ListingCard
                                    containerStyling={{ cursor: "pointer" }}
                                    key={data.productId}
                                    imageUrl={data?.imageUrls?.length && data.imageUrls[0]}
                                    productName={data.productName}
                                    productType={data.subCategory}
                                    productCategory={data.category}
                                    quantity={data.quantity}
                                    price={data.productPrice}
                                    onClick={() => navigate(`/product/info/${data.productId}`)}
                                />
                            )}
                        </ProductSection>

                        {isLoading && <Lottie options={lottieOptions(true, true, spinnerLottie)}
                            height={450}
                            width={450}
                            isStopped={false}
                            isPaused={false}
                        />}

                        {searchResults && searchResults.length === 0 &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <Img
                                    src={"/images/empty_clipboards.svg"}
                                />
                                <h3>Nothing Found</h3>
                            </div>
                        }

                        <StepperContainer>
                            <Pagination 
                                count={numOfPages} 
                                page={pageNumber+1}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    setPageNumber(value-1);
                                    searchParams.set(ProductSearchParams.page_number, String(value-1 ));
                                    setSearchParams(searchParams);
                                }}
                            />
                        </StepperContainer>

                    </SectionContainer>

                </ContentContainer>
            </div>

            {/* Modals */}
            <ModalContainer visible={searchFilterModalOpen}>
                <SearchFilterModal
                    closeModal={() => setSearchFilterModalOpen(!searchFilterModalOpen)}
                    onMinValueChange={(value) => setMinPrice(parseInt(value))}
                    onMaxValueChange={(value) => setMaxPrice(parseInt(value))}
                    onCategoryChange={(value) => setCategory(value)}
                    onFilterSubmit={() => submitFilter()}
                    searchCategory={searchCategory}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                />
            </ModalContainer>

            {/*Error Message Modal*/}
            <ModalContainer visible={messageModalVisible}>
                <MessageModal
                    message={messageModalMessage}
                    onClickConfirm={() => setMessageModalVisible(false)}
                />
            </ModalContainer>
            <Footer />
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  /* width: 100%; */
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
    display: grid;
    grid-template-columns: auto 80%;
    gap: 20px;
  }
`;

let FilterInfoContainer = styled.p`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
`;

let MediumText = styled.p`
  font-weight: 500;
  font-size: 24px;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
  text-align: left;
`;

let FilterOptionContainer = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

let SectionContainer = styled.div`
  width: 100%;
  /* margin-top: 20px; */
  box-sizing: border-box;
  padding: 20px;
  
`;

let StepperContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

let ProductSection = styled.div`
  /* width: 100%; */
  display: inline-flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items:flex-start;
  /* justify-content: center; */
`;

let Img = styled.img`
    height: 300px;
`;


//=========================Filter Styles=========================


let FilterContainer = styled.div`
    display: none;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.laptop}) {
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-right: 0.5px solid ${Colors.black400}
    }
`;

let HeaderContainer = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-top: 20px;
`;

let CheckboxContainer = styled.div`
    padding-left: 20px;
`;

let Text = styled.p`
  font-weight: 400;
  font-size: 18px;
  padding: 0;
  margin: 0;
  color: ${Colors.black};
`;

export default SearchProductPage;