export let ProductCategorySearchMapping = [
    {
        category: "Headlights and Bulbs",
        searchMapping: "/search?search_category=Headlight, Bulbs and Lights"
    },
    {
        category: "Vinyl, Wraps and Tints",
        searchMapping: "/search?search_category=Vinyl, Wraps and Tints"
    },
    {
        category: "Detailing",
        searchMapping: "/search?search_category=Body Modification and Addons"
    },
    {
        category: "Care and Maintenance",
        searchMapping: "/search?search_category=Care and Maintenance",
    },
    {
        category: "Electronics",
        searchMapping: "/search?search_category=Electronics"
    }
]

export default ProductCategorySearchMapping