import React, { CSSProperties, FC } from 'react';
import styled from "styled-components";
import Colors from '../../constants/Colors';

interface CheckableInterface {
    labelText: string;
    containerStyling?: CSSProperties;
    value?: boolean;
    onChange?: Function;
}

let Checkable: FC<CheckableInterface> = (props) => {
    return (
        <Container style={props.containerStyling}>
            <CheckBox
                type="checkbox"
                checked={props.value}
                onChange={()=> {if(props.onChange) props.onChange(!props.value)}}
            />
            <Text>{props.labelText}</Text>
        </Container>
    )
}

let Container = styled.div`
    /* margin-bottom: 10px; */
`;

let CheckBox = styled.input`
    margin-right: 10px;
`;

let Text = styled.label`
    font-weight: 300;
    font-size: 14px;
    padding: 0;
    margin: 0;
    color: ${Colors.black};
`;

export default Checkable;