import React, { CSSProperties, FC, useState } from 'react';
import styled from "styled-components";
import { FiTrash2 } from "react-icons/fi";

//Local Imports
import Colors from "../constants/Colors";
import { SCREEN_WIDTH_CONSTANTS } from '../constants/Devices';
import { useUpdateCartItemMutation } from "../features/cart/cartApiSlice";
import { setNotification } from "../features/notification/notificationSlice";
import { useAppDispatch } from "../store";
import useWindowDimensions from '../utils/useWindowDimensions';
import Incrementer from './Form/Incrementer';

interface OrderHistoryCardInterface {
  imageUrl: string;
  name: string;
  price: number;
  quantity: number;
  availableQuantity: number;
  details: any;
  productId: number;
  cartItemId: string;
  onClickDelete: Function;
  containerStyling?: CSSProperties
}

const CartItem: FC<OrderHistoryCardInterface> = (props) => {

  const [triggerUpdateCartItem, { isLoading }] = useUpdateCartItemMutation()
  const [componentQuantity, setComponentQuantity] = useState(props.quantity);
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();

  const handleChange = (updatedQuantity) => {
    if (updatedQuantity <= 0) return;
    triggerUpdateCartItem({
      cartItemRequest: {
        productId: props.productId,
        quantity: updatedQuantity,
        details: props.details
      },
      cartItemId: props.cartItemId
    }
    ).unwrap()
      .then(response => {
        console.log("Updated cart response", response)
        setComponentQuantity(updatedQuantity)
      })
      .catch(error => {
        console.error("Cart update quantity error", error)
        dispatch(setNotification({ message: error.data.message, show: true }))
      })
  }

  return (
    <Container style={{ ...props.containerStyling }}>
      <ImageContainer>
        <Image src={props.imageUrl} />
      </ImageContainer>
      <Content>
        <ItemProperties>
          <div style={{ overflowY: 'hidden', textOverflow: 'ellipsis', maxHeight: 55 }}>
            <ItemPropertyName>{dimensions.width && dimensions.width > 530 ? props.name : props.name.substring(1, 30) + "..."}</ItemPropertyName>
          </div>
          <AvailableQuantity>Only {props.availableQuantity} in stock</AvailableQuantity>
        </ItemProperties>

        <LowerContainer>
          <ItemPropertyPrice> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.price)} JMD</ItemPropertyPrice>
          <ActionButtonsContainer>
            <Icon onClick={() => props.onClickDelete()}>
              <FiTrash2 size={20} color={Colors.red} />
            </Icon>
            {/* TODO: Incrementer not working */}
            <Incrementer
              availableQuantity={props.availableQuantity}
              updateShoppingQuantity={handleChange}
              value={componentQuantity}
              containerStyling={{ minWidth: 150, width: 150 }}
            />
          </ActionButtonsContainer>
        </LowerContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  padding: 15px;
    height: 125px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}){
        /* height: 100px; */
        min-width: 300px;
    }
`;

const Content = styled.div`
  padding-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
    /* min-width: 100px; */
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
    height: 100%;
`;

const ItemProperties = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  div {
    padding-bottom: 5px;
  }

  div > span:first-child {
    color: ${Colors.lightGrey2};
    font-family: Poppins;
    font-size: 16px;
  }
`;

const ItemPropertyName = styled.p`
  font-family: Poppins;
  font-size: 16px;
  color: ${Colors.carbonBlack};
  font-weight: 500;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  padding: 0;
  margin: 0;
  /* max-height: 50px; */
`;

const ItemPropertyPrice = styled.span`
  color: ${Colors.green};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
`;

const AvailableQuantity = styled.span`
  color: ${Colors.orange};
  font-family: Poppins;
  font-size: 16px;
  /* font-weight: 500 */
`;

const Icon = styled.div`
  display: flex;
  align-items: flex-end;
  > :hover {
    cursor: pointer;
  }
`;

const ActionButtonsContainer = styled.span`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end

`;

export default CartItem;
