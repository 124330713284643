import React, { CSSProperties, FC} from 'react';
import styled from 'styled-components';
import { FiPlus, FiMinus } from "react-icons/fi";
import Colors from '../../constants/Colors';

interface IncrementerInterface {
    availableQuantity: number;
    updateShoppingQuantity: Function;
    value: number;
    containerStyling?: CSSProperties;
}

let Incrementer:FC<IncrementerInterface> = props => {

    let updateCounter = (updateValue: number) => {
        debugger
        if(updateValue > props.availableQuantity) props.updateShoppingQuantity(props.availableQuantity)
        else if(updateValue<0) props.updateShoppingQuantity(0)
        else {props.updateShoppingQuantity(updateValue)}
    }

    return (
        <Container style={{...props.containerStyling}}>
            <FiMinus size={32} style={{ cursor: 'pointer', stroke: Colors.borderPrimary}} onClick={()=>{updateCounter(props.value-1)}}/>
            <Input
                disabled
                value={props.value}
                style={{width: '100%'}}
            />
            <FiPlus size={32} style={{ cursor: 'pointer', stroke: Colors.borderPrimary }} onClick={()=>{updateCounter(props.value+1)}}/>
        </Container>
    )
}

let Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 450px;
    min-width: 270px;
    justify-content: center;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    padding: 5px;
    gap: 10px;
    border: 0.5px solid ${Colors.borderPrimary};
    border-radius: 6px;
`;

let Input = styled.input`
    border: none;
    text-align: center;
`;

export default Incrementer;