import React, { CSSProperties, FC } from "react";
import styled from "styled-components";

interface AccountUserCardInterface {
  containerStyling?: CSSProperties;
  profilePhotoStyling?: CSSProperties;
  titleStyling?: CSSProperties;
  onClick?: Function;
  value?: String;
  userName?: String;
}

const AccountUserCard: FC<AccountUserCardInterface> = (props) => {
  return (
    <Container style={{ ...props.containerStyling }}>
      <ProfilePhoto
        src={"/images/user-profile.svg"}
        style={{ ...props.profilePhotoStyling }}
      />
      <Title style={{ ...props.titleStyling }}>{props.userName}</Title>
    </Container>
  );
};

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 6px; 
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  min-width: 350px;
`;

const ProfilePhoto = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
`;

const Title = styled.a`
  font-size: 20px;
  font-weight: 500;
`;

export default AccountUserCard;
