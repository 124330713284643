import React, { FC, useState } from "react";
import styled from "styled-components";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { FiShoppingCart, FiUser, FiChevronDown, FiStar } from "react-icons/fi";
import { Badge } from "@mui/material";

//Local Imports
import SearchBar from "./SearchBar";
import Device, { SCREEN_WIDTH_CONSTANTS } from "../constants/Devices";
import Colors from "../constants/Colors";
import { useAppSelector } from "../store";
import ProductCategorySearchMapping from "../constants/product-category-mapping";
import useWindowDimensions from "../utils/useWindowDimensions";

interface NavBarInterface {
  onSearchChange?: Function
  onSearchClick?: Function
}

interface DropdownProps {
  options: string[];
}


interface DropdownContentProps {
  open: boolean;
}

const NavBar: FC<NavBarInterface> = (props) => {
  let navigate = useNavigate();
  let windowDimensions = useWindowDimensions()
  const stateCustomerInfo = useAppSelector(state => state.customer)
  const cartInfo = useAppSelector(state => state.cart)
  const [searchText, setSearchText] = useState<string>("");
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(false)

  return (
    <div>
      <Container>
        <Left className="col-lg-4">
          <Logo onClick={() => navigate("/")} >
            <img src={"/images/logo.svg"} alt="Company Logo" style={{ width: 35, height: 35 }} />
          </Logo>
          <CompanyName onClick={() => navigate("/")}>Bailey's Auto Edits</CompanyName>
          {/* {width && width < 720 && <FeatherIcon icon="menu" className="menu" onClick={() => setSideNavOpen(true)} />} */}
        </Left>
        <Middle className="col-lg-4">
          <SearchBar
            onChange={(value) => {
              setSearchText(value)
              if (props.onSearchChange) props.onSearchChange(value)
            }}
            onSearchClick={() => navigate(`/search?search_string=${searchText}`)}
          />
        </Middle>
        <Right className="col-lg-4">
          <Badge color="success" variant="dot" invisible={stateCustomerInfo.customer === null ? true : false}>
            <FiUser size={24} onClick={() => navigate("/account")} style={{
              cursor: "pointer",
              stroke: Colors.white,
              strokeWidth: 2.5
            }} />
          </Badge>

          <Badge color="info" badgeContent={cartInfo.count}
            style={{ marginLeft: 20 }}
          >
            <FiShoppingCart size={24} onClick={() => navigate("/account/cart")} color={Colors.white} style={{
              cursor: "pointer",
              stroke: Colors.white,
              strokeWidth: 2.5
            }} />
          </Badge>
        </Right>

      </Container>

      {(windowDimensions.width && windowDimensions.width < 1024) && <MobileCategoryBar
        navigate={navigate}
      />}
      {(windowDimensions.width && windowDimensions.width >= 1024) && <DesktopCategoryBar
        navigate={navigate}
      />}
    </div>
  );
};

/**
 * Category Drop Down section for mobile devices
 * @param props 
 * @returns 
 */
let MobileCategoryBar: FC<{ navigate: NavigateFunction }> = props => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (searchString: string) => {
    props.navigate(searchString)
    setIsOpen(false);
  };

  return (
    <DropdownContainer>
      <DropdownButton
        onClick={toggleDropdown}>
        Category
        <FiChevronDown size={24} style={{
          stroke: Colors.black,
          strokeWidth: 2.5
        }} />
      </DropdownButton>
      <DropdownContent open={isOpen}>
        {ProductCategorySearchMapping.map((data, index) => (
          <DropdownItem key={index} onClick={() => handleOptionClick(data.searchMapping)}>
            {data.category}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  )
}

/**
 * Category Drop Down section for Desktop devices
 * @param props 
 * @returns 
 */
let DesktopCategoryBar: FC<{ navigate: NavigateFunction }> = props => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (searchString: string) => {
    props.navigate(searchString)
    setIsOpen(false);
  };

  return (
    <DropdownContainer>
      <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
        <DropdownButtonDesktop
          onClick={toggleDropdown}>
          Category
          <FiChevronDown size={24} style={{
            stroke: Colors.black,
            strokeWidth: 2.5
          }} />
        </DropdownButtonDesktop>
        <DropdownButtonDesktop
          onClick={()=>props.navigate('/account/wishlist')}>
          <FiStar size={24} style={{
            stroke: Colors.black,
            strokeWidth: 2.5,
            marginRight: 10
          }} />
          Wishlist
        </DropdownButtonDesktop>
      </div>
      <DropdownContent open={isOpen}>
        {ProductCategorySearchMapping.map((data, index) => (
          <DropdownItem key={index} onClick={() => handleOptionClick(data.searchMapping)}>
            {data.category}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-areas: "Left Middle Middle Middle Right";
  height: max-content;
  max-width: 100%;
  top: 0;
  padding: 20px 20px;
  @media ${Device.mobileL} {
    padding-top: 0;
    grid-template-areas:
      "Left Right"
      "Middle Middle";
  }
  background-color: ${Colors.black}
`;

const Left = styled.div`
  grid-area: Left;
  display: flex;
  justify-content: left;
  align-items: center;
  @media ${Device.mobileL} {
    height: 70px;
  }
`;

const Logo = styled.div`
  width: max-content;
  height: max-content;
  margin-right: 15px;
  &:hover {cursor: pointer}
  & > img {
    height: 24px;
    margin-right: 5px;
  }

  @media ${Device.tablet} {
    margin-right: 10px;
  }
`;

const CompanyName = styled.a`
  font-size: 24px;
  font-weight: 500;
  color: ${Colors.white};
  margin-right: 15px;
  &:hover {cursor: pointer}
  @media ${Device.mobileXL} {
    visibility: hidden;
    width: 0;
    margin: 0;
  }
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.tabletS}) {
    font-size: 20px;
  }
`;

const Middle = styled.div`
  grid-area: Middle;
  padding: 0;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  grid-area: Right;
  display: flex;
  justify-content: right;
  align-items: center;

  & > .shopping-cart {
    margin-left: 20px;
  }

@media${Device.tablet} {
  & > .shopping-cart,
  .user {
    size: 16px;
    margin: 5px;
  }
} @media${Device.mobileL} {
  & > .shopping-cart {
    margin-left: 5px;
  }
}
`;


// ========================== Mobile drop down styles ========================

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  background-color: ${Colors.white};
  padding-left: 10px;
  padding-right: 10px;
`;

const DropdownButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  color: ${Colors.black};
  background-color: ${Colors.white};
  font-weight: 500;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

const DropdownButtonDesktop = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${Colors.white};
  color: ${Colors.black};
  font-weight: 500;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

const DropdownContent = styled.div<DropdownContentProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
  /* width: 100%; */
  @media only screen and (min-width: ${SCREEN_WIDTH_CONSTANTS.iPad}) {
    width: auto;
  }
`;

const DropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;



export default NavBar;
