import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "../../store";
import {GeneralResponseType} from "../types";
import {
    CustomerInfoResponseType,
    CustomerInfoUpdateResponseType,
    CustomerShippingAddressType,
    CustomerType
} from "./types";
import {refreshTokenUtil} from "../../utils/tokenUtil";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const customerApiSlice = createApi({
    reducerPath: "CustomerApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${CUSTOMER_API_BASE_URL}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.accessToken
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['User', 'Address'],

    endpoints: (builder) => ({
        getAddresses: builder.query<GeneralResponseType<Array<CustomerShippingAddressType>>, void>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let addressResponse = await fetchWithBQ({
                    url: "/customer/account/address",
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })
                return addressResponse as any
            },
            providesTags:['Address'],
            keepUnusedDataFor: 180
        }),

        addAddress: builder.mutation<GeneralResponseType<CustomerShippingAddressType>, CustomerShippingAddressType>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let addressResponse = await fetchWithBQ({
                    url: "/customer/account/address",
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                    body: arg
                })
                return addressResponse as any
            },
            invalidatesTags:['Address'],
        }),

        deleteAddress: builder.mutation<GeneralResponseType<null>, number>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let addressResponse = await fetchWithBQ({
                    url: `/customer/account/address/${arg}`,
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    }
                })
                return addressResponse as any
            },
            invalidatesTags:['Address'],
        }),

        getCustomerInfo: builder.query<GeneralResponseType<CustomerInfoResponseType>, void>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let customerResponse = await fetchWithBQ({
                    url: "/customer/account/info",
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                })
                return customerResponse as any
            },
            providesTags:['User'],
            keepUnusedDataFor: 180
        }),

        updateCustomerInfo: builder.mutation<GeneralResponseType<CustomerInfoUpdateResponseType>, CustomerType>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {

                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let customerResponse = await fetchWithBQ({
                    url: "/customer/account",
                    method: "PUT",
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    },
                    body: arg
                })
                return customerResponse as any
            },
            invalidatesTags: ['User']
        })
    })
})

export const { useLazyGetAddressesQuery, useGetCustomerInfoQuery, useLazyGetCustomerInfoQuery, useUpdateCustomerInfoMutation, useAddAddressMutation, useDeleteAddressMutation } = customerApiSlice;