import {CustomerInfoResponseType, CustomerShippingAddressType, CustomerStateType, CustomerType} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: CustomerStateType = {
    addresses: [],
    customer: null,
    error: null
};

const customerSlice = createSlice({
    name: "CustomerSlice",
    initialState,
    reducers: {
        setCustomerInfo(state, action: PayloadAction<CustomerInfoResponseType | null>){
            if(action.payload){
                state.addresses = action.payload.addresses;
                state.customer = action.payload.customer;
            }
        },
        setCustomerAddresses(state, action: PayloadAction<Array<CustomerShippingAddressType>>){
            state.addresses = action.payload
        },
        deleteCustomerAddresses(state, action: PayloadAction<number>){
            if(state.addresses&&state.addresses.length > 0) state.addresses = state.addresses?.filter(value=> value.id!==action.payload)
        },
        addAddress(state, action: PayloadAction<CustomerShippingAddressType>){
            state.addresses?.push(action.payload)
        },
        updateCustomerInfo(state, action: PayloadAction<CustomerType>){
            console.log("action.payload --->", action.payload);
            state.customer = action.payload
        }
    }
})

export const { setCustomerAddresses,setCustomerInfo, updateCustomerInfo, addAddress,deleteCustomerAddresses } = customerSlice.actions

export const customerReducer = customerSlice.reducer;