import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Lottie from 'react-lottie';
import NavBar from '../components/NavBar';
import OrderHistoryCard from '../components/Order/OrderHistoryCard';
import Footer from '../components/Universal/Footer';
import TextTrail from '../components/Universal/TextTrail';
import Colors from '../constants/Colors';
import { useLazyGetOrderListQuery } from '../features/order/orderApiSlice';
import { OrderModel } from '../features/order/types';
import { lottieOptions } from "../utils/lottieOptions";
import spinnerLottie from "../assets/lottie/loading-orange.json";
import Pagination from '@mui/material/Pagination';

let OrderHistoryPage = () => {

    let [pageNumber, setPageNumber] = useState(0);
    let [pageSize, setPageSize] = useState(10);
    let [lastPage, setLastPage] = useState(true);
    let [orderList, setOrderList] = useState<Array<OrderModel>>([]);
    let [numOfPages, setNumOfPages] = useState(0)

    let [triggerGetOrderHistory, { isLoading, isSuccess }] = useLazyGetOrderListQuery();

    let getOrderList = (pageNum?) => {
        triggerGetOrderHistory({ page: pageNum!==undefined ? pageNum : pageNumber, count: pageSize })
            .unwrap()
            .then(data => {
                if (data.response) {
                    setPageNumber(data.response?.pageNo)
                    setOrderList(data.response.content)
                    setLastPage(data.response.last)
                    setNumOfPages(data.response.totalPages)
                }
            })
    }

    useEffect(() => {
        getOrderList();
    }, [])

    return (
        <Container>
            <div>
                <NavBar />
                {isSuccess && <ContentContainer>
                    <ContentWrapper>
                        <TextTrail
                            textArray={['Your Account', 'Order History']}
                            containerStyling={{ marginTop: '20px' }}
                        />
                        {/* <SearchBar
                            placeholder="Order Search"
                            containerStyling={{ marginTop: '20px' }}
                        />

                        <div>
                            <DatePicker
                                containerStyling={{ marginTop: '20px' }}
                            />
                        </div> */}

                        <OrderListContainer>
                            {orderList.map((item, index) =>
                                <OrderHistoryCard
                                    // imageUrl={item.imageUrl}
                                    orderMethod={item.orderMethod}
                                    cancelable={item.status !== 'CANCELLED'}
                                    status={item.status}
                                    date={item.orderDate}
                                    orderCount={item.orderDetails?.length || 0}
                                    orderTotal={item.orderTotal + item.deliveryRate}
                                    productName={item.orderDetails && item.orderDetails?.map(val => val.product.productName).join(",")}
                                    containerStyling={{ marginBottom: '15px' }}
                                    orderId={item.id}
                                    trackingNumber={item.trackingNumber}
                                    orderDetails={item.orderDetails}
                                />
                            )}

                        </OrderListContainer>

                        {/* TODO implement this */}
                        <StepperContainer>
                            <Pagination
                                count={numOfPages}
                                page={pageNumber+1}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    setPageNumber(value-1);
                                    getOrderList(value-1)
                                }}
                            />
                        </StepperContainer>
                    </ContentWrapper>

                </ContentContainer>}

                {isLoading && <Lottie options={lottieOptions(true, true, spinnerLottie)}
                    height={450}
                    width={450}
                    isStopped={false}
                    isPaused={false}
                />}
            </div>
            <Footer />
        </Container>
    )
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.background};
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 20px 20px;
`;

const ContentWrapper = styled.div`
    width: 100%;
    max-width: 770px;
    min-width: 350px;
`;

const OrderListContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    
`;

let StepperContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-right: 20px;
`;

export default OrderHistoryPage;