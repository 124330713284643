
export interface ProductSearchFilter {
    category: string | any;
    maxPrice: number;
    minPrice: number
}

export interface ProductSearchTypes {
    pageNumber: number;
    itemCount: number;
    searchString: string | any;
    searchFilter: ProductSearchFilter | null
}

export interface ProductType {
    productId: number;
    productName: string;
    productDescription: string;
    productPrice: number;
    quantity: number;
    status: string;
    details: {descriptionPoints: Array<string>, packageContent: Array<string>};
    category: string;
    subCategory: string;
    isListed: boolean;
    supplierId: number;
    isFeatured: boolean;
    weightLb: number;
    imageUrls: [] | Array<string>
}

export enum ProductSearchParams {
    search_string = "search_string",
    search_category = "search_category",
    max_price = "max_price",
    min_price = "min_price",
    page_number = "page_number",
    item_count = "item_count",
}