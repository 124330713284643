export const zipmailLocations =[
    {
     "location": "Above Rocks"
    },
    {
     "location": "Alexandria"
    },
    {
     "location": "Allman Town"
    },
    {
     "location": "Anchovy"
    },
    {
     "location": "Annotto Bay"
    },
    {
     "location": "Bamboo"
    },
    {
     "location": "Black River"
    },
    {
     "location": "Bog Walk"
    },
    {
     "location": "Boscobel"
    },
    {
     "location": "Bridgeport"
    },
    {
     "location": "Brown’s Town"
    },
    {
     "location": "Buff Bay"
    },
    {
     "location": "Bull Bay"
    },
    {
     "location": "Central Sorting Office"
    },
    {
     "location": "Chapleton"
    },
    {
     "location": "Christiana"
    },
    {
     "location": "Claremont"
    },
    {
     "location": "Constant Spring"
    },
    {
     "location": "Cross Roads"
    },
    {
     "location": "Darliston"
    },
    {
     "location": "Denbigh"
    },
    {
     "location": "Denham Town"
    },
    {
     "location": "Discovery Bay"
    },
    {
     "location": "Duncans"
    },
    {
     "location": "Ewarton"
    },
    {
     "location": "Fairy Hll"
    },
    {
     "location": "Falmouth"
    },
    {
     "location": "Fontana Waterloo"
    },
    {
     "location": "Four Paths"
    },
    {
     "location": "Frome"
    },
    {
     "location": "Gayle"
    },
    {
     "location": "General Post Office"
    },
    {
     "location": "Golden Grove"
    },
    {
     "location": "Gordon Town"
    },
    {
     "location": "Grange Hill"
    },
    {
     "location": "Grants Pen"
    },
    {
     "location": "Granville"
    },
    {
     "location": "Greater Portmore"
    },
    {
     "location": "Green Island"
    },
    {
     "location": "Gregory Park"
    },
    {
     "location": "Half-Way-Tree"
    },
    {
     "location": "Hayes"
    },
    {
     "location": "Highgate"
    },
    {
     "location": "Hopewell"
    },
    {
     "location": "Jones Town"
    },
    {
     "location": "Junction"
    },
    {
     "location": "Knockpatrick"
    },
    {
     "location": "Lawrence Tavern"
    },
    {
     "location": "Liguanea"
    },
    {
     "location": "Linstead"
    },
    {
     "location": "Lionel Town"
    },
    {
     "location": "Little London"
    },
    {
     "location": "Little River"
    },
    {
     "location": "Lluidasvale"
    },
    {
     "location": "Long Bay"
    },
    {
     "location": "Lucea"
    },
    {
     "location": "Lyssons"
    },
    {
     "location": "Manchioneal"
    },
    {
     "location": "Mandeville"
    },
    {
     "location": "May Pen"
    },
    {
     "location": "Meadowbridge"
    },
    {
     "location": "Middle Quarters"
    },
    {
     "location": "Mona"
    },
    {
     "location": "Moneague"
    },
    {
     "location": "Montego Bay #1"
    },
    {
     "location": "Montego Bay 2"
    },
    {
     "location": "Morant Bay"
    },
    {
     "location": "Negril"
    },
    {
     "location": "New Kingston"
    },
    {
     "location": "Ocho Rios"
    },
    {
     "location": "Old Harbour"
    },
    {
     "location": "Oracabessa"
    },
    {
     "location": "Petersfield"
    },
    {
     "location": "Port Antonio"
    },
    {
     "location": "Port Maria"
    },
    {
     "location": "Port Morant"
    },
    {
     "location": "Port Royal"
    },
    {
     "location": "Porus"
    },
    {
     "location": "Priestman's River"
    },
    {
     "location": "Reading"
    },
    {
     "location": "Red Hills"
    },
    {
     "location": "Richmond"
    },
    {
     "location": "Runaway Bay"
    },
    {
     "location": "Santa Cruz"
    },
    {
     "location": "Savanna-la-Mar"
    },
    {
     "location": "Sheffield"
    },
    {
     "location": "Southfield"
    },
    {
     "location": "Spalding"
    },
    {
     "location": "Spanish Town"
    },
    {
     "location": "St. Ann’s Bay"
    },
    {
     "location": "Stony Hill"
    },
    {
     "location": "Swift River"
    },
    {
     "location": "Tower Isle"
    },
    {
     "location": "Utech"
    },
    {
     "location": "Vineyard Town"
    },
    {
     "location": "Waterford"
    },
    {
     "location": "Western District"
    },
    {
     "location": "Whitehouse"
    },
    {
     "location": "Whitesands Beach"
    },
    {
     "location": "Whitfield Town"
    },
    {
     "location": "Williamsfield"
    },
    {
     "location": "Windsor Castle"
    },
    {
     "location": "Windward Road"
    },
    {
     "location": "Yallahs"
    }
   ]