import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { GeneralResponseType } from "../types";
import { ShippingDeliveryCalculatorRequest } from "./types";
import {refreshTokenUtil} from "../../utils/tokenUtil";
import { RootState } from "../../store";

const CUSTOMER_API_BASE_URL: any = process.env.REACT_APP_CUSTOMER_API_BASE_URL

export const shippingCalculatorApiSlice = createApi({
    reducerPath: "ShippingCalculatorApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: `${CUSTOMER_API_BASE_URL}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth.accessToken
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['ShippingCalculator'],

    endpoints: (builder)=>({
        calculateShippingRate: builder.query<GeneralResponseType<{price: number, deliveryType: string}>, ShippingDeliveryCalculatorRequest>({
            async queryFn(arg, {getState, dispatch}, extraOptions, fetchWithBQ) {
                let utilRes = await refreshTokenUtil(fetchWithBQ, dispatch, getState);

                if(utilRes?.refreshTokenRes?.error) return utilRes?.refreshTokenRes;

                let calcResponse = await fetchWithBQ({
                    url: "/calculator/shipping-delivery",
                    method: "POST",
                    body: arg,
                    headers: {
                        'Authorization': `Bearer ${utilRes?.accessToken}`,
                    }
                })
                return calcResponse as any
            }
        })
    })
})

export const {useLazyCalculateShippingRateQuery} = shippingCalculatorApiSlice;